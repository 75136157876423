// src/components/Dashboard/OrderFulfillment/OrderFulfilmentSidebar.jsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ClipboardCheckIcon,
  ClipboardListIcon,
  ArchiveIcon,
  TruckIcon,
  XIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import Logo from '../../images/arbie_gray_logo.png';

import PromptLink from '../Dashboard/Ecommerce/ProductDetailsPage/UnsavedChanges/PromptLink';

import { setFilter } from '../../features/orders/ordersSlice';

const OrderFulfilmentSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  onNavigateAttempt, // Function to handle navigation attempts
}) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.orders);
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  const handleFilterClick = (status) => {
    dispatch(setFilter({ status }));
  };

  const sections = [
    {
      title: 'Order Management',
      items: [
        {
          name: 'New Orders',
          link: '/orderfulfilment/new',
          icon: (
            <ClipboardListIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'new',
        },
        {
          name: 'Under Review',
          link: '/orderfulfilment/under-review',
          icon: (
            <ClipboardCheckIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'under_review',
        },
        {
          name: 'Ready to Ship',
          link: '/orderfulfilment/ready-to-ship',
          icon: (
            <TruckIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'ready_to_ship',
        },
        {
          name: 'In Progress',
          link: '/orderfulfilment/in-progress',
          icon: (
            <ArchiveIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'in_progress',
        },
        {
          name: 'Completed',
          link: '/orderfulfilment/completed',
          icon: (
            <ClipboardCheckIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'completed',
        },
        {
          name: 'Voided',
          link: '/orderfulfilment/voided',
          icon: (
            <XIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'voided',
        },
        {
          name: 'Returns',
          link: '/orderfulfilment/returns',
          icon: (
            <ClipboardListIcon className='w-6 h-6 text-indigo-500 flex-shrink-0' />
          ),
          status: 'returns',
        },
      ],
    },
  ];

  return (
    <div
      className={`fixed inset-0 z-50 transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:static lg:z-auto lg:inset-auto bg-white dark:bg-gray-800 border-r shadow-lg h-full ${isSidebarCollapsed ? 'lg:w-16' : 'lg:w-64'
        } w-72 flex flex-col justify-between dark:border-gray-700`}
    >
      <div className='flex flex-col h-full'>
        {/* Top Section: Logo and Close Button for Mobile */}
        <div className='flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 lg:hidden'>
          <PromptLink
            to='/'
            className='flex items-center'
            onClick={() => onNavigateAttempt('/')}
          >
            <img src={Logo} className='h-8 w-auto' alt='Logo' />
            {!isSidebarCollapsed && (
              <span className='ml-2 text-lg font-semibold text-gray-700 dark:text-gray-200'>
                Order Fulfillment
              </span>
            )}
          </PromptLink>
          <button
            className='text-gray-500 dark:text-gray-400'
            onClick={() => setIsSidebarOpen(false)}
          >
            <XIcon className='w-6 h-6' />
          </button>
        </div>

        {/* Sidebar Content */}
        <div className='flex-grow overflow-y-auto'>
          {/* Expanded Sidebar Content: Always visible on mobile, conditional on desktop */}
          <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'block'}`}>
            {/* User Info */}
            <div className='p-4'>
              <div className='flex items-center mb-4'>
                <img
                  src={
                    profileImageUrl ||
                    'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                  }
                  className='h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600'
                  alt='User Avatar'
                />
                {/* User Name: Hidden on Desktop if Collapsed */}
                <div className={`${isSidebarCollapsed ? 'lg:hidden' : 'ml-3'}`}>
                  <h2 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                    Welcome, {user?.name}
                  </h2>
                </div>
              </div>

              {/* Navigation Sections */}
              {sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h3 className='text-xs font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400 mb-2'>
                    {section.title}
                  </h3>
                  <nav className='space-y-1 mb-4'>
                    {section.items.map((item, index) => (
                      <PromptLink
                        key={index}
                        to={item.link}
                        className={`flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 transition ${filters.status === item.status
                            ? 'bg-gray-200 dark:bg-gray-700 font-semibold'
                            : ''
                          }`}
                        onClick={() => {
                          handleFilterClick(item.status);
                          onNavigateAttempt(item.link);
                        }}
                      >
                        {item.icon}
                        {/* Item Name: Hidden on Desktop if Collapsed */}
                        <span
                          className={`ml-3 ${isSidebarCollapsed ? 'lg:hidden' : 'inline'}`}
                        >
                          {item.name}
                        </span>
                      </PromptLink>
                    ))}
                  </nav>
                </div>
              ))}
            </div>

            {/* Need Help Section: Hidden on Desktop if Collapsed */}
            {!isSidebarCollapsed && (
              <div className='p-4 bg-gray-100 dark:bg-gray-900 rounded-lg m-4 border dark:border-gray-700'>
                <div className='flex items-center'>
                  <QuestionMarkCircleIcon className='w-6 h-6 text-indigo-500 dark:text-indigo-400 flex-shrink-0' />
                  <div className='ml-3'>
                    <h4 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
                      Need Help?
                    </h4>
                    <p className='text-xs text-gray-500 dark:text-gray-400'>
                      Submit a ticket and our team will assist you.
                    </p>
                  </div>
                </div>

                <PromptLink
                  to='/settings/help'
                  className='block mt-4 text-center text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-400 rounded-md py-2'
                  onClick={() => onNavigateAttempt('/settings/help')}
                >
                  Submit a Ticket
                </PromptLink>
              </div>
            )}
          </div>

          {/* Sidebar Collapsed View on Desktop */}
          {isSidebarCollapsed && (
            <nav className='flex-col items-center p-4 space-y-2 lg:flex hidden'>
              {sections.map((section) =>
                section.items.map((item, index) => (
                  <PromptLink
                    key={index}
                    to={item.link}
                    className='flex items-center justify-center p-4 text-gray-700 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 rounded-lg transition'
                    onClick={() => {
                      handleFilterClick(item.status);
                      onNavigateAttempt(item.link);
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      className: 'w-6 h-6 text-indigo-500',
                    })}
                  </PromptLink>
                ))
              )}
            </nav>
          )}
        </div>

        {/* Bottom Navigation: Home Dashboard */}
        <div
          className={`p-4 border-t border-gray-200 dark:border-gray-700 ${isSidebarCollapsed ? 'lg:hidden' : ''
            }`}
        >
          <PromptLink
            to='/arbie'
            className='flex items-center p-2 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 transition'
            onClick={() => onNavigateAttempt('/arbie')}
          >
            <HomeIcon className='w-6 h-6 flex-shrink-0' />
            {!isSidebarCollapsed && <span className='ml-3'>Home Dashboard</span>}
          </PromptLink>
        </div>

        {/* Bottom Section: Collapse/Expand Button for Large Screens */}
        <div className='p-4 hidden lg:flex'>
          {isSidebarCollapsed ? (
            <button
              className='flex items-center justify-center w-full h-10 bg-gray-200 dark:bg-gray-700 rounded-md'
              onClick={() => setIsSidebarCollapsed(false)}
            >
              <ArrowRightIcon className='w-6 h-6 text-gray-500 dark:text-gray-400' />
            </button>
          ) : (
            <button
              className='flex items-center justify-center w-full h-10 bg-gray-200 dark:bg-gray-700 rounded-md'
              onClick={() => setIsSidebarCollapsed(true)}
            >
              <ArrowLeftIcon className='w-6 h-6 text-gray-500 dark:text-gray-400' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderFulfilmentSidebar;
