import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Logo
import logo from "../../images/arbiengine_data.png";
// Light/Dark images for Mobile
import inventoryMobileLight from "../../images/inventory-mobile-light.png";
import inventoryMobileDark from "../../images/inventory-mobile-dark.png";

const PowerSellersTeaserMobile = () => {
  const navigate = useNavigate();

  // Listen for theme changes, defaulting to localStorage's value or "light"
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme);
    };

    window.addEventListener("themeChange", handleThemeChange);
    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  // Dynamically pick the mobile teaser image
  const teaserImage = theme === "dark" ? inventoryMobileDark : inventoryMobileLight;

  // Single button: "Learn About Our Beta"
  const handleNavigateToPowerSellers = () => {
    navigate("/powersellers");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section
      className="relative w-full min-h-[95vh] flex flex-col justify-between items-center text-center pt-10"
      aria-label="Power Sellers Teaser - Mobile"
    >
      {/* Top Image */}
      <div className="w-full">
        <img
          src={teaserImage}
          alt="Power Sellers Teaser"
          className="w-full h-auto object-cover"
        />
      </div>

      {/* Teaser Content */}
      <div className="w-full px-6 py-6 flex flex-col items-center text-gray-900 dark:text-white">
        {/* Small Title with Logo */}
        <div className="flex items-center gap-3 mb-4">
          <img src={logo} alt="ArbiEngine Logo" className="h-8 w-8" />
          <div className="flex items-center gap-2">
            <span className="text-xl sm:text-2xl font-bold">ArbiEngine</span>
            <span className="text-sm sm:text-base font-semibold text-indigo-700 dark:text-indigo-500 whitespace-nowrap">
              for Power Sellers
            </span>
          </div>
        </div>

        {/* Headline */}
        <h1 className="text-3xl sm:text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">
          Reimagine Ecommerce,
          <br />
          Starting with eBay.
        </h1>

        {/* Supporting Text */}
        <p className="mt-3 text-base sm:text-lg text-gray-700 dark:text-gray-300 max-w-2xl">
          We’re partnering with <strong>100 eBay Power Sellers</strong> to refine
          listing flows, fulfillment processes, and the foundations of our next-generation
          e-commerce platform. Become an Early Adopter and help shape what’s next!
        </p>

        {/* Single Button */}
        <div className="mt-6 w-full">
          <button
            onClick={handleNavigateToPowerSellers}
            className="w-full rounded-full bg-indigo-600 px-6 py-3 text-base font-semibold text-white shadow-md
                       hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400
                       transition-transform transform hover:scale-105"
          >
            Learn About Our Beta
          </button>
        </div>
      </div>
    </section>
  );
};

export default PowerSellersTeaserMobile;
