import React from 'react';
import { Helmet } from 'react-helmet-async'; // 1) Import Helmet
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallToAction from '../components/AboutUs/CallToAction';
import Timeline from '../components/AboutUs/Timeline';
import MissionSection from '../components/AboutUs/MissionSection';
import Statistics from '../components/AboutUs/Statistics';
import Team from '../components/AboutUs/Team';

const AboutUs = () => {
  return (
    <>
      
      <Helmet>
        {/* Basic HTML metadata */}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Title */}
        <title>ArbiEngine – About Us: A Simpler Path to Ecommerce Success</title>

        {/* Primary Description */}
        <meta
          name="description"
          content="Learn how ArbiEngine unifies product sourcing, data analysis, process automation, and more—empowering entrepreneurs and enterprises to scale globally with less complexity."
        />

        {/* (Optional) Keywords */}
        <meta
          name="keywords"
          content="ArbiEngine, about us, ecommerce, product sourcing, data analysis, process automation, multichannel listing, brand development, customer engagement"
        />

        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://arbiengine.com/about-us" />
        <meta name="author" content="ArbiEngine" />

        {/* JSON-LD for structured data (optional but recommended) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "ArbiEngine – About Us: A Simpler Path to Ecommerce Success",
            description:
              "Learn how ArbiEngine unifies product sourcing, data analysis, process automation, and more—empowering entrepreneurs and enterprises to scale globally with less complexity.",
            url: "https://arbiengine.com/about-us",
            mainEntity: {
              "@type": "Organization",
              name: "ArbiEngine",
              logo: "https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png",
              sameAs: [
                "https://x.com/ArbiEngine",
                "https://www.instagram.com/arbiengine",
                "https://www.linkedin.com/company/arbiengine",
              ],
            },
          })}
        </script>

        {/* Open Graph / Social Preview */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="ArbiEngine – About Us: A Simpler Path to Ecommerce Success"
        />
        <meta
          property="og:description"
          content="Join us and discover how ArbiEngine helps you scale your online business—from product sourcing to global brand development."
        />
        <meta
          property="og:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
        <meta property="og:url" content="https://arbiengine.com/about-us" />
        <meta property="og:site_name" content="ArbiEngine" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ArbiEngine – About Us: A Simpler Path to Ecommerce Success"
        />
        <meta
          name="twitter:description"
          content="Learn how ArbiEngine brings together every aspect of ecommerce, so you can focus on growth instead of juggling disconnected tools."
        />
        <meta
          name="twitter:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
      </Helmet>


     
      <div className='relative min-h-screen  bg-gray-100 dark:bg-gray-900'>
        {/* Top Left Gradient */}
        <div
          className="absolute inset-x-0 top-0 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] 
              -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400 
              opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {/* Middle Left Gradient */}
        <div
          className="absolute inset-x-0 top-1/4 transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem]
              -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400
              opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {/* Bottom Left Gradient */}
        <div
          className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem]
              -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400
              opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {/* Right Side Gradient 1 */}
        <div
          className="absolute top-0 right-0 transform-gpu overflow-hidden blur-3xl sm:top-10"
          aria-hidden="true"
        >
          <div
            className="relative right-[calc(50%-10rem)] aspect-[1155/678] w-[40.125rem]
              translate-x-1/2 rotate-[60deg] bg-gradient-to-tr from-blue-400 to-pink-500
              opacity-30 dark:opacity-30 sm:right-[calc(50%-5rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {/* Right Side Gradient Statistics Section */}
        <div
          className="absolute top-1/2 right-0 transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative right-[calc(50%-10rem)] aspect-[1155/678] w-[40.125rem]
              translate-x-1/2 rotate-[60deg] bg-gradient-to-tr from-blue-400 to-pink-500
              opacity-30 dark:opacity-30 sm:right-[calc(50%-5rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <Header isSticky={true} />
        <CallToAction />
        <Timeline />
        <MissionSection />
        <Statistics />
        <Team />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
