import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDirty: false,
  // Optionally, you can track specific changes or metadata
  // changedFields: [], // e.g., [{ fieldName: 'name', oldValue: 'Old', newValue: 'New' }]
};

const unsavedChangesSlice = createSlice({
  name: 'unsavedChanges',
  initialState,
  reducers: {
    setDirty: (state, action) => {
      state.isDirty = true;
      // Optionally, handle specific changed fields
      // state.changedFields = action.payload;
    },
    resetDirty: (state) => {
      state.isDirty = false;
      // state.changedFields = [];
    },
  },
});

export const { setDirty, resetDirty } = unsavedChangesSlice.actions;

export default unsavedChangesSlice.reducer;
