// src/components/Dashboard/Ecommerce/ProductDetailsPage/ProductMediaSection.jsx

import React, { useRef, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CameraIcon,
  XIcon,
  ArrowsExpandIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import { useFormikContext } from 'formik';

const ProductMediaSection = ({ media = [], darkMode, onUploadImages }) => {
  const scrollRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { values } = useFormikContext();

  // Minimum fields required for a draft record: brand, model, item_description, price, quantity
  const areDraftFieldsFilled = (
    values.basicDetails.brand &&
    values.basicDetails.model &&
    values.basicDetails.item_description &&
    values.basicDetails.price !== undefined &&
    values.basicDetails.price !== '' &&
    values.basicDetails.quantity !== undefined &&
    values.basicDetails.quantity !== ''
  );

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: direction === 'left' ? -300 : 300,
        behavior: 'smooth',
      });
    }
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    );
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="mt-8 relative">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
          Product Images
        </h2>
        <div className="relative flex items-center">
          {areDraftFieldsFilled ? (
            <button
              type="button"
              onClick={onUploadImages}
              className="flex items-center bg-indigo-600 text-white px-5 py-2 rounded-lg shadow hover:bg-indigo-700 transition focus:outline-none focus:ring-2 focus:ring-indigo-500"
              aria-label={media.length > 0 ? 'Manage Images' : 'Upload Images'}
            >
              <CameraIcon className="h-6 w-6 mr-2" />
              {media.length > 0 ? 'Manage Images' : 'Upload Images'}
            </button>
          ) : (
            <div className="group inline-flex items-center relative">
              <button
                type="button"
                disabled
                className="flex items-center bg-gray-400 text-white px-5 py-2 rounded-lg shadow cursor-not-allowed focus:outline-none"
                aria-label={media.length > 0 ? 'Manage Images' : 'Upload Images'}
              >
                <CameraIcon className="h-6 w-6 mr-2" />
                {media.length > 0 ? 'Manage Images' : 'Upload Images'}
              </button>

              {/* Tooltip positioned to the left of the button with dark/light mode support */}
              <div className="absolute top-1/2 right-full transform -translate-y-1/2 mr-2 p-3 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-50 w-64 
                              bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
                <div className="flex items-start space-x-2">
                  <InformationCircleIcon className="h-5 w-5 text-indigo-400 flex-shrink-0 mt-0.5" />
                  <span className="leading-tight">
                    Save as draft first: populate required draft fields (brand, model, description, price, quantity) before adding photos.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Image Gallery */}
      <div className="relative">
        <div className="relative w-full">
          {media.length > 3 && (
            <button
              type="button"
              className="ml-2 absolute left-0 z-10 p-2 bg-white dark:bg-gray-800 rounded-full top-1/2 transform -translate-y-1/2 hover:bg-gray-100 dark:hover:bg-gray-700 transition focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => scroll('left')}
              aria-label="Scroll Left"
            >
              <ChevronLeftIcon className="h-6 w-6 text-indigo-600" />
            </button>
          )}

          <div
            ref={scrollRef}
            className={`flex space-x-4 overflow-x-auto scrollbar-hide p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'
              } shadow-md`}
          >
            {media.length > 0 ? (
              media.map((image, index) => (
                <div
                  key={index}
                  className="relative w-64 h-64 flex-shrink-0 rounded-lg overflow-hidden shadow-md cursor-pointer group"
                  onClick={() => openModal(index)}
                >
                  <img
                    src={image.awsUrl}
                    alt={`Product Image ${index + 1}`}
                    className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-105"
                  />
                  {/* Expand Icon Overlay */}
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300">
                    <ArrowsExpandIcon className="h-12 w-12 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 dark:text-gray-400 w-full py-16">
                No images available
              </div>
            )}
          </div>

          {media.length > 3 && (
            <button
              type="button"
              className="mr-2 absolute right-0 z-10 p-2 bg-white dark:bg-gray-800 rounded-full top-1/2 transform -translate-y-1/2 hover:bg-gray-100 dark:hover:bg-gray-700 transition focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => scroll('right')}
              aria-label="Scroll Right"
            >
              <ChevronRightIcon className="h-6 w-6 text-indigo-600" />
            </button>
          )}
        </div>
      </div>

      {/* Modal for Enlarged Images */}
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80"
        >
          <div className="relative flex items-center justify-center max-w-screen max-h-screen">
            {media.length > 1 && (
              <button
                type="button"
                className="absolute left-4 md:left-8 top-1/2 transform -translate-y-1/2 p-2 bg-white bg-opacity-30 rounded-full text-indigo-600 hover:bg-opacity-50 focus:outline-none"
                onClick={showPrevImage}
                aria-label="Previous Image"
              >
                <ChevronLeftIcon className="h-10 w-10" />
              </button>
            )}

            <img
              src={media[currentImageIndex].awsUrl}
              alt={`Product Image ${currentImageIndex + 1}`}
              className="max-w-full max-h-full rounded-lg shadow-lg"
              style={{ maxWidth: '90vw', maxHeight: '90vh' }}
            />

            {media.length > 1 && (
              <button
                type="button"
                className="absolute right-4 md:right-8 top-1/2 transform -translate-y-1/2 p-2 bg-white bg-opacity-30 rounded-full text-indigo-600 hover:bg-opacity-50 focus:outline-none"
                onClick={showNextImage}
                aria-label="Next Image"
              >
                <ChevronRightIcon className="h-10 w-10" />
              </button>
            )}

            <button
              type="button"
              className="absolute top-4 right-4 md:top-8 md:right-8 text-red-500 hover:text-gray-500 focus:outline-none"
              onClick={closeModal}
              aria-label="Close Modal"
            >
              <XIcon className="h-8 w-8" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductMediaSection;
