// src/layouts/OrderFulfilmentLayout.jsx
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DashHeader from '../../components/Dashboard/Header/DashHeader';
import OrderFulfilmentSidebar from '../../components/OrderFulfilment/OrderFulfilmentSidebar';

const OrderFulfilmentLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900 transition-colors duration-200">
      {/* Header */}
      <DashHeader setIsSidebarOpen={setIsSidebarOpen} />

      <div className="flex flex-1 pt-16 overflow-hidden">
        {/* Sidebar */}
        <OrderFulfilmentSidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
        />

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default OrderFulfilmentLayout;
