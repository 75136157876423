import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnections, signIn, updateUserConnection, deleteConnectionThunk, reset } from '../../features/connections/connectionSlice';
import ConnectionCard from '../../components/connections/ConnectionCard';
import ConnectionModal from '../../components/connections/ConnectionModal';
import ReauthenticateModal from '../../components/connections/ReauthenticateModal';
import DeleteModal from '../../components/connections/DeleteModal';
import { toast } from 'react-toastify';
import HelperModal from '../../components//HelperModal'; // Import the modal

const platformConfig = {
  ebay: {
    name: 'eBay',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
  },
};

const ConnectionSettings = () => {
  const dispatch = useDispatch();
  const { connections, isLoading, isError, message } = useSelector((state) => state.connection);
  const { subscription, plans } = useSelector((state) => state.subscription);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReauthenticateModalOpen, setIsReauthenticateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPlatform, setCurrentPlatform] = useState(null);
  const [editingConnection, setEditingConnection] = useState(null);
  const [deletingConnection, setDeletingConnection] = useState(null);
  const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);
  const [unlockingConnection, setUnlockingConnection] = useState(null);

  // Calculate account limits
  const activePlan = plans.find((plan) => plan.name === subscription?.plan);
  const accountLimit = activePlan?.accountLimit || 0;
  const nonLockedConnections = connections.filter((conn) => !conn.locked); // Exclude locked connections
  const currentConnectionsCount = nonLockedConnections.length;

  useEffect(() => {
    if (subscription?.userId) {
      dispatch(fetchConnections(subscription.userId));
    }
  }, [dispatch, subscription]);

  useEffect(() => {
    if (isError && message) {
      toast.error(message);
      const timeout = setTimeout(() => {
        dispatch(reset());
      }, 2000);
      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [isError, message, dispatch]);

  const handleAddAccount = (platform) => {
    setIsModalOpen(true);
    setCurrentPlatform(platform);
  };

  const handleEditAccount = (connection) => {
    setIsModalOpen(true);
    setCurrentPlatform(connection.platform);
    setEditingConnection(connection);
  };

  const handleDeleteAccount = (connection) => {
    setIsDeleteModalOpen(true);
    setDeletingConnection(connection);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentPlatform(null);
    setEditingConnection(null);
  };

  const handleCloseReauthenticateModal = () => {
    setIsReauthenticateModalOpen(false);
    setEditingConnection(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeletingConnection(null);
  };

  const handleModalSubmit = (connectionDetails) => {
    console.log(connectionDetails)
    if (editingConnection) {
      dispatch(updateUserConnection({
        connectionId: connectionDetails.connectionId,
        newAccountName: connectionDetails.accountName,
        listToByDefault: connectionDetails.listToByDefault,
      }));
    } else {
      dispatch(signIn(connectionDetails));
    }
    setIsModalOpen(false);
    setCurrentPlatform(null);
    setEditingConnection(null);
  };

  const handleReauthenticate = (connection) => {
    setIsReauthenticateModalOpen(true);
    setEditingConnection(connection);
  };

  const handleReauthenticateConfirm = () => {
    dispatch(signIn({
      platform: editingConnection.platform,
      accountType: editingConnection.accountType,
      accountName: editingConnection.accountName,
      connectionId: editingConnection.id,
    }));
    handleCloseReauthenticateModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteConnectionThunk({ connectionId: deletingConnection._id, platform: deletingConnection.platform }));
    handleCloseDeleteModal();
  };

  const handleUnlock = (connection) => {
    setUnlockingConnection(connection);
    setUnlockModalOpen(true);
  };

  const handleUnlockConfirm = () => {
    dispatch(updateUserConnection({
      connectionId: unlockingConnection._id,
      unlock: true, // Pass the unlock flag
    }));
    setUnlockModalOpen(false);
    setUnlockingConnection(null);
  };

  const handleUnlockModalClose = () => {
    setUnlockModalOpen(false);
    setUnlockingConnection(null);
  };


  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <div className='flex justify-between items-center mb-8'>
        <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200'>Connection Settings</h1>
        <div className='bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 p-3 rounded-md'>
          <span className='font-semibold'>Connections:</span> {currentConnectionsCount} / {accountLimit}
        </div>
      </div>
      <div className='bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-6 mb-8 rounded-lg shadow-md border'>
        <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2'>Summary</h2>
        <p className='text-gray-700 dark:text-gray-300 mb-2'>
          You can manage your connections to various platforms here. Some connections may have limits on the number of requests. Upgrade your access to unlock more connections.
        </p>
      </div>
  
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {Object.keys(platformConfig).map((platform) => (
          <div key={platform}>
            <ConnectionCard
              key={platform}
              platform={platform}
              logoUrl={platformConfig[platform].logoUrl}
              plans={plans}
              connections={connections.filter(conn => conn.platform === platform)}
              isLoading={isLoading}
              onConnect={(accountType) => handleAddAccount(platform, accountType)}
              onEdit={handleEditAccount}
              onDelete={handleDeleteAccount}
              onReauthenticate={handleReauthenticate}
              subscription={subscription}
              onUnlock={handleUnlock}
            />
          </div>
        ))}
      </div>
  
      {isModalOpen && (
        <ConnectionModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleModalSubmit}
          platform={currentPlatform}
          connections={connections.filter(conn => conn.platform === currentPlatform)}
          editingConnection={editingConnection}
        />
      )}
  
      {isReauthenticateModalOpen && (
        <ReauthenticateModal
          isOpen={isReauthenticateModalOpen}
          onClose={handleCloseReauthenticateModal}
          onConfirm={handleReauthenticateConfirm}
          connection={editingConnection}
        />
      )}
  
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteConfirm}
          connection={deletingConnection}
        />
      )}

      {isUnlockModalOpen && (
        <HelperModal
          isOpen={isUnlockModalOpen}
          onClose={handleUnlockModalClose}
          title="Unlock Account"
          confirmText="Unlock"
          cancelText="Cancel"
          onConfirm={handleUnlockConfirm}
        >
          <p className="text-sm text-gray-600 dark:text-gray-300">
            Are you sure you want to unlock this account? This action cannot be undone.
          </p>
        </HelperModal>
      )}
    </div>
  );
};

export default ConnectionSettings;
