import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  refreshAuthToken,
  setSessionTimeout,
  logout,
  reset,
} from './features/auth/authSlice';
import { addNotification } from './features/userSettings/settingsSlice';
import io from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Elements } from '@stripe/react-stripe-js';
import SessionTimeoutModal from './components/SessionTimeoutModal';
import PrivateRoute from './components/PrivateRoute';
import stripePromise from './config/stripeSetup';
import Home from './pages/Home.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermsOfService from './pages/TermsOfService.jsx';
import Login from './pages/Login.jsx';
import Register from './pages/Register.jsx';
import RegistrationClosed from './pages/RegistrationClosed.jsx';
import ArbieResultsAFV2 from './pages/ArbieResultsAFV2.jsx';
import SettingsLayout from './pages/Settings/Settings.jsx';
import DefaultLayout from './pages/Dashboard/DefaultLayout.jsx';
import ArbiBoostLayout from './pages/ArbiBoost/ArbiBoostLayout.jsx';
import VerificationPage from './pages/VerificationPage.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
import ForgotPasswordConfirm from './pages/ForgotPasswordConfirm.jsx';
import MetricsV2 from './pages/Dashboard/MetricsV2.jsx';
import Products from './pages/Dashboard/Products.jsx';
import PurchasesPage from './pages/Dashboard/PurchasesPage.jsx';
import OrdersPage from './pages/Dashboard/OrdersPage.jsx';
import PhotographyQueue from './pages/Dashboard/PhotographyQueue.jsx';
import ProductResearchPage from './pages/ProductResearchPage.jsx';
import Error404 from './pages/Error404.jsx';
import ProfileSettings from './pages/ProfileSettings/ProfileSettings.jsx';
import NotificationSettings from './pages/NotificationSettings/NotificationSettings.jsx';
import ConnectionSettings from './pages/ConnectionSettings/ConnectionSettings.jsx';
import SocialConnectionSettings from './pages/ConnectionSettings/SocialConnectionSettings.jsx';
import SubscriptionManagement from './pages/SubscriptionManagement/SubscriptionManagement.jsx';
import SecuritySettings from './pages/SecuritySettings/SecuritySettings.jsx';
import HelpSupport from './pages/HelpSupport/HelpSupport.jsx';
import PaymentPageV2 from './pages/SubscriptionManagement/PaymentPageV2.jsx';
import AboutUs from './pages/AboutUs.jsx';
import TwoFactorAuth from './pages/TwoFactorAuth.jsx';
import CategoryDetailPage from './pages/CategoryDetailPage.jsx';
import CryptoJS from 'crypto-js';
import ReactGA from 'react-ga4';
import ProductDetailsPage from './pages/Dashboard/ProductDetailsPage.jsx';
import InventorySettings from './pages/InventorySettings/InventorySettings.jsx';
import AdminRoute from './components/AdminRoute';
import BlogLayout from './pages/Blog/BlogLayout.jsx';
import BlogPost from './pages/Blog/BlogPost.jsx';

// Import OnboardingPage from the correct path
import OnboardingPage from './pages/Dashboard/OnboardingPage.jsx';

// Import Order Fulfillment Layout and Dashboard
import OrderFulfilmentLayout from './pages/OrderFulfilment/OrderFulfilmentLayout.jsx'; // Adjust the path based on your project structure
import OrderFulfillmentDashboard from './pages/OrderFulfilment/OrderFulfillmentDashboard.jsx';

import PowerSellersLandingPage from './pages/PowerSellersLandingPage.jsx';

import ExpressBetaInterest from './pages/ExpressBetaInterest.jsx'

ReactGA.initialize('G-CP50H37Y8M');

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Only track pageviews in production
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return null;
};

const App = () => {
  const dispatch = useDispatch();
  const { token, sessionTimeout, sessionExpirationTime, bypassSuccessful, user } = useSelector(
    (state) => state.auth
  );
  const { notificationSettings } = useSelector((state) => state.settings);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let timeoutId;
    let logoutId;
    let countdownInterval;

    if (token && sessionExpirationTime) {
      const timeRemaining = sessionExpirationTime - Date.now();
      const timeoutDuration = timeRemaining - 60000; // 1 minute before expiration

      if (timeoutDuration > 0) {
        timeoutId = setTimeout(() => {
          setShowTimeoutModal(true);
          localStorage.setItem('showTimeoutModal', 'true');
          dispatch(setSessionTimeout(true));

          countdownInterval = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(countdownInterval);
                dispatch(logout());
                dispatch(reset());
                setShowTimeoutModal(false);
                localStorage.removeItem('showTimeoutModal');
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        }, timeoutDuration);

        logoutId = setTimeout(() => {
          dispatch(logout());
          setShowTimeoutModal(false);
          localStorage.removeItem('showTimeoutModal');
        }, timeRemaining);
      } else {
        dispatch(logout());
      }
    }

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(logoutId);
      clearInterval(countdownInterval);
    };
  }, [dispatch, token, sessionExpirationTime]);

  useEffect(() => {
    if (sessionTimeout && token) {
      setShowTimeoutModal(true);
    }
  }, [sessionTimeout, token]);

  useEffect(() => {
    if (localStorage.getItem('showTimeoutModal') === 'true') {
      dispatch(logout());
      dispatch(reset());
      setShowTimeoutModal(false);
      localStorage.removeItem('showTimeoutModal');
    }
  }, [dispatch]);

  useEffect(() => {
    let socket;
    if (notificationSettings?.pushNotifications && user && token) {
      socket = io(process.env.REACT_APP_API_URL);

      // Register userId with the socket connection
      socket.emit('register', user._id);

      socket.on('notification', (data) => {
        dispatch(addNotification(data));
      });

      return () => {
        socket.off('notification');
      };
    }
  }, [dispatch, notificationSettings, user, token]);

  const handleContinue = () => {
    const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
    const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();

    dispatch(refreshAuthToken({ encryptedPassword }));
    setShowTimeoutModal(false);
    dispatch(setSessionTimeout(false));
    localStorage.removeItem('showTimeoutModal');
    setCountdown(60); // Reset countdown
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    setShowTimeoutModal(false);
    localStorage.removeItem('showTimeoutModal');
  };

  return (
    <>
      <ToastContainer />
      <Router>
        <Analytics />
        <div>
          {showTimeoutModal && token && (
            <SessionTimeoutModal
              show={showTimeoutModal}
              handleContinue={handleContinue}
              handleLogout={handleLogout}
              countdown={countdown}
            />
          )}
          <Elements stripe={stripePromise}>
            <Routes>
              {/* Public Routes */}
              <Route path='/' element={<Home />} />
              <Route path='/powersellers' element={<PowerSellersLandingPage />} />
              <Route path='/beta' element={<ExpressBetaInterest />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-of-service' element={<TermsOfService />} />
              <Route path='/about-us' element={<AboutUs />} />
              <Route path='/blog' element={<BlogLayout />} />
              <Route path='/blog/:postId' element={<BlogPost />} />

              {/* Authentication Routes */}
              <Route
                path='/login'
                element={!token ? <Login /> : <Navigate to='/arbie' />}
              />
              <Route path='/2fa' element={<TwoFactorAuth />} />
              <Route
                path='/register-closed'
                element={<RegistrationClosed />}
              />
              <Route
                path='/register'
                element={bypassSuccessful ? <Register /> : <Navigate to='/register-closed' />}
              />
              <Route
                path='/forgot-password'
                element={!token ? <ForgotPassword /> : <Navigate to='/' />}
              />
              <Route
                path='/forgot-password-confirm'
                element={
                  !token ? <ForgotPasswordConfirm /> : <Navigate to='/' />
                }
              />
              <Route
                path='/verification'
                element={
                  !token ? <VerificationPage /> : <Navigate to='/arbie' />
                }
              />

              {/* Payment Routes */}
              {/* <Route
                path='/payment'
                element={
                  <PrivateRoute>
                    <PaymentPage />
                  </PrivateRoute>
                }
              /> */}

              <Route
                path='/payment'
                element={
                  <PrivateRoute>
                    <PaymentPageV2 />
                  </PrivateRoute>
                }
              />

              {/* Settings Routes */}
              <Route
                path='/settings'
                element={
                  <PrivateRoute>
                    <SettingsLayout />
                  </PrivateRoute>
                }
              >
                <Route index element={<ProfileSettings />} />
                <Route
                  path='notifications'
                  element={<NotificationSettings />}
                />
                <Route path='connections' element={<ConnectionSettings />} />
                <Route
                  path='subscription'
                  element={<SubscriptionManagement />}
                />
                <Route path='security' element={<SecuritySettings />} />
                <Route path='help' element={<HelpSupport />} />
                <Route
                  path='social-media-connections'
                  element={
                    <AdminRoute>
                      <SocialConnectionSettings />
                    </AdminRoute>
                  }
                />
                <Route path='inventory-settings' element={<InventorySettings />} />
              </Route>

              {/* Dashboard Routes */}
              <Route
                path='/arbie'
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              >
                <Route index element={<MetricsV2 />} />
                <Route path='ecom-products' element={<Products />} />
                <Route path='products' element={<ProductDetailsPage />} />
                <Route path='products/:productId' element={<ProductDetailsPage />} />
                <Route path='photo-queue' element={<PhotographyQueue />} />
                <Route
                  path='results-feed'
                  element={
                    <AdminRoute>
                      <ArbieResultsAFV2 />
                    </AdminRoute>
                  }
                />
                <Route path='purchases' element={<PurchasesPage />} />
                <Route path='orders' element={<OrdersPage />} />
                <Route
                  path='research'
                  element={
                    <AdminRoute>
                      <ProductResearchPage />
                    </AdminRoute>
                  }
                />
                <Route path='categories/:categoryId' element={<CategoryDetailPage />} />
                {/* Added Onboarding Page Route */}
                <Route path='onboarding' element={<OnboardingPage />} />
              </Route>

              {/* ArbiBoost Routes */}
              <Route
                path='/arbiBoost'
                element={
                  <PrivateRoute>
                    <ArbiBoostLayout />
                  </PrivateRoute>
                }
              >
              </Route>

              {/* Order Fulfillment Routes */}
              <Route
                path='/order-fulfilment'
                element={
                  <PrivateRoute>
                    <OrderFulfilmentLayout />
                  </PrivateRoute>
                }
              >
                <Route index element={<OrderFulfillmentDashboard />} />
                {/* Add additional child routes here if needed */}
              </Route>

              {/* Catch-All Route */}
              <Route
                path='*'
                element={token ? <Error404 /> : <Navigate to='/login' />}
              />
            </Routes>
          </Elements>
        </div>
      </Router>
    </>
  );
};

export default App;
