// src/features/orders/orderSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ordersService from './ordersService';

const initialState = {
  orders: [],
  totalOrdersCount: 0,
  filteredItems: [],
  allItems: [],
  order: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  page: 1,
  itemsPerPage: 25,
  hasMoreItems: true,
  filters: {
    connectionFilter: 'all',
    searchQuery: '',
    dateRange: { start: null, end: null },
  },
  totalAvailableResults: 0,
};

// Helper function to apply filters and sorting to orders
const applyFiltersAndSort = (items, filters) => {
  const { connectionFilter, searchQuery, dateRange } = filters;

  let filteredItems = items.filter((item) => {
    const connectionMatch = connectionFilter === 'all' || item.accountID === connectionFilter;

    const searchQueryMatch =
      !searchQuery ||
      item.items.some((subItem) => {
        const query = searchQuery.toLowerCase();
        const itemMatches =
          subItem.title?.toLowerCase().includes(query) ||
          subItem.itemId?.toLowerCase().includes(query) ||
          subItem.ebayId?.toLowerCase().includes(query);

        const orderMatches =
          item.sellerUsername?.toLowerCase().includes(query) ||
          item.orderComments?.toLowerCase().includes(query);

        return itemMatches || orderMatches;
      });

    const dateMatch =
      (!dateRange.start || new Date(item.orderDate) >= new Date(dateRange.start)) &&
      (!dateRange.end || new Date(item.orderDate) <= new Date(dateRange.end));

    return connectionMatch && searchQueryMatch && dateMatch;
  });

  // Sort by most recent orders
  filteredItems.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

  return filteredItems;
};

// Async actions
export const fetchAllOrders = createAsyncThunk(
  'orders/fetchAllOrders',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await ordersService.fetchAllOrders(token);
      return await ordersService.fetchOrders(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.fetchOrders(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchOrderById = createAsyncThunk(
  'orders/fetchOrderById',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.fetchOrderById(id, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (orderData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.createOrder(orderData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (orderData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.updateOrder(orderData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.deleteOrder(id, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchOrderCount = createAsyncThunk(
  'orders/fetchOrderCount',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await ordersService.fetchOrderCount(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    reset: (state) => {
      state.orders = [];
      state.filteredItems = [];
      state.order = null;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.page = 1;
      state.hasMoreItems = true;
    },
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      state.page = 1;
      const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
      state.filteredItems = filteredItems;
      state.orders = filteredItems.slice(0, state.itemsPerPage);
      state.totalAvailableResults = filteredItems.length;
      state.hasMoreItems = filteredItems.length > state.itemsPerPage;
    },
    fetchMoreOrdersFromState: (state) => {
      const startIndex = state.page * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      const newOrders = state.filteredItems.slice(startIndex, endIndex);
      state.orders = [...state.orders, ...newOrders];
      state.page += 1;
      if (newOrders.length < state.itemsPerPage) {
        state.hasMoreItems = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allItems = Array.isArray(action.payload) ? action.payload : [];
        const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
        state.filteredItems = filteredItems;
        state.orders = filteredItems.slice(0, state.itemsPerPage);
        state.totalAvailableResults = filteredItems.length;
        state.hasMoreItems = filteredItems.length > state.itemsPerPage;
      })
      .addCase(fetchAllOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allItems = Array.isArray(action.payload) ? action.payload : [];
        const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
        state.filteredItems = filteredItems;
        state.orders = filteredItems.slice(0, state.itemsPerPage);
        state.totalAvailableResults = filteredItems.length;
        state.hasMoreItems = filteredItems.length > state.itemsPerPage;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrderById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.order = action.payload;
      })
      .addCase(fetchOrderById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders.push(action.payload);
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.orders.findIndex((order) => order._id === action.payload._id);
        if (index !== -1) {
          state.orders[index] = action.payload;
        }
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = state.orders.filter((order) => order._id !== action.payload._id);
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrderCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // action.payload should be { count: number }
        state.totalOrdersCount = action.payload.count || 0;
      })
      .addCase(fetchOrderCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setFilter, fetchMoreOrdersFromState } = ordersSlice.actions;
export default ordersSlice.reducer;
