import React from 'react';

const HelperModal = ({
  isOpen,
  onClose,
  title,
  children,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  isLoading = false,
}) => {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    // Close modal when clicking outside the content area
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md mx-4">
        <div className="border-b dark:border-gray-700 px-4 py-3">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{title}</h3>
        </div>
        <div className="px-4 py-4">
          {children}
        </div>
        <div className="flex justify-end space-x-4 px-4 py-3 border-t dark:border-gray-700">
          <button
            onClick={onClose}
            className="bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300 font-bold py-2 px-4 rounded hover:bg-gray-400 dark:hover:bg-gray-600"
          >
            {cancelText}
          </button>
          <button
            onClick={onConfirm}
            className={`${
              isLoading
                ? 'bg-red-400 dark:bg-red-600 cursor-not-allowed'
                : 'bg-red-500 hover:bg-red-600'
            } text-white font-bold py-2 px-4 rounded`}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelperModal;
