import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector to access Redux state
import { Link } from 'react-router-dom';
import { SunIcon, MoonIcon } from '@heroicons/react/solid';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReferFriendModal from './ReferFriendModal'; // Import the ReferFriendModal

import arbiEngineLogoDark from '../images/arbienginelogo.png';
import arbiEngineLogoLight from '../images/arbieBlackNew.png';

const Footer = () => {
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Get user and token from Redux state
  const { user, token } = useSelector((state) => state.auth);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }

    const themeEvent = new CustomEvent('themeChange', {
      detail: { theme: darkMode ? 'dark' : 'light' },
    });
    window.dispatchEvent(themeEvent);
  }, [darkMode]);

  return (
    <footer className='bg-slate-50 dark:bg-gray-900 relative z-10' role="contentinfo">
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='py-16 flex flex-col items-center sm:flex-row justify-between'>
          {/* Logo and slogan section */}
          <div className='flex flex-col items-center sm:items-start'>
            <Link to='/'>
              <img
                src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
                alt='ArbiEngine Logo - Discover Ecommerce Arbitrage Opportunities'
                className='h-10 w-auto'
              />
            </Link>
            <p className='mt-4 text-center sm:text-left text-sm text-slate-700 dark:text-slate-300'>
              <span className='hidden lg:inline self-center text-xs font-semibold sm:text-sm whitespace-nowrap dark:text-white'>
                <p className={`text-gray-800 dark:text-white font-bold pr-4`}>
                  Where{' '}
                  <span className='text-indigo-600 dark:text-indigo-400'>
                    data
                  </span>{' '}
                  drive{' '}
                  <span className={`text-indigo-600 dark:text-indigo-400`}>
                    decisions
                  </span>.
                </p>
              </span>
            </p>
          </div>
          {/* Social Media Links */}
          <nav className='mt-10 text-sm' aria-label='Social Media Links'>
            <div className='flex justify-center sm:justify-start gap-x-6'>
              <a
                href='https://x.com/ArbiEngine'
                target='_blank'
                rel='noopener noreferrer'
                className='text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400'
                aria-label='Follow us on Twitter'
              >
                <FaTwitter className='h-6 w-6' />
              </a>
              <a
                href='https://www.instagram.com/arbiengine'
                target='_blank'
                rel='noopener noreferrer'
                className='text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400'
                aria-label='Follow us on Instagram'
              >
                <FaInstagram className='h-6 w-6' />
              </a>
              <a
                href='https://www.linkedin.com/company/arbiengine'
                target='_blank'
                rel='noopener noreferrer'
                className='text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400'
                aria-label='Connect with us on LinkedIn'
              >
                <FaLinkedin className='h-6 w-6' />
              </a>
            </div>
          </nav>
          {/* Refer a Friend, Links, and Theme toggle */}
          <div className='mt-10 sm:mt-0 flex flex-col items-center sm:items-end'>
            <button
              onClick={toggleDarkMode}
              className='mb-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-lg text-sm p-2.5'
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? (
                <SunIcon className='w-5 h-5' />
              ) : (
                <MoonIcon className='w-5 h-5' />
              )}
            </button>
            {/* Show Refer a Friend button only if user and token are present (Hiding this feature for later dont delete) */}
            {/* {user && token && (
              <button
                className='mt-4 px-4 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-500'
                onClick={openModal}
              >
                Refer a Friend
              </button>
            )} */}
            {/* Additional Links */}
            <div className='mt-4 flex flex-col items-center sm:items-end'>
              <Link
                to='/terms-of-service'
                className='text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400'
              >
                Terms of Service
              </Link>
              <Link
                to='/privacy-policy'
                className='text-slate-700 dark:text-slate-300 hover:text-indigo-600 dark:hover:text-indigo-400'
              >
                Privacy Policy
              </Link>
            </div>
            <p className='mt-4 text-sm text-slate-500 dark:text-slate-400'>
              © 2024 ArbiEngine. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      {/* Refer a Friend Modal */}
      <ReferFriendModal isOpen={isModalOpen} onClose={closeModal} />
    </footer>
  );
};

export default Footer;
