import React from 'react';
import { useFormikContext } from 'formik';

const ProductOrganization = ({ category, setFieldValue, darkMode }) => {
  const { errors, touched, handleBlur } = useFormikContext();

  const handleCategoryChange = (e) => {
    const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
    setFieldValue('category', valueWithoutSpaces);
  };

  return (
    <div className={`p-6 rounded-lg shadow-md ${darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'}`}>
      <h3 className="text-lg font-bold mb-4">Product Organization</h3>
      <div className="mb-6">
        <label className={`block text-sm font-medium mb-2 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
          Category ID
        </label>
        <input
          type="text"
          name="category"
          value={category || ''}
          onChange={handleCategoryChange}
          onBlur={handleBlur}
          className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-gray-100 border-gray-600' : 'bg-gray-50 text-gray-900 border-gray-300'
            }`}
          placeholder="Enter category ID"
        />
        {errors.category && touched.category && (
          <p className="text-red-500 mt-1 text-sm">{errors.category}</p>
        )}
      </div>
    </div>
  );
};

export default ProductOrganization;
