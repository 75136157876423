import React from 'react';
import logo from '../../images/logo.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PaymentLeftSection = () => {
  const navigate = useNavigate();
  const { subscription, selectedPlan } = useSelector((state) => state.subscription);
  const isFreeTrial = !subscription;  
  const trialDays = selectedPlan?.trialDays || 0;

  const priceToDisplay = isFreeTrial ? 0 : selectedPlan?.price || 0;

  const handleBackClick = () => {
    navigate('/settings/subscription');
  };

  return (
    <div className="max-w-lg ml-auto px-12 py-8">
      {/* Back Button with Logo */}
      <div className="flex items-center space-x-3 mb-8">
        <button
          className="text-gray-600 hover:text-gray-800 text-lg"
          onClick={handleBackClick}
        >
          ←
        </button>
        <img src={logo} alt="Logo" className="h-8" />
      </div>

      {/* Subscription Heading */}
      <h1 className="text-xl font-medium text-gray-600 mb-3">
        Subscribe to {selectedPlan?.name} Plan
      </h1>

      {/* Pricing */}
      <div className="flex items-center space-x-3 mb-8">
        <span className="text-5xl font-extrabold text-gray-900">
          ${priceToDisplay}
        </span>
        <div className="flex flex-col text-base">
          <span className="text-base text-gray-500 leading-none">per</span>
          <span className="text-base text-gray-500 leading-none">month</span>
        </div>
      </div>

      {isFreeTrial && (
        <div className="mb-4">
          <p className="text-sm text-green-600">
            {trialDays > 0
              ? `This plan includes a ${trialDays}-day free trial. You pay $0 until the trial ends.`
              : 'This plan includes a free trial. You pay $0 until the trial ends.'}
            <br />
            After the trial, you’ll be charged ${selectedPlan?.price}/month.
          </p>
        </div>
      )}

      {/* Details */}
      <div className="border-t border-gray-200 py-6">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-base font-medium text-gray-800">
              {selectedPlan?.name} Subscription
            </p>
            <p className="text-base text-gray-500">Billed monthly</p>
          </div>
          <p className="text-base font-semibold text-gray-800">
            ${priceToDisplay}
          </p>
        </div>
      </div>

      {/* Subtotal and Tax */}
      <div className="border-t border-gray-200 py-6">
        <div className="flex justify-between items-center mb-6">
          <p className="text-base text-gray-800">Subtotal</p>
          <p className="text-base font-semibold text-gray-800">
            ${priceToDisplay}
          </p>
        </div>
      </div>

      {/* Total Due Today */}
      <div className="border-t border-gray-200 pt-6">
        <div className="flex justify-between items-center">
          <p className="text-lg font-bold text-gray-800">Total due today</p>
          <p className="text-xl font-extrabold text-gray-900">
            ${priceToDisplay}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentLeftSection;
