import React from "react";
import { useNavigate } from "react-router-dom";
import fallbackImage from "../../images/arbi_network.mp4"; // Update the file path as needed

const CentralizedPlatform = () => {
  const navigate = useNavigate();

  const handleExpressInterestClick = () => {
    navigate("/beta");
    // Add a short delay before scrolling to top
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  return (
    <section className="relative w-full min-h-[70vh] bg-gradient-to-b from-indigo-800 via-indigo-900 to-indigo-700 dark:from-gray-900 dark:via-gray-900 dark:to-black flex items-center justify-center overflow-hidden">
      {/* Background Video or Dots Animation */}
      <div className="absolute inset-0 z-0">
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
        >
          <source src={fallbackImage} type="video/mp4" />
          {/* Fallback Image */}
          <img
            src={fallbackImage}
            alt="Centralized Platform for Businesses"
            className="w-full h-full object-cover"
          />
        </video>
      </div>

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-indigo-700 via-indigo-500 to-indigo-400 dark:from-indigo-700 dark:via-indigo-900 dark:to-indigo-800 opacity-95 z-10"></div>

      {/* Content */}
      <div className="relative z-20 text-center px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
        {/* Heading */}
        <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-extrabold text-white dark:text-gray-100 leading-snug tracking-tight">
          Say goodbye to plugins, <br /> software bloat, and confusion.
        </h1>

        {/* Supporting Text */}
        <p className="mt-4 sm:mt-6 text-base sm:text-lg lg:text-xl xl:text-2xl text-gray-200 dark:text-gray-400">
          Empower your business with a centralized platform that eliminates silos,
          reduces complexity, and lets you focus on scaling your brand while
          delivering exceptional value to your customers.
        </p>

        {/* Single Call-to-Action Button */}
        <div className="mt-6 sm:mt-8 flex justify-center">
          <button
            onClick={handleExpressInterestClick}
            className="w-full sm:w-auto rounded-full bg-indigo-500 dark:bg-indigo-700 px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white shadow-lg hover:bg-indigo-400 dark:hover:bg-indigo-400 transition focus:outline-none focus:ring-2 focus:ring-indigo-300 dark:focus:ring-indigo-500"
          >
            Join the Beta Today
          </button>
        </div>
      </div>
    </section>
  );
};

export default CentralizedPlatform;
