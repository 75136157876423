import React from 'react'
import {
  SearchIcon,
  ChartSquareBarIcon,
  PuzzleIcon,
  CogIcon,
  ShoppingCartIcon,
  ClipboardCheckIcon,
  SpeakerphoneIcon,
  UsersIcon,
} from '@heroicons/react/outline'

const features = [
  {
    icon: <SearchIcon className="h-8 w-8" />,
    title: 'Product Sourcing',
    description:
      'Spot high-margin opportunities and outmaneuver the competition with advanced research tools.',
  },
  {
    icon: <ChartSquareBarIcon className="h-8 w-8" />,
    title: 'Data Analysis',
    description:
      'Transform raw metrics into profit-driving insights, so you can grow with confidence.',
  },
  {
    icon: <PuzzleIcon className="h-8 w-8" />,
    title: 'Process Automation',
    description:
      'Eliminate manual tasks and reclaim time for strategy and innovation in your operations.',
  },
  {
    icon: <CogIcon className="h-8 w-8" />,
    title: 'System Integration',
    description:
      'Link all your ecommerce tools under one roof for a cohesive, streamlined workflow.',
  },
  {
    icon: <ShoppingCartIcon className="h-8 w-8" />,
    title: 'Multichannel Listing',
    description:
      'Effortlessly expand to multiple marketplaces from a single, centralized dashboard.',
  },
  {
    icon: <ClipboardCheckIcon className="h-8 w-8" />,
    title: 'Order Fulfillment',
    description:
      'Speed up pick-and-pack and ensure on-time deliveries—keeping customers delighted.',
  },
  {
    icon: <SpeakerphoneIcon className="h-8 w-8" />,
    title: 'Brand Development',
    description:
      'Amplify your brand’s visibility with unified messaging and targeted marketing campaigns.',
  },
  {
    icon: <UsersIcon className="h-8 w-8" />,
    title: 'Customer Engagement',
    description:
      'Deepen loyalty by personalizing every touchpoint with robust CRM and engagement tools.',
  },
]

const MissionSection = () => {
  return (
    <section className="relative w-full py-16 sm:py-24 text-gray-900 dark:text-white">
      <div className="mx-auto max-w-7xl px-6 sm:px-8 lg:px-12">
        {/* Section Heading */}
        <div className="mx-auto max-w-3xl text-center mb-12">
          <h2 className="text-4xl sm:text-5xl font-extrabold mb-4">
            Building a Simpler Path to Ecommerce Success
          </h2>
          <p className="text-lg sm:text-xl text-gray-600 dark:text-gray-300">
            ArbiEngine brings together every aspect of your online business—
            from product sourcing to customer engagement—so you can focus on
            rapid growth instead of juggling disconnected tools.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, idx) => (
            <div
              key={idx}
              className="group rounded-xl bg-white dark:bg-gray-800 shadow-lg p-6 transition-transform hover:scale-105"
            >
              {/* Icon */}
              <div className="mb-4 inline-flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600 text-white">
                {feature.icon}
              </div>
              {/* Title */}
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {feature.title}
              </h3>
              {/* Description */}
              <p className="mt-3 text-sm sm:text-base text-gray-600 dark:text-gray-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default MissionSection
