import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { reactivateSubscription } from '../../features/subscriptions/subscriptionSlice';

const SubscriptionTierCard = ({
  tier,
  subscription,
  onSubscribe,
  onUpgradeDowngrade,
  tiers,
}) => {
  const dispatch = useDispatch();
  // Determine if the user is subscribed to this tier
  const isSubscribed =
    subscription &&
    subscription.plan === tier.name &&
    ['active', 'trialing'].includes(subscription.status);

  const isPendingReactivation =
    subscription &&
    subscription.plan === tier.name &&
    subscription.status === 'cancelled' &&
    new Date(subscription.endDate) > new Date();

  // Find the current tier based on the subscription's plan
  const currentTier = subscription
    ? tiers.find((t) => t.name === subscription.plan)
    : null;

  // Determine if the selected tier is an upgrade or downgrade
  const canUpgrade =
    currentTier && tier.hierarchy > currentTier.hierarchy;
  const canDowngrade =
    currentTier && tier.hierarchy < currentTier.hierarchy;

  // Render the price based on the tier
  const renderPrice = () => {
    if (tier.name === 'Enterprise') {
      return (
        <span className='text-lg font-bold text-indigo-700 dark:text-indigo-400'>
          Custom Pricing
        </span>
      );
    }
    return (
      <span className='text-xl font-semibold'>
        ${tier.price}/month
      </span>
    );
  };

  // Reactivate subscription handler
  const handleReactivate = () => {
    dispatch(reactivateSubscription(subscription.stripeSubscriptionId));
  };

  return (
    <div
      className={`relative flex flex-col justify-between p-4 border rounded-lg shadow-lg transform transition-transform hover:shadow-2xl hover:scale-105 duration-300 
      ${
        isSubscribed || isPendingReactivation
          ? 'border-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
          : 'bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900'
      }`}
    >
      {/* Inner Card Content */}
      <div
        className={`flex flex-col justify-between h-full p-4 rounded-lg ${
          isSubscribed || isPendingReactivation
            ? 'bg-white dark:bg-gray-900'
            : 'bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900'
        }`}
      >
        <div>
          <h2
            className={`text-2xl font-bold mb-2 ${
              isSubscribed || isPendingReactivation
                ? 'text-gray-900 dark:text-white'
                : 'text-gray-900 dark:text-white'
            }`}
          >
            {tier.name}
          </h2>
          <p className='mb-2'>{renderPrice()}</p>
          <p
            className={`mb-2 ${
              isSubscribed || isPendingReactivation
                ? 'text-gray-900 dark:text-white'
                : 'text-gray-600 dark:text-white'
            }`}
          >
            {tier.description}
          </p>
          <ul className='mb-4 space-y-1'>
            {tier.features.map((feature, index) => (
              <li
                key={index}
                className={`flex items-center ${
                  isSubscribed || isPendingReactivation
                    ? 'text-gray-900 dark:text-white'
                    : 'text-gray-600 dark:text-white'
                }`}
              >
                <CheckCircleIcon className='w-5 h-5 text-green-500 mr-2' />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-4'>
          {isSubscribed ? (
            <button
              className='bg-gray-500 text-white font-bold py-2 px-4 rounded w-full cursor-not-allowed'
              disabled
            >
              Already Subscribed
            </button>
          ) : isPendingReactivation ? (
            <button
              onClick={handleReactivate}
              className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full'
            >
              Reactivate Subscription
            </button>
          ) : tier.name === 'Enterprise' ? (
            <button
              onClick={onSubscribe}
              className='bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full'
            >
              Contact Us
            </button>
          ) : canUpgrade ? (
            <button
              onClick={() => onUpgradeDowngrade(tier)}
              className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full'
            >
              Upgrade
            </button>
          ) : canDowngrade ? (
            <button
              onClick={() => onUpgradeDowngrade(tier)}
              className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-full'
            >
              Downgrade
            </button>
          ) : (
            <button
              onClick={() => onSubscribe(tier)}
              className='bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full'
            >
              Subscribe
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTierCard;
