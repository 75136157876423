import React, { useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import { createTicket, reset } from '../../features/userSettings/settingsSlice'

const HelpSupport = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)
  const [form, setForm] = useState({
    user: '',
    name: '',
    email: '',
    message: '',
    issue: '',
    priority: 'Low',
    severity: 'Minor',
  })
  const { user } = useSelector((state) => state.auth)
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  const priorities = ['Low', 'Medium', 'High', 'N/A'];
  const severities = ['Minor', 'Major', 'Critical', 'N/A'];
  const commonIssues = [
    'Bug in the app',
    'Feature request',
    'Performance issue',
    'Security concern',
    'Enterprise Plan Upgrade',
    'Other',
  ];

  useEffect(() => {
    if (user) {
      setForm((prevForm) => ({ ...prevForm, user: user._id }));
    }

    if (location.state) {
      setForm((prevForm) => ({
        ...prevForm,
        ...location.state,
      }));
    }
  }, [user, location.state]);

  const validateForm = () => {
    const errors = {}
    if (!form.name) errors.name = 'Name is required'
    if (!form.email) errors.email = 'Email is required'
    else if (!/\S+@\S+\.\S+/.test(form.email))
      errors.email = 'Email address is invalid'
    if (!form.message) errors.message = 'Message is required'
    if (!form.issue) errors.issue = 'Issue is required'
    return errors
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formErrors = validateForm()
    if (Object.keys(formErrors).length === 0) {
      dispatch(createTicket({ formData: form }))
        .unwrap()
        .then(() => {
          toast.success('Ticket created successfully!')
          setIsOpen(false)
          setForm({
            name: '',
            email: '',
            message: '',
            issue: '',
            priority: 'Low',
            severity: 'Minor',
          })
        })
        .catch((error) => {
          toast.error('Failed to create ticket: ' + error)
        })
        .finally(() => {
          dispatch(reset())
        })
    } else {
      setErrors(formErrors)
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-5'>
        Help & Support
      </h1>
      <div className='mb-6'>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label
              htmlFor='name'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
            {errors.name && (
              <p className='text-red-500 text-xs mt-1'>{errors.name}</p>
            )}
          </div>
          <div className='mb-4'>
            <label
              htmlFor='email'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
            {errors.email && (
              <p className='text-red-500 text-xs mt-1'>{errors.email}</p>
            )}
          </div>
          <div className='mb-4'>
            <label
              htmlFor='message'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Message
            </label>
            <textarea
              id='message'
              name='message'
              value={form.message}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              rows='4'
            />
            {errors.message && (
              <p className='text-red-500 text-xs mt-1'>{errors.message}</p>
            )}
          </div>
          <div className='mb-4'>
            <label
              htmlFor='issue'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Issue
            </label>
            <select
              id='issue'
              name='issue'
              value={form.issue}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            >
              <option value=''>Select an issue</option>
              {commonIssues.map((issue, index) => (
                <option key={index} value={issue}>
                  {issue}
                </option>
              ))}
            </select>
            {errors.issue && (
              <p className='text-red-500 text-xs mt-1'>{errors.issue}</p>
            )}
          </div>
          <div className='mb-4'>
            <label
              htmlFor='priority'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Priority
            </label>
            <select
              id='priority'
              name='priority'
              value={form.priority}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            >
              {priorities.map((priority, index) => (
                <option key={index} value={priority}>
                  {priority}
                </option>
              ))}
            </select>
          </div>
          <div className='mb-4'>
            <label
              htmlFor='severity'
              className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
            >
              Severity
            </label>
            <select
              id='severity'
              name='severity'
              value={form.severity}
              onChange={handleChange}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            >
              {severities.map((severity, index) => (
                <option key={index} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
          <div className='flex items-center justify-between'>
            <button
              type='submit'
              className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Submit Ticket
            </button>
            <button
              type='button'
              onClick={closeModal}
              className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-200'
                  >
                    Create a Ticket
                  </Dialog.Title>
                  <form onSubmit={handleSubmit} className='mt-4'>
                    <div className='mb-4'>
                      <label
                        htmlFor='issue'
                        className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
                      >
                        Issue
                      </label>
                      <select
                        id='issue'
                        name='issue'
                        value={form.issue}
                        onChange={handleChange}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      >
                        <option value=''>Select an issue</option>
                        {commonIssues.map((issue, index) => (
                          <option key={index} value={issue}>
                            {issue}
                          </option>
                        ))}
                      </select>
                      {errors.issue && (
                        <p className='text-red-500 text-xs mt-1'>
                          {errors.issue}
                        </p>
                      )}
                    </div>
                    <div className='mb-4'>
                      <label
                        htmlFor='priority'
                        className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
                      >
                        Priority
                      </label>
                      <select
                        id='priority'
                        name='priority'
                        value={form.priority}
                        onChange={handleChange}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      >
                        {priorities.map((priority, index) => (
                          <option key={index} value={priority}>
                            {priority}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='mb-4'>
                      <label
                        htmlFor='severity'
                        className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
                      >
                        Severity
                      </label>
                      <select
                        id='severity'
                        name='severity'
                        value={form.severity}
                        onChange={handleChange}
                        className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      >
                        {severities.map((severity, index) => (
                          <option key={index} value={severity}>
                            {severity}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex items-center justify-between'>
                      <button
                        type='submit'
                        className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                      >
                        Submit Ticket
                      </button>
                      <button
                        type='button'
                        onClick={closeModal}
                        className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default HelpSupport
