import React from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import Header from '../components/Header';
import Footer from '../components/Footer';
import PowerSellersHero from '../components/PowerSellers/PowerSellersHero';
import ValueAddTabs from '../components/PowerSellers/ValueAddTabs';
import EarlyAdopterInitiative from '../components/PowerSellers/EarlyAdopterInitiative';
import CentralizedPlatform from '../components/PowerSellers/CentralizedPlatform'

function PowerSellersLandingPage() {
  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        {/* Basic HTML metadata */}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Title */}
        <title>ArbiEngine for Power Sellers – Rethink Your Product Listing Flow</title>

        {/* Primary Description */}
        <meta
          name="description"
          content="Join ArbiEngine’s exclusive Beta for eBay Power Sellers. Simplify listing flows, centralize fulfillment, and lock in exclusive pricing while shaping the future of multi-vertical ecommerce."
        />

        {/* Keywords (largely optional for modern SEO) */}
        <meta
          name="keywords"
          content="ebay power sellers, ecommerce platform, listing flow, order fulfillment, multi-account management, ArbiEngine Beta"
        />

        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://arbiengine.com/powersellers" />
        <meta name="author" content="ArbiEngine" />

        {/* JSON-LD for Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "ArbiEngine for Power Sellers – Rethink Your Product Listing Flow",
            description:
              "Join ArbiEngine’s exclusive Beta for eBay Power Sellers. Simplify listing flows, centralize fulfillment, and lock in exclusive pricing while shaping the future of multi-vertical ecommerce.",
            url: "https://arbiengine.com/powersellers",
            mainEntity: {
              "@type": "Organization",
              name: "ArbiEngine",
              logo: "https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png",
              sameAs: [
                "https://x.com/ArbiEngine",
                "https://www.instagram.com/arbiengine",
                "https://www.linkedin.com/company/arbiengine",
              ],
            },
          })}
        </script>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="ArbiEngine for Power Sellers – Rethink Your Product Listing Flow"
        />
        <meta
          property="og:description"
          content="Streamline your eBay Power Seller operations with ArbiEngine—one platform for inventory, fulfillment, and growth."
        />
        <meta
          property="og:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
        <meta
          property="og:url"
          content="https://arbiengine.com/powersellers"
        />
        <meta property="og:site_name" content="ArbiEngine" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ArbiEngine for Power Sellers – Rethink Your Product Listing Flow"
        />
        <meta
          name="twitter:description"
          content="Experience advanced listing flows, fulfill orders faster, and manage multiple eBay stores seamlessly. Join our Beta today!"
        />
        <meta
          name="twitter:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
      </Helmet>


      {/* Page Layout */}
      <div className="relative min-h-screen bg-gray-100 dark:bg-gray-900">
        {/* Header */}
        <Header isSticky={true} />

        {/* Hero Section */}
        <PowerSellersHero />

        {/* Value Add Section */}
        <ValueAddTabs />



        {/* Early Adopter Initiative */}
        <EarlyAdopterInitiative />

        {/* CentralizedPlatform Section */}
        <CentralizedPlatform />

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default PowerSellersLandingPage;
