import React from "react";
import { useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/solid"; // Correct icon for download

const BillingDetails = () => {
  const { billingDetails, isLoading, isError } = useSelector(
    (state) => state.subscription
  );

  if (isLoading) {
    return <p>Loading invoices...</p>;
  }

  // If there is an error, treat it as if no invoices are available
  if (isError || !billingDetails || billingDetails.length === 0) {
    return (
      <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-4">
          Invoices
        </h2>
        <p className="text-gray-500 dark:text-gray-300 mb-4">
          View and track your billing and invoices below.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mt-4">
          No invoices available.
        </p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
      <div>
        <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-4">
          Invoices
        </h2>
        <p className="text-gray-500 dark:text-gray-300 mb-4">
          View and track your billing and invoices below.
        </p>
      </div>
      <div className="divide-y divide-gray-200">
        {billingDetails.map((detail) => (
          <div
            key={detail.id}
            className="flex justify-between items-center py-4 space-x-4"
          >
            {/* Invoice Number */}
            <div className="w-1/3">
              <p className="font-semibold text-gray-700 dark:text-white truncate">
                {detail.invoiceNumber || detail.id}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                {new Date(detail.date).toLocaleDateString()}
              </p>
            </div>

            {/* Badge */}
            <div className="w-1/3 flex justify-center">
              <span
                className={`text-xs font-semibold px-3 py-1 rounded-full ${
                  detail.status.toUpperCase() === "PAID"
                    ? "bg-green-100 text-green-600"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {detail.status.toUpperCase() === "PAID" ? "PAID" : "OVERDUE"}
              </span>
            </div>

            {/* Amount Paid */}
            <div className="w-1/4 text-right">
              <p className="font-semibold text-gray-700 dark:text-white">
                ${detail.amountPaid.toFixed(2)}
              </p>
            </div>

            {/* Download Button */}
            <div className="w-1/12 text-right">
              {detail.invoicePdf ? (
                <a
                  href={detail.invoicePdf}
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 transition duration-150"
                  title="Download Invoice"
                >
                  <DownloadIcon className="h-5 w-5" />
                </a>
              ) : (
                <span
                  className="text-gray-400 cursor-not-allowed"
                  title="Invoice not available"
                >
                  <DownloadIcon className="h-5 w-5" />
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BillingDetails;
