import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/marketing`;

// Helper function to get config with token
// (Used for refer-a-friend which requires a protected route)
const getConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

/**
 * Send a refer-a-friend email (POST request)
 */
export const sendReferAFriendEmail = async (email, token, senderName) => {
  const body = { email, senderName };
  const { data } = await axios.post(`${baseUrl}/refer-friend`, body, getConfig(token));
  return data;
};

/**
 * Create a new Beta Lead (POST /api/marketing/beta-signup)
 * NOTE: This endpoint does not require a token (only reCAPTCHA).
 */
export const createLead = async (leadData) => {
  // leadData should include your lead fields + captcha if needed
  const { data } = await axios.post(`${baseUrl}/beta-signup`, leadData);
  return data; // e.g. { message: 'Lead created successfully!', lead: {...} }
};
