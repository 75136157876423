import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subscriptionService from './subscriptionService';

const initialState = {
  subscription: JSON.parse(localStorage.getItem('subscription')) || null,
  selectedPlan: JSON.parse(localStorage.getItem('selectedPlan')) || null,
  plans: JSON.parse(localStorage.getItem('plans')) || [],
  paymentMethod: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  billingDetails: [],
};

// Async thunks
export const getPaymentMethod = createAsyncThunk(
  'subscription/getPaymentMethod',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.fetchPaymentMethod(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunks
export const getSubscription = createAsyncThunk(
  'subscription/get',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.fetchSubscription(userId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  'subscription/create',
  async (subscriptionData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.createSubscription(subscriptionData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  'subscription/update',
  async ({ subscriptionId, newPlanId, subscriptionItemId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.updateSubscription(
        { subscriptionId, newPlanId, subscriptionItemId },
        token
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async ({ subscriptionId, subscriptionItemId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.cancelSubscriptionItem(subscriptionId, subscriptionItemId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPlans = createAsyncThunk(
  'subscription/getPlans',
  async (encryptedPassword, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return await subscriptionService.fetchPlans(encryptedPassword, config);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePaymentMethod = createAsyncThunk(
  'subscription/deletePaymentMethod',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.deletePaymentMethod(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  'subscription/addPaymentMethod',
  async ({ paymentMethodId, billingDetails }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.addPaymentMethod({ paymentMethodId, billingDetails }, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const toggleAutoRenew = createAsyncThunk(
  'subscription/toggleAutoRenew',
  async ({ subscriptionId, autoRenew }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.toggleAutoRenew(subscriptionId, autoRenew, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchBillingDetails = createAsyncThunk(
  'subscription/fetchBillingDetails',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.fetchBillingDetails(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reactivateSubscription = createAsyncThunk(
  'subscription/reactivate',
  async (subscriptionId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.reactivateSubscription(subscriptionId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetSubscriptionState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    setSelectedTier: (state, action) => {
      state.selectedPlan = action.payload;
      localStorage.setItem('selectedPlan', JSON.stringify(action.payload));
    },
    setSelectedPlanFromStorage: (state, action) => {
      state.subscription = action.payload.subscription;
      state.selectedPlan = action.payload.selectedPlan;
    },
    clearError: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload.subscription;
        localStorage.setItem('subscription', JSON.stringify(action.payload.subscription));
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.subscription = null;
        localStorage.removeItem('subscription');
      })
      .addCase(createSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload;
        localStorage.setItem('subscription', JSON.stringify(action.payload));
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscription = action.payload;
        localStorage.setItem('subscription', JSON.stringify(action.payload));
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload.subscription;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPlans.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.plans = action.payload;
        localStorage.setItem('plans', JSON.stringify(action.payload));
      })
      .addCase(getPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      }).addCase(getPaymentMethod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentMethod = action.payload;
      })
      .addCase(getPaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.paymentMethod = null
      })
      .addCase(deletePaymentMethod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePaymentMethod.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentMethod = null; // Clear payment method
      })
      .addCase(deletePaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addPaymentMethod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPaymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentMethod = action.payload; // Update payment method in the state
      })
      .addCase(addPaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(toggleAutoRenew.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(toggleAutoRenew.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload.subscription;
        localStorage.setItem('subscription', JSON.stringify(action.payload.subscription));
      })
      .addCase(toggleAutoRenew.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBillingDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBillingDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.billingDetails = action.payload;
      })
      .addCase(fetchBillingDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(reactivateSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reactivateSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload.subscription
        localStorage.setItem('subscription', JSON.stringify(action.payload.subscription));
      })
      .addCase(reactivateSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  resetSubscriptionState,
  setSelectedTier,
  setSelectedPlanFromStorage,
  clearError,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
