import React from 'react';

const milestones = [
  { date: 'November, 2023', title: 'ArbiEngine Formed', link: '#' },
  { date: 'March 15th, 2024', title: 'Generated Revenue', link: '' },
  { date: 'July, 2024', title: 'Began Fundraising', link: '' },
  { date: 'Q1, 2025', title: 'Onboard Power Sellers', link: '/powersellers' },
  { date: 'Q2, 2025', title: 'Full Suite Release', link: '' },

];

const Timeline = () => {
  return (
    <section className="w-full py-12 sm:py-16 text-gray-900 dark:text-white relative">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">

        <div className="relative">
          {/* Desktop Horizontal Line */}
          <div className="timeline-border hidden md:block absolute inset-0 m-auto w-full h-2 bg-gradient-to-r from-indigo-200 via-indigo-500 to-indigo-900 dark:from-indigo-700 dark:via-indigo-900 dark:to-indigo-500"></div>

          {/* Mobile Vertical Line */}
          <div className="block md:hidden absolute left-4 top-0 bottom-0 w-0.5 bg-gradient-to-b from-indigo-500 to-indigo-900 dark:from-indigo-300 dark:to-indigo-600"></div>

          <ul className="relative z-10 flex flex-col md:flex-row justify-between text-center md:text-left">
            {milestones.map((milestone, index) => (
              <li
                key={index}
                className="relative mb-10 md:mb-0 flex flex-col items-start md:items-start md:w-1/6 pl-10 md:pl-0"
              >
                <div className="text-indigo-500 dark:text-indigo-300 text-sm font-semibold mb-2">
                  {milestone.date}
                </div>
                {milestone.link ? (
                  <a
                    className="text-gray-900 dark:text-white transition-colors duration-200 hover:text-indigo-500 dark:hover:text-indigo-300 w-fit whitespace-nowrap md:whitespace-normal border-b border-gray-300 dark:border-gray-600 pb-px text-lg font-medium leading-tight tracking-tight hover:border-indigo-500 dark:hover:border-indigo-300"
                    href={milestone.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {milestone.title}
                  </a>
                ) : (
                  <span className="text-gray-900 dark:text-white w-fit whitespace-nowrap md:whitespace-normal border-b border-gray-300 dark:border-gray-600 pb-px text-lg font-medium leading-tight tracking-tight">
                    {milestone.title}
                  </span>
                )}

                {/* Mobile Dots and Horizontal Line */}
                <div className="md:hidden flex items-center justify-start mt-4 relative">
                  <div className="absolute left-4 top-2 w-10 border-t-2 border-indigo-500 dark:border-indigo-300"></div>
                  <div className="flex-shrink-0 h-4 w-4 bg-indigo-500 rounded-full relative">
                    {index === milestones.length - 2 && (
                      <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 bg-green-500 rounded-full opacity-50 animate-ping"></div>
                    )}
                    {index !== milestones.length - 1 && (
                      <div className="w-full h-full bg-indigo-500 rounded-full"></div>
                    )}
                  </div>
                </div>

                {/* Desktop Dots */}
                <div className="hidden md:flex items-center justify-center mt-4">
                  <div
                    className={`timeline-dot w-4 h-4 rounded-full relative ${index === milestones.length - 2 
                        ? 'bg-green-500 dark:bg-green-300 animate-pulse'
                        : 'bg-indigo-400 dark:bg-indigo-600'
                      }`}
                  >
                    {index === milestones.length - 1 && (
                      <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 bg-green-500 dark:bg-green-300 rounded-full opacity-50 animate-ping"></div>
                    )}
                  </div>
                </div>
                {index < milestones.length - 1 && (
                  <div className="hidden md:block timeline-line w-0.5 h-24 bg-gradient-to-b from-indigo-500 dark:from-indigo-600 to-transparent mt-4"></div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
