import React from 'react'
import {
  SearchIcon,
  ChartBarIcon,
  LightningBoltIcon,
  CogIcon,
  ShoppingCartIcon,
  ClipboardCheckIcon,
  SpeakerphoneIcon,
  UsersIcon,
} from '@heroicons/react/outline'

// Define your upcoming verticals (silos) here
const features = [
  {
    icon: <SearchIcon className="h-6 w-6" />,
    title: 'Product Sourcing',
    description:
      'Uncover profitable products effortlessly with advanced research tools, helping you stay ahead in crowded markets.',
  },
  {
    icon: <ChartBarIcon className="h-6 w-6" />,
    title: 'Data Analytics',
    description:
      'Turn raw data into actionable insights, empowering you to make confident, growth-focused decisions.',
  },
  {
    icon: <LightningBoltIcon className="h-6 w-6" />,
    title: 'Process Automation',
    description:
      'Automate repetitive tasks to save time and scale your operations with less effort.',
  },
  {
    icon: <CogIcon className="h-6 w-6" />,
    title: 'System Integration',
    description:
      'Build a unified workflow by connecting ArbiEngine with your existing tools and platforms.',
  },
  {
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    title: 'Multichannel Listing',
    description:
      'Manage product listings across multiple marketplaces, ensuring accuracy and consistency.',
  },
  {
    icon: <ClipboardCheckIcon className="h-6 w-6" />,
    title: 'Order Fulfillment',
    description:
      'Pick, pack, and ship from a single dashboard to boost efficiency and customer satisfaction.',
  },
  {
    icon: <SpeakerphoneIcon className="h-6 w-6" />,
    title: 'Brand Building',
    description:
      'Elevate your brand with cohesive messaging and targeted marketing strategies that drive engagement.',
  },
  {
    icon: <UsersIcon className="h-6 w-6" />,
    title: 'Customer Engagement',
    description:
      'Foster loyalty and personalization with CRM tools designed to strengthen post-sale relationships.',
  },
]

const SecondaryFeatures = () => {
  return (
    <section
      id="secondary-features"
      aria-label="ArbiEngine's upcoming verticals"
      className="relative z-20 py-16 sm:py-24 lg:py-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

        {/* Heading & Intro */}
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-4xl sm:text-5xl font-extrabold text-gray-900 dark:text-white">
            Coming Q2 2025: A Comprehensive Ecommerce Suite
          </h2>
          <p className="mt-5 text-lg sm:text-xl text-gray-600 dark:text-gray-300">
            As a <strong>startup</strong>, we’re building multiple
            <strong> verticals</strong>—from product sourcing and data analytics
            to CRM and brand building—to deliver a
            <strong> modern, scalable ERP alternative</strong>. Our mission is
            to streamline ecommerce for <strong>entrepreneurs</strong> and
            <strong> enterprises</strong> alike by unifying essential workflows
            under ArbiEngine.
          </p>
        </div>

        {/* Feature Grid */}
        <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-12">
          {features.map((feature, idx) => (
            <div
              key={idx}
              className="rounded-lg bg-white dark:bg-gray-800 shadow-lg p-6 transition-transform hover:scale-105"
            >
              <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600 text-white mb-4">
                {feature.icon}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {feature.title}
              </h3>
              <p className="mt-3 text-base text-gray-600 dark:text-gray-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SecondaryFeatures
