import React, { useState } from 'react';
import {
  PencilIcon,
  TrashIcon,
  CameraIcon,
  CubeIcon,
  ScaleIcon,
  CalendarIcon,
  ClockIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';

import { Tooltip, Typography } from "@material-tailwind/react";

import HelperModal from '../../HelperModal'; // Import the modal

const STATUS_CONFIG = {
  draft: { color: 'bg-yellow-500', label: 'Draft' },
  active: { color: 'bg-green-500', label: 'Active' },
  inactive: { color: 'bg-red-500', label: 'Inactive' },
  disabled: { color: 'bg-gray-500', label: 'Disabled' },
  relist: { color: 'bg-blue-500', label: 'Relist' },
  pending: { color: 'bg-yellow-500', label: 'Pending' },
  completed: { color: 'bg-green-500', label: 'Completed' },
  hold: { color: 'bg-orange-500', label: 'Hold' },
  sold: { color: 'bg-red-500', label: 'Sold' },
  deleted: { color: 'bg-gray-500', label: 'Deleted' },
};

const ProductsDataCard = ({ items, onDelete, onEditPhotos, onCardClick, unlockItem }) => {
  const defaultImageUrl = 'https://cdn-icons-png.flaticon.com/512/3342/3342243.png';
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleLockClick = (itemId) => {
    setSelectedItemId(itemId);
    setModalOpen(true);
  };

  const handleUnlockConfirm = () => {
    unlockItem(selectedItemId); // Call the unlock function (redux or prop-based)
    setModalOpen(false);
    setSelectedItemId(null);
  };


  const StatusIndicator = ({ status, label }) => {
    const config = STATUS_CONFIG[status] || STATUS_CONFIG['disabled'];
    return (
      <div className="flex items-center space-x-2">
        <div className={`h-3 w-3 rounded-full ${config.color}`} title={config.label} />
        <span className="text-xs text-gray-600 dark:text-gray-300">
          {label}: {config.label}
        </span>
      </div>
    );
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    const formatter = new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    });
    return formatter.format(new Date(date));
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items && items.length > 0 ? (
          items.map((item) => {
            const {
              _id,
              images,
              sku,
              brand,
              model,
              item_description,
              price,
              quantity,
              condition,
              photographyStatus,
              recordCompletionStatus,
              dimensions,
              weight,
              timestamp,
              updatedAt,
              locked, // <-- If true, show red lock + tooltip
            } = item;

            const lengthVal = dimensions?.length || 0;
            const widthVal = dimensions?.width || 0;
            const heightVal = dimensions?.height || 0;
            const unitVal = dimensions?.unit || 'INCH';
            const dimensionStr = dimensions
              ? `${lengthVal} x ${widthVal} x ${heightVal} ${unitVal}`
              : 'N/A';

            const weightStr = weight
              ? `${weight.value || 0} ${weight.unit || 'pounds'}`
              : 'N/A';

            const productImage = images && images.length > 0 ? images[0].awsUrl : defaultImageUrl;

            return (
              <div
                key={_id}
                className={`relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-sm rounded-lg p-4 flex flex-col space-y-3 cursor-pointer transition-transform duration-300
                  hover:bg-indigo-100 dark:hover:bg-indigo-900
                `}
                onClick={() => !locked && onCardClick(_id)}
              >
                {/* Top Row: Image & Basic Info */}
                <div className="flex items-start space-x-4">
                  <div className="relative group">
                    <img
                      src={productImage}
                      alt={sku}
                      className="w-20 h-20 rounded-lg object-cover"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!locked) onEditPhotos(item);
                      }}
                    />
                    {!locked && (
                      <div
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition rounded-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditPhotos(item);
                        }}
                      >
                        <CameraIcon className="h-6 w-6 text-white" />
                      </div>
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h2 className="text-base font-semibold text-gray-900 dark:text-white truncate">
                      {sku}
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400 truncate max-w-[12rem]">
                      {brand && <span className="font-medium">{brand} </span>}{model} {item_description}
                    </p>
                    <p className="text-base font-bold text-indigo-600 dark:text-indigo-400 mt-1">
                      ${price?.toFixed(2) || '0.00'}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-400 mt-1">
                      Quantity: {quantity || 0} | Condition: {condition || 'N/A'}
                    </p>
                  </div>
                  {/* Actions / Lock */}
                  <div
                    className="flex items-center space-x-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {locked ? (
                      <Tooltip
                        content={
                          <div className="w-60">
                            <Typography color="white" className="font-medium">
                              Product Locked
                            </Typography>
                            <Typography
                              variant="small"
                              color="white"
                              className="font-normal opacity-80"
                            >
                              You must upgrade your plan or reduce your number
                              of products to unlock this.
                            </Typography>
                          </div>
                        }
                        className="z-[9999]" // Add this class for maximum z-index
                      >
                        <LockClosedIcon
                          className="h-5 w-5 text-red-500 cursor-pointer hover:text-red-700 hover:scale-110 hover:translate-y-[-2px] transition-transform duration-200 ease-in-out"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleLockClick(_id);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        <button
                          onClick={() => onCardClick(_id)}
                          className="text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-300 p-1"
                          aria-label="View Product Details"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => onDelete(item)}
                          className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 p-1"
                          aria-label="Delete Product"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {/* Additional Info */}
                <div className="grid grid-cols-2 gap-2 text-xs text-gray-600 dark:text-gray-300">
                  <div className="flex items-center space-x-1">
                    <CubeIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" title="Dimensions" />
                    <span className="font-medium mr-1">Dimensions:</span>
                    <span>{dimensionStr}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <ScaleIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" title="Weight" />
                    <span className="font-medium mr-1">Weight:</span>
                    <span>{weightStr}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <CalendarIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" title="Created Date" />
                    <span className="font-medium mr-1">Created:</span>
                    <span>{formatDate(timestamp)}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <ClockIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" title="Last Updated" />
                    <span className="font-medium mr-1">Updated:</span>
                    <span>{formatDate(updatedAt)}</span>
                  </div>
                </div>

                {/* Status Indicators */}
                <div className="flex justify-start space-x-6 text-xs text-gray-600 dark:text-gray-300 border-t border-gray-200 dark:border-gray-700 pt-2">
                  <StatusIndicator
                    status={recordCompletionStatus}
                    label="Record Completion Status"
                  />
                  <StatusIndicator
                    status={photographyStatus}
                    label="Photography Status"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center col-span-full py-8">
            <p className="text-gray-500 dark:text-gray-400">No products available</p>
          </div>
        )}
      </div>
      <HelperModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Unlock Item"
        confirmText="Unlock"
        cancelText="Cancel"
        onConfirm={handleUnlockConfirm}
      >
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Are you sure you want to unlock this item? This action cannot be undone.
        </p>
      </HelperModal>
    </>
  );
};

export default ProductsDataCard;
