import React from 'react';

const CallToAction = () => {
  return (
    <section className="w-full lg:my-16 py-12 sm:py-16  text-gray-900 dark:text-white relative">
      
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="text-left w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight tracking-tight text-black dark:text-white mb-6">
              Scale Your <span className="text-indigo-600 dark:text-indigo-400">Business</span> with <span className="text-indigo-600 dark:text-indigo-400">ArbiEngine</span>
            </h1>
            <a
              className="inline-block font-bold text-center whitespace-nowrap rounded-md transition-colors duration-200 outline-none bg-indigo-600 dark:bg-indigo-500 text-white   hover:bg-indigo-500 dark:hover:bg-indigo-400 px-6 py-3 text-lg"
              href="/beta"
            >
              Join the Beta
            </a>
          </div>
          <div className="text-left w-full md:w-1/2 md:pl-8">
            <p className="text-base sm:text-lg leading-relaxed">
              Your <strong className="text-indigo-600 dark:text-indigo-400">all-in-one solution</strong> for scaling <strong className="text-indigo-600 dark:text-indigo-400">ecommerce businesses</strong>.<br></br> Join us to <strong className="text-indigo-600 dark:text-indigo-400">transform your business</strong> and <strong className="text-indigo-600 dark:text-indigo-400">unlock new opportunities</strong>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
