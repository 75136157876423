// src/components/PromptLink.jsx

import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * PromptLink Component
 *
 * A wrapper around the React Router `Link` component that intercepts navigation
 * attempts when there are unsaved changes (`isDirty` is true).
 * It delegates the navigation handling to the parent component via the `onClick` prop.
 *
 * Props:
 * - to: The target path to navigate to.
 * - children: The content inside the link.
 * - className: CSS classes for styling.
 * - onClick: Function to handle the navigation attempt.
 * - ...props: Any additional props.
 */

const PromptLink = forwardRef(({ to, children, className, onClick, ...props }, ref) => {
  const isDirty = useSelector((state) => state.unsavedChanges.isDirty);

  const handleClick = (e) => {
    if (isDirty && onClick) {
      // Prevent default navigation
      e.preventDefault();
      // Delegate the handling to the parent component via onClick
      onClick(to);
    }
    // If not dirty, proceed with navigation as usual
    // No need to handle else case as Link handles it
  };

  return (
    <Link to={to} onClick={handleClick} className={className} ref={ref} {...props}>
      {children}
    </Link>
  );
});

PromptLink.displayName = 'PromptLink';

PromptLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PromptLink.defaultProps = {
  className: '',
  onClick: null,
};

export default PromptLink;