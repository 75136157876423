import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  PlusIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import 'react-quill/dist/quill.snow.css';
import '../../../../components_css/custom-quill.css';
import { useFormikContext } from 'formik';

const ProductBasicDetails = ({ darkMode }) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext();

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [activeFieldInfo, setActiveFieldInfo] = useState(null);
  const [showAdditionalFieldsInfo, setShowAdditionalFieldsInfo] = useState(false);
  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [newCustomField, setNewCustomField] = useState({
    fieldName: '',
    value: '',
    isRequired: false,
    useInTemplates: false,
    platform: 'General',
  });
  const [errorsAddField, setErrorsAddField] = useState({});

  const recordCompletionStatus = values.recordCompletionStatus;

  const uspsShippingOptions = [
    { label: 'USPS First Class', value: 'USPSFirstClass' },
    { label: 'USPS Priority Padded Flat Rate Envelope', value: 'USPSPriorityMailPaddedFlatRateEnvelope' },
    { label: 'USPS Priority Small Flat Rate Box', value: 'USPSPriorityMailSmallFlatRateBox' },
    { label: 'USPS Priority Mail Medium Flat Rate Box', value: 'USPSPriorityFlatRateBox' },
    { label: 'USPS Priority Mail Large Flat Rate Box', value: 'USPSPriorityMailLargeFlatRateBox' },
  ];

  const shippingOptions = [
    { label: 'UPS Ground', value: 'UPSGround' },
    ...uspsShippingOptions,
  ];

  const platforms = ['General', 'eBay', 'Amazon', 'Shopify'];

  const platformFieldOptions = {
    eBay: ['Condition', 'UPC', 'MPN', 'Brand', 'Model', 'EAN', 'ISBN'],
    Amazon: ['ASIN', 'UPC', 'Brand', 'Model', 'Color', 'Size'],
    Shopify: ['Vendor', 'Type', 'Tags', 'Collection', 'Published', 'UPC'],
  };

  const calculateAutoAcceptPrice = (price) => Number((price * 0.95).toFixed(2));
  const calculateAutoDeclinePrice = (price) => Number((price * 0.9).toFixed(2));

  const [uspsSelected, setUspsSelected] = useState(false);

  useEffect(() => {
    setUspsSelected(
      values.basicDetails.shippingMethods?.some((method) =>
        uspsShippingOptions.some((option) => option.value === method)
      )
    );
  }, [values.basicDetails.shippingMethods, uspsShippingOptions]);

  const handleFieldInfoClick = (index) => {
    setActiveFieldInfo((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleShippingChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setFieldValue('basicDetails.shippingMethods', selectedOptions);
  };

  const handleToggleOverride = () => {
    const currentOverride = values.basicDetails.overrideBestOffer;
    const newOverride = !currentOverride;
    setFieldValue('basicDetails.overrideBestOffer', newOverride);

    if (!newOverride && values.basicDetails.price) {
      const price = values.basicDetails.price;
      setFieldValue('basicDetails.autoAcceptPrice', calculateAutoAcceptPrice(price), false);
      setFieldValue('basicDetails.autoDeclinePrice', calculateAutoDeclinePrice(price), false);
    }
  };

  const handleAdditionalInfoChange = (value) => {
    setFieldValue('basicDetails.additional_info', value);
  };

  const handleCustomFieldChange = (index, newValue) => {
    const updatedCustomFields = [...values.customFields];
    updatedCustomFields[index].value = newValue;
    setFieldValue('customFields', updatedCustomFields);
  };

  const handleAddCustomField = () => {
    if (newCustomField.fieldName.trim() === '') {
      setErrorsAddField({ addField: 'Field name is required' });
      return;
    }

    const duplicate = values.customFields.some((field) => {
      const fieldPlatforms = Array.isArray(field.platforms) ? field.platforms : [];
      return (
        field.fieldName.toLowerCase() === newCustomField.fieldName.toLowerCase() &&
        fieldPlatforms.includes(newCustomField.platform)
      );
    });

    if (duplicate) {
      setErrorsAddField({ addField: 'This field already exists for the selected platform.' });
      return;
    }

    const updatedCustomFields = [
      ...values.customFields,
      {
        fieldName: newCustomField.fieldName,
        value: newCustomField.value,
        isRequired: newCustomField.isRequired,
        useInTemplates: newCustomField.useInTemplates,
        platforms: [newCustomField.platform],
        sources: ['manual'],
      },
    ];
    setFieldValue('customFields', updatedCustomFields);

    setNewCustomField({
      fieldName: '',
      value: '',
      isRequired: false,
      useInTemplates: false,
      platform: 'General',
    });
    setShowAddCustomField(false);
    setErrorsAddField({});
  };

  const handleClearShipping = () => {
    setFieldValue('basicDetails.shippingMethods', ['UPSGround']);
  };

  useEffect(() => {
    const bestOfferEnabled = values.basicDetails.bestOfferEnabled;
    const overrideBestOffer = values.basicDetails.overrideBestOffer;
    const price = values.basicDetails.price;

    if (bestOfferEnabled && !overrideBestOffer && price) {
      const newAutoAccept = calculateAutoAcceptPrice(price);
      const newAutoDecline = calculateAutoDeclinePrice(price);

      if (values.basicDetails.autoAcceptPrice !== newAutoAccept) {
        setFieldValue('basicDetails.autoAcceptPrice', newAutoAccept, false);
      }
      if (values.basicDetails.autoDeclinePrice !== newAutoDecline) {
        setFieldValue('basicDetails.autoDeclinePrice', newAutoDecline, false);
      }
    }
  }, [
    values.basicDetails.price,
    values.basicDetails.bestOfferEnabled,
    values.basicDetails.overrideBestOffer,
    values.basicDetails.autoAcceptPrice,
    values.basicDetails.autoDeclinePrice,
    setFieldValue,
  ]);

  useEffect(() => {
    if (
      values.customFields &&
      values.customFields.some((field, index) => {
        const error = errors.customFields?.[index]?.value;
        const isTouched = touched.customFields?.[index]?.value;
        return error && isTouched;
      })
    ) {
      setIsAccordionOpen(true);
    }
  }, [errors.customFields, touched.customFields, values.customFields]);

  return (
    <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md`}>
      {/* Brand, Model, Item Description */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Brand */}
        <div>
          <label htmlFor="basicDetails.brand" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Brand</label>
          <input
            type="text"
            name="basicDetails.brand"
            id="basicDetails.brand"
            value={values.basicDetails.brand}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {errors.basicDetails?.brand && touched.basicDetails?.brand && (
            <p className="text-red-500 mt-1">{errors.basicDetails.brand}</p>
          )}
        </div>

        {/* Model */}
        <div>
          <label htmlFor="basicDetails.model" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Model</label>
          <input
            type="text"
            name="basicDetails.model"
            id="basicDetails.model"
            value={values.basicDetails.model}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {errors.basicDetails?.model && touched.basicDetails?.model && (
            <p className="text-red-500 mt-1">{errors.basicDetails.model}</p>
          )}
        </div>

        {/* Item Description */}
        <div>
          <label htmlFor="basicDetails.item_description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Item Description</label>
          <input
            type="text"
            name="basicDetails.item_description"
            id="basicDetails.item_description"
            value={values.basicDetails.item_description}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {errors.basicDetails?.item_description && touched.basicDetails?.item_description && (
            <p className="text-red-500 mt-1">{errors.basicDetails.item_description}</p>
          )}
        </div>
      </div>

      {/* Additional Fields Accordion */}
      {values.customFields && (
        <div className="mt-2">
          <div className="flex items-center">
            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Additional Fields
              </span>
              <InformationCircleIcon
                className="h-5 w-5 ml-1 text-gray-500 cursor-pointer"
                onClick={() => setShowAdditionalFieldsInfo(!showAdditionalFieldsInfo)}
              />
              <button
                type="button"
                onClick={() => setShowAddCustomField(true)}
                className="ml-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                title="Add Custom Field"
              >
                <PlusIcon className="h-5 w-5" />
              </button>
              <button
                type="button"
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                className="text-gray-700 dark:text-gray-300 focus:outline-none pl-4"
              >
                {isAccordionOpen ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="flex-grow mx-2 border-t border-gray-300 dark:border-gray-600"></div>
          </div>

          {showAdditionalFieldsInfo && (
            <div className="mt-1 p-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded">
              <p className="text-sm text-gray-700 dark:text-gray-300">
                The additional fields are platform-specific fields that may be required or optional, depending on the platform.
              </p>
            </div>
          )}

          {isAccordionOpen && (
            <>
              {values.customFields.length > 0 && (
                <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
                  {values.customFields.map((field, index) => {
                    const fieldPlatforms = Array.isArray(field.platforms) ? field.platforms : [];
                    const isMissingField = Array.isArray(field.sources) && field.sources.includes('missing');

                    return (
                      <div key={`${field.fieldName}-${fieldPlatforms.join('-')}-${index}`}>
                        <div className="flex items-center">
                          <label
                            className={`block text-sm font-medium ${isMissingField
                              ? 'text-yellow-600 dark:text-yellow-400'
                              : 'text-gray-700 dark:text-gray-300'
                              }`}
                          >
                            {field.fieldName}{' '}
                            {field.isRequired && <span className="text-red-500">*</span>}
                            {isMissingField && (
                              <ExclamationCircleIcon
                                className="h-5 w-5 ml-1 text-yellow-600 dark:text-yellow-400 inline"
                                title="Missing Required Field"
                              />
                            )}
                          </label>
                          <InformationCircleIcon
                            className="h-5 w-5 ml-1 text-gray-500 cursor-pointer"
                            onClick={() => handleFieldInfoClick(index)}
                          />
                        </div>
                        <input
                          type="text"
                          value={field.value || ''}
                          onChange={(e) => handleCustomFieldChange(index, e.target.value)}
                          className={`mt-1 block w-full border ${isMissingField
                            ? 'border-yellow-600 dark:border-yellow-400'
                            : 'border-gray-300 dark:border-gray-600'
                            } rounded-md shadow-sm py-1 px-2 ${isMissingField
                              ? 'bg-yellow-50 dark:bg-yellow-700'
                              : 'bg-gray-50 dark:bg-gray-700'
                            } text-gray-900 dark:text-gray-100`}
                          placeholder={`Enter ${field.fieldName}`}
                        />
                        {activeFieldInfo === index && (
                          <div className="mt-1 p-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded">
                            <p className="text-sm text-gray-700 dark:text-gray-300">
                              <strong>Required:</strong> {field.isRequired ? 'Yes' : 'No'}
                              <br />
                              <strong>Platform:</strong> {fieldPlatforms.join(', ')}
                              <br />
                              <strong>Use in Templates:</strong>{' '}
                              {field.useInTemplates ? 'Yes' : 'No'}
                            </p>
                          </div>
                        )}
                        {errors.customFields?.[index]?.value && touched.customFields?.[index]?.value && (
                          <p className="text-red-500 mt-1 text-sm">
                            {errors.customFields[index].value}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {showAddCustomField && (
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Add Custom Field</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Platform
                      </label>
                      <select
                        value={newCustomField.platform}
                        onChange={(e) => {
                          setNewCustomField({ ...newCustomField, platform: e.target.value, fieldName: '' });
                          setErrorsAddField({});
                        }}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                      >
                        {platforms.map((platform) => (
                          <option key={platform} value={platform}>
                            {platform}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Field Name
                      </label>
                      {newCustomField.platform === 'General' ? (
                        <input
                          type="text"
                          value={newCustomField.fieldName}
                          onChange={(e) => setNewCustomField({ ...newCustomField, fieldName: e.target.value })}
                          className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        />
                      ) : (
                        <select
                          value={newCustomField.fieldName}
                          onChange={(e) => setNewCustomField({ ...newCustomField, fieldName: e.target.value })}
                          className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        >
                          <option value="">Select a field</option>
                          {platformFieldOptions[newCustomField.platform]
                            .filter((fieldName) => {
                              return !values.customFields.some((field) => {
                                const fieldPlatforms = Array.isArray(field.platforms) ? field.platforms : [];
                                return field.fieldName === fieldName && fieldPlatforms.includes(newCustomField.platform);
                              });
                            })
                            .map((fieldName) => (
                              <option key={fieldName} value={fieldName}>
                                {fieldName}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Value
                      </label>
                      <input
                        type="text"
                        value={newCustomField.value}
                        onChange={(e) => setNewCustomField({ ...newCustomField, value: e.target.value })}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                      />
                    </div>

                    <div className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        checked={newCustomField.isRequired}
                        onChange={(e) => setNewCustomField({ ...newCustomField, isRequired: e.target.checked })}
                        className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Required
                      </label>
                    </div>

                    <div className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        checked={newCustomField.useInTemplates}
                        onChange={(e) =>
                          setNewCustomField({ ...newCustomField, useInTemplates: e.target.checked })
                        }
                        className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Use in Templates
                      </label>
                    </div>
                  </div>

                  {errorsAddField.addField && (
                    <p className="text-red-500 text-sm mt-2">{errorsAddField.addField}</p>
                  )}

                  <div className="mt-4 flex space-x-2">
                    <button
                      onClick={handleAddCustomField}
                      className="px-3 py-1.5 bg-green-500 text-white rounded-md shadow hover:bg-green-600 text-sm"
                    >
                      Add Field
                    </button>
                    <button
                      onClick={() => {
                        setShowAddCustomField(false);
                        setErrorsAddField({});
                      }}
                      className="px-3 py-1.5 bg-gray-500 text-white rounded-md shadow hover:bg-gray-600 text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* Price, Quantity, Condition */}
      <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Price */}
        <div>
          <label htmlFor="basicDetails.price" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Price</label>
          <input
            type="number"
            name="basicDetails.price"
            id="basicDetails.price"
            value={values.basicDetails.price}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {errors.basicDetails?.price && touched.basicDetails?.price && (
            <p className="text-red-500 mt-1">{errors.basicDetails.price}</p>
          )}
          <div className="mt-2 flex items-center space-x-6">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="basicDetails.bestOfferEnabled"
                id="basicDetails.bestOfferEnabled"
                checked={values.basicDetails.bestOfferEnabled}
                onChange={handleChange}
                onBlur={handleBlur}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="basicDetails.bestOfferEnabled" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">Enable Best Offer</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="basicDetails.acceptsReturns"
                id="basicDetails.acceptsReturns"
                checked={values.basicDetails.acceptsReturns}
                onChange={handleChange}
                onBlur={handleBlur}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="basicDetails.acceptsReturns" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">Returns Accepted</label>
            </div>
          </div>
        </div>

        {/* Quantity */}
        <div>
          <label htmlFor="basicDetails.quantity" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Quantity</label>
          <input
            type="number"
            name="basicDetails.quantity"
            id="basicDetails.quantity"
            value={values.basicDetails.quantity}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {errors.basicDetails?.quantity && touched.basicDetails?.quantity && (
            <p className="text-red-500 mt-1">{errors.basicDetails.quantity}</p>
          )}
        </div>

        {/* Condition (completed-only field) */}
        <div>
          <label htmlFor="basicDetails.condition" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Condition</label>
          <select
            name="basicDetails.condition"
            id="basicDetails.condition"
            value={values.basicDetails.condition}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          >
            <option value="">Select Condition</option>
            <option value="NEW">New</option>
            <option value="USED_EXCELLENT">Used - Excellent</option>
            <option value="NEW_OTHER">New Other</option>
            <option value="FOR_PARTS_OR_NOT_WORKING">For Parts or Not Working</option>
          </select>
          {/* Show condition error only if completed */}
          {recordCompletionStatus === 'completed' && errors.basicDetails?.condition && touched.basicDetails?.condition && (
            <p className="text-red-500 mt-1">{errors.basicDetails.condition}</p>
          )}
        </div>
      </div>

      {/* Best Offer, Additional Info, Shipping */}
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          {values.basicDetails.bestOfferEnabled && (
            <div className="mt-2">
              <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Override Auto Accept/Decline Price</label>
                <button
                  onClick={handleToggleOverride}
                  className={`ml-4 px-2 py-1 text-sm rounded ${values.basicDetails.overrideBestOffer ? 'bg-red-600 text-white' : 'bg-green-500 text-white'}`}
                >
                  {values.basicDetails.overrideBestOffer ? 'Disable Override' : 'Enable Override'}
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Auto Accept Price</label>
                  <input
                    type="number"
                    name="basicDetails.autoAcceptPrice"
                    value={values.basicDetails.autoAcceptPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.basicDetails.overrideBestOffer}
                    className={`mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 ${!values.basicDetails.overrideBestOffer && 'cursor-not-allowed opacity-50'}`}
                  />
                  {errors.basicDetails?.autoAcceptPrice && touched.basicDetails?.autoAcceptPrice && (
                    <p className="text-red-500 mt-1">{errors.basicDetails.autoAcceptPrice}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Auto Decline Price</label>
                  <input
                    type="number"
                    name="basicDetails.autoDeclinePrice"
                    value={values.basicDetails.autoDeclinePrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.basicDetails.overrideBestOffer}
                    className={`mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 ${!values.basicDetails.overrideBestOffer && 'cursor-not-allowed opacity-50'}`}
                  />
                  {errors.basicDetails?.autoDeclinePrice && touched.basicDetails?.autoDeclinePrice && (
                    <p className="text-red-500 mt-1">{errors.basicDetails.autoDeclinePrice}</p>
                  )}
                </div>
              </div>
            </div>
          )}

          <label htmlFor="basicDetails.additional_info" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-4">Additional Information</label>
          <ReactQuill
            key={darkMode}
            value={values.basicDetails.additional_info}
            onChange={handleAdditionalInfoChange}
            className={`${darkMode ? 'quill-dark' : 'quill-light'} rounded-md h-40 mt-1`}
            theme="snow"
            placeholder=""
          />
        </div>

        <div>
          <fieldset className="border p-4 rounded-md mt-14 md:mt-0">
            <legend className="text-lg font-medium text-gray-700 dark:text-gray-300">Shipping Information</legend>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1">
                <label htmlFor="basicDetails.dimensions.length" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Length (in)</label>
                <input
                  type="number"
                  name="basicDetails.dimensions.length"
                  value={values.basicDetails.dimensions.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {recordCompletionStatus === 'completed' && errors.basicDetails?.dimensions?.length && touched.basicDetails?.dimensions?.length && (
                  <p className="text-red-500 mt-1">{errors.basicDetails.dimensions.length}</p>
                )}
              </div>

              <div className="col-span-1">
                <label htmlFor="basicDetails.dimensions.width" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Width (in)</label>
                <input
                  type="number"
                  name="basicDetails.dimensions.width"
                  value={values.basicDetails.dimensions.width}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {recordCompletionStatus === 'completed' && errors.basicDetails?.dimensions?.width && touched.basicDetails?.dimensions?.width && (
                  <p className="text-red-500 mt-1">{errors.basicDetails.dimensions.width}</p>
                )}
              </div>

              <div className="col-span-1">
                <label htmlFor="basicDetails.dimensions.height" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Height (in)</label>
                <input
                  type="number"
                  name="basicDetails.dimensions.height"
                  value={values.basicDetails.dimensions.height}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {recordCompletionStatus === 'completed' && errors.basicDetails?.dimensions?.height && touched.basicDetails?.dimensions?.height && (
                  <p className="text-red-500 mt-1">{errors.basicDetails.dimensions.height}</p>
                )}
              </div>

              <div className="col-span-1">
                <label htmlFor="basicDetails.weight.value" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Weight</label>
                <input
                  type="number"
                  name="basicDetails.weight.value"
                  value={values.basicDetails.weight.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {recordCompletionStatus === 'completed' && errors.basicDetails?.weight?.value && touched.basicDetails?.weight?.value && (
                  <p className="text-red-500 mt-1">{errors.basicDetails.weight.value}</p>
                )}
              </div>

              <div className="col-span-1 md:col-span-2">
                <label htmlFor="basicDetails.weight.unit" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Unit</label>
                <select
                  name="basicDetails.weight.unit"
                  value={values.basicDetails.weight.unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                >
                  <option value="POUND">POUND</option>
                  <option value="OUNCE">OUNCE</option>
                </select>
              </div>
            </div>

            <div className="mt-4 flex justify-between items-center">
              <label htmlFor="basicDetails.shippingMethods" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Shipping Methods</label>
              {uspsSelected && (
                <button
                  type="button"
                  onClick={handleClearShipping}
                  className="p-1 text-sm rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                >
                  Reset
                </button>
              )}
            </div>
            <select
              name="basicDetails.shippingMethods"
              id="basicDetails.shippingMethods"
              multiple
              value={values.basicDetails.shippingMethods}
              onChange={handleShippingChange}
              onBlur={handleBlur}
              className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              {shippingOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {/* Show shipping methods error only if completed */}
            {recordCompletionStatus === 'completed' && errors.basicDetails?.shippingMethods && touched.basicDetails?.shippingMethods && (
              <p className="text-red-500 mt-1">{errors.basicDetails.shippingMethods}</p>
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ProductBasicDetails;
