import { Tooltip } from "@material-tailwind/react";
import React, { useState, useEffect, Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  SunIcon,
  MoonIcon,
  MenuIcon,
  XIcon,
  ChevronDownIcon,
  LockClosedIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { reset, logout } from "../features/auth/authSlice";
import arbiEngineLogoDark from "../images/arbienginelogo.png";
import arbiEngineLogoLight from "../images/arbieBlackNew.png";

/** Utility: join class names */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({
  hideGetStarted = false,
  hasBorder = true,
  isSticky = false,
}) {
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem("theme") === "dark"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  /** Logout Handler */
  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  /** Sticky Header Scroll Tracking */
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /** Dark Mode Sync with localStorage */
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }

    const themeEvent = new CustomEvent("themeChange", {
      detail: { theme: darkMode ? "dark" : "light" },
    });
    window.dispatchEvent(themeEvent);
  }, [darkMode]);

  /**
   * Navigation with submenus
   * "ArbiSearch" and "ArbiCortex" are marked with comingSoon = true
   */
  const navigation = [
    {
      name: "What We Offer",
      submenu: [
        {
          name: "Power Seller Solutions",
          href: "/powersellers",
          comingSoon: false,
        },
        { name: "ArbiSearch", href: "#", comingSoon: true },
        { name: "ArbiCortex", href: "#", comingSoon: true },
      ],
    },
    { name: "Blog", href: "/blog" },
    { name: "About Us", href: "/about-us" },
  ];

  return (
    <Disclosure
      as="header"
      className={classNames(
        "relative z-20",
        isSticky ? "sticky top-0" : "",
        isSticky && isScrolled ? "bg-white dark:bg-gray-900 shadow-lg" : "",
        hasBorder ? "border-b dark:border-white border-black" : ""
      )}
    >
      {({ open }) => (
        <>
          {/* Top Bar */}
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              {/* Logo Section */}
              <Link to="/" className="flex items-center">
                <img
                  src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
                  className="h-8 mr-3"
                  alt="ArbiEngine Logo"
                />
                <span className="hidden lg:inline self-center text-xs font-semibold sm:text-sm whitespace-nowrap dark:text-white">
                  <p className="text-gray-800 dark:text-white font-bold">
                    Where{" "}
                    <span className="text-indigo-600 dark:text-indigo-400">
                      data
                    </span>{" "}
                    drive{" "}
                    <span className="text-indigo-600 dark:text-indigo-400">
                      decisions
                    </span>
                    .
                  </p>
                </span>
              </Link>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-6">
                {navigation.map((item, idx) =>
                  item.submenu ? (
                    <Menu
                      as="div"
                      className="relative inline-block text-left"
                      key={idx}
                    >
                      <Menu.Button className="text-sm font-semibold text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center">
                        {item.name}
                        <ChevronDownIcon className="w-4 h-4 ml-1" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute mt-2 w-56 origin-top-left rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
                          {item.submenu.map((subItem) => (
                            <Menu.Item key={subItem.name}>
                              {({ active }) => {
                                // If subItem.comingSoon, do not navigate away
                                const linkClass = classNames(
                                  active
                                    ? "bg-gray-100 dark:bg-gray-600"
                                    : "",
                                  "flex items-center justify-between px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                                );
                                return (
                                  <div className={linkClass}>
                                    {/* Link text for subItem */}
                                    <Link
                                      to={
                                        subItem.comingSoon
                                          ? "#"
                                          : subItem.href
                                      }
                                      className="mr-2"
                                    >
                                      {subItem.name}
                                    </Link>
                                    {/* If comingSoon, show padlock icon in a Tooltip */}
                                    {subItem.comingSoon && (
                                      <Tooltip
                                        content="Coming Soon"
                                        className="bg-gray-800 text-white rounded p-2 text-xs z-[9999]"
                                      >
                                        <span className="inline-flex">
                                          <LockClosedIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                );
                              }}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="text-sm font-semibold text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400"
                    >
                      {item.name}
                    </Link>
                  )
                )}

                {/* Theme Toggle */}
                <button
                  id="theme-toggle"
                  type="button"
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-sm p-2.5"
                  onClick={() => setDarkMode(!darkMode)}
                  aria-label={
                    darkMode ? "Switch to light mode" : "Switch to dark mode"
                  }
                >
                  {darkMode ? (
                    <SunIcon className="w-5 h-5" />
                  ) : (
                    <MoonIcon className="w-5 h-5" />
                  )}
                </button>

                {/* User Actions */}
                {user ? (
                  <>
                    <button
                      type="button"
                      className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      aria-label="View notifications"
                    >
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <Link
                      to="/arbie"
                      className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                    >
                      Dashboard
                    </Link>
                    <Menu as="div" className="relative ml-3">
                      <Menu.Button className="flex text-sm rounded-full focus:outline-none">
                        <img
                          className="h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down"
                          src={
                            profileImageUrl ||
                            "https://cdn-icons-png.flaticon.com/512/9131/9131529.png"
                          }
                          alt="User profile"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white dark:bg-gray-800">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/arbie"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 dark:bg-gray-600"
                                    : "",
                                  "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                                )}
                              >
                                Dashboard
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={onLogout}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 dark:bg-gray-600"
                                    : "",
                                  "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left"
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  !hideGetStarted && (
                    <Link
                      to="/beta"
                      className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                    >
                      Join the Beta
                    </Link>
                  )
                )}
              </div>

              {/* Mobile Navigation Trigger */}
              <Disclosure.Button className="md:hidden flex items-center space-x-3">
                {/* Mobile Theme Toggle */}
                <button
                  id="theme-toggle-mobile"
                  className="text-gray-500 dark:text-gray-400"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDarkMode(!darkMode);
                  }}
                >
                  {darkMode ? (
                    <SunIcon className="w-5 h-5" />
                  ) : (
                    <MoonIcon className="w-5 h-5" />
                  )}
                </button>
                {user && (
                  <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center focus:outline-none">
                      <img
                        className="h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down"
                        src={
                          profileImageUrl ||
                          "https://cdn-icons-png.flaticon.com/512/9131/9131529.png"
                        }
                        alt="User profile"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white dark:bg-gray-800">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/arbie"
                              className={classNames(
                                active
                                  ? "bg-gray-100 dark:bg-gray-600"
                                  : "",
                                "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                              )}
                            >
                              Dashboard
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={onLogout}
                              className={classNames(
                                active
                                  ? "bg-gray-100 dark:bg-gray-600"
                                  : "",
                                "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
                <MenuIcon
                  className="block h-6 w-6 text-gray-700 dark:text-gray-300"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((navItem) =>
                navItem.submenu ? (
                  <Disclosure as="div" key={navItem.name}>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md text-sm font-semibold">
                      {navItem.name}
                      <ChevronDownIcon className="w-5 h-5" />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-5 space-y-1">
                      {navItem.submenu.map((subItem) => (
                        <div
                          key={subItem.name}
                          className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md px-4 py-3"
                        >
                          {/* Non-comingSoon Link or dummy link if locked */}
                          <Link
                            to={subItem.comingSoon ? "#" : subItem.href}
                            className="text-gray-700 dark:text-gray-300 text-sm font-semibold"
                          >
                            {subItem.name}
                          </Link>
                          {subItem.comingSoon && (
                            <Tooltip
                              content="Coming Soon"
                              className="bg-gray-800 text-white rounded p-2 text-xs z-[9999]"
                            >
                              <span className="inline-flex">
                                <LockClosedIcon className="w-5 h-5 text-gray-500 dark:text-gray-400 ml-2" />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </Disclosure>
                ) : (
                  <Link
                    key={navItem.name}
                    to={navItem.href}
                    className="block px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md text-sm font-semibold"
                  >
                    {navItem.name}
                  </Link>
                )
              )}
              {/* If user not logged in & !hideGetStarted => Beta button */}
              {!user && !hideGetStarted && (
                <Link
                  to="/beta"
                  className="block w-full text-center px-4 py-3 rounded-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Join the Beta
                </Link>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
