import React, { useState, useEffect } from "react";
import logo from "../../images/arbiengine_data.png"; // Your logo
import inventoryComputerLight from "../../images/inventory-computer-light.png"; // Light mode image
import inventoryComputerDark from "../../images/inventory-computer-dark.png";   // Dark mode image

const PowerSellersHeroDesktop = () => {
  // Listen for theme changes, defaulting to localStorage's value or "light"
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  );

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme);
    };

    // Event listener that receives { detail: { theme: "light" | "dark" } }
    window.addEventListener("themeChange", handleThemeChange);
    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  // Determine which image to use based on current theme
  const heroImage =
    theme === "dark" ? inventoryComputerDark : inventoryComputerLight;

  // Smooth scroll to Early Adopter section
  const handleScrollToEarlyAdopter = () => {
    const element = document.getElementById("early-adopter-initiative");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="relative w-full min-h-[95vh] bg-gradient-to-br from-gray-100 via-white to-indigo-100 dark:from-gray-900 dark:via-gray-800 dark:to-indigo-900 flex items-center"
      aria-label="Power Sellers Hero Section"
    >
      {/* Dynamically chosen image */}
      <div className="absolute inset-0">
        <img
          src={heroImage}
          alt="Monitor showcasing ArbiEngine"
          className="absolute inset-0 object-cover w-full h-full"
        />
      </div>

      {/* Hero Content */}
      <div className="relative z-10 container mx-auto flex justify-end px-6 lg:px-16">
        {/* Content Block */}
        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 w-full flex flex-col items-start text-left">
          {/* Small Title with Logo */}
          <div className="flex items-center gap-3 mb-4">
            <img src={logo} alt="ArbiEngine Logo" className="h-8 w-8" />
            <div className="flex items-center gap-2">
              <span className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900 dark:text-white">
                ArbiEngine
              </span>
              <span className="text-sm sm:text-base font-semibold text-indigo-700 dark:text-indigo-500 whitespace-nowrap">
                for Power Sellers
              </span>
            </div>
          </div>

          {/* Large Slogan */}
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white leading-tight">
            Rethink your <br />
            product listing flow.
          </h1>

          {/* Supporting Text */}
          <p className="mt-4 text-base sm:text-lg lg:text-xl text-gray-700 dark:text-gray-300 max-w-md">
            The ultimate platform for streamlined listings, centralized
            fulfillment, and seamless management of your eBay stores.
          </p>

          {/* Modern Button */}
          <button
            onClick={handleScrollToEarlyAdopter}
            className="mt-6 rounded-full bg-indigo-600 px-8 py-4 text-lg font-semibold text-white shadow-lg hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            Become an Early Adopter
          </button>
        </div>
      </div>
    </section>
  );
};

export default PowerSellersHeroDesktop;
