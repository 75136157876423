import React from 'react';
import PaymentLeftSection from './PaymentLeftSection';
import PaymentRightSection from './PaymentRightSection';

const PaymentPageV2 = () => {
  return (
    <div className="min-h-screen flex">
    {/* Left half with gray background */}
        <div className="w-1/2 bg-gray-50 p-8">
            <PaymentLeftSection />
        </div>

        {/* Right half */}
        <div className="w-1/2 p-8">
            <PaymentRightSection />
        </div>
    </div>
  );
};

export default PaymentPageV2;
