import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../images/arbiengine_data.png"
// Light/Dark images for the mobile hero
import inventoryMobileLight from "../../images/inventory-mobile-light.png"
import inventoryMobileDark from "../../images/inventory-mobile-dark.png"

const PowerSellersHeroMobile = () => {
  // Smooth-scroll to EarlyAdopterInitiative
  const handleScrollToEarlyAdopter = () => {
    const element = document.getElementById("early-adopter-initiative")
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  const navigate = useNavigate()

  // Listen for theme changes, defaulting to localStorage's value or "light"
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  )

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme)
    }

    window.addEventListener("themeChange", handleThemeChange)
    return () => {
      window.removeEventListener("themeChange", handleThemeChange)
    }
  }, [])

  // Pick the correct hero image based on current theme
  const heroImage =
    theme === "dark" ? inventoryMobileDark : inventoryMobileLight

  return (
    <section
      className="relative w-full min-h-[95vh] bg-gradient-to-br from-gray-100 via-white to-indigo-100 dark:from-gray-900 dark:via-gray-800 dark:to-indigo-900 text-white flex flex-col justify-between"
      aria-label="Power Sellers Hero Section"
    >
      {/* Hero Content */}
      <div className="container mx-auto flex flex-col items-center justify-center px-6 sm:px-8 md:px-12 text-center pt-10">
        {/* Small Title with Logo */}
        <div className="flex items-center gap-3 mb-5">
          <img src={logo} alt="ArbiEngine Logo" className="h-8 w-8" />
          <div className="flex items-center">
            <span className="text-2xl font-bold text-gray-900 dark:text-white">
              ArbiEngine
            </span>
            <span className="text-sm font-semibold text-indigo-600 dark:text-indigo-400 ml-2 whitespace-nowrap">
              for Power Sellers
            </span>
          </div>
        </div>

        {/* Large Slogan */}
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight text-gray-900 dark:text-white max-w-3xl">
          Rethink your product listing flow.
        </h1>

        {/* Supporting Text */}
        <p className="mt-3 text-lg md:text-xl text-gray-700 dark:text-gray-300 max-w-2xl">
          The ultimate platform for streamlined listings, centralized
          fulfillment, and seamless management of your eBay stores.
        </p>

        {/* Modern Button */}
        <button
          onClick={handleScrollToEarlyAdopter}
          className="mt-5 rounded-full bg-indigo-600 px-10 py-4 text-lg font-semibold text-white shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          Become an Early Adopter
        </button>
      </div>

      {/* Monitor Image */}
      <div className="w-full mt-4">
        <img
          src={heroImage}
          alt="Monitor showcasing ArbiEngine"
          className="w-full h-auto object-cover"
        />
      </div>
    </section>
  )
}

export default PowerSellersHeroMobile
