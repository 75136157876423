import React from 'react';
import {
  PencilIcon,
  TrashIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  PlusIcon,
  LockClosedIcon,
} from '@heroicons/react/solid';

import { Tooltip, Typography } from "@material-tailwind/react";

const ConnectionCard = ({
  platform,
  logoUrl,
  plans,
  connections,
  isLoading,
  onConnect,
  onEdit,
  onDelete,
  onReauthenticate,
  subscription,
  onUnlock
}) => {
  const handleRefreshToken = (connection) => {
    // Only reauthenticate if not locked
    if (!connection.locked) {
      onReauthenticate(connection);
    }
  };

  const handleDelete = (connection) => {
    // Allow deleting even if locked, or comment out if you want to prevent deletes:
    // if (connection.locked) return;
    onDelete(connection);
  };

  const plan = subscription?.plan;
  const accountLimit =
    plans.find((plan) => plan.name === plan)?.accountLimit || 0;
  const connectionCount = connections.length;
  const canAddAccount = connectionCount < accountLimit;

  return (
    <div className="relative bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-4 shadow-lg rounded-lg border">
      <div className="flex justify-between items-center mb-4">
        <img
          src={logoUrl}
          alt={`${platform} Logo`}
          className="w-16 h-12 object-contain"
        />
        <div className="text-right">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
            {platform} Connections
          </h2>
        </div>
      </div>

      {connections.length === 0 ? (
        <div className="flex justify-center">
          <button
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-full flex items-center"
            onClick={() => onConnect('selling')}
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            Add Account
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-2 overflow-x-auto pb-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900">
          {connections.map((connection) => {
            const isConnected = connection.refreshTokenStatus;
            const daysUntilRefresh = connection.daysUntilRefreshTokenExpires;
            const isLocked = connection.locked;

            return (
              <div
                key={connection.id}
                className={`relative p-4 rounded-lg min-w-max border mr-2 ${
                  isLocked
                    ? 'bg-gray-200 dark:bg-gray-700'
                    : 'bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900'
                }`}
              >
                {/* 
                  Instead of an overlay, we simply show a red lock if isLocked is true.
                  (Removed the locked overlay code) 
                */}

                <div className="flex justify-between items-center mb-2">
                  <p className="text-gray-800 dark:text-gray-200 font-medium">
                    {connection.accountName}
                  </p>
                  <div className="flex items-center space-x-1">
                    {isLocked ? (
                      <Tooltip
                        content={
                          <div className="w-60">
                            <Typography color="white" className="font-medium">
                              Account Locked
                            </Typography>
                            <Typography
                              variant="small"
                              color="white"
                              className="font-normal opacity-80"
                            >
                              You must upgrade your plan or reduce your number
                              of accounts to unlock this.
                            </Typography>
                          </div>
                        }
                      >
                        <LockClosedIcon
                          className="w-5 h-5 text-red-500 cursor-pointer hover:text-red-700 hover:scale-110 hover:translate-y-[-2px] transition-transform duration-200 ease-in-out"
                          onClick={() => onUnlock(connection)} // Add a new `onUnlock` prop
                        />
                      </Tooltip>
                    ) : isConnected ? (
                      <CheckCircleIcon className="w-5 h-5 text-green-500" />
                    ) : (
                      <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                    )}
                  </div>
                </div>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  Username:{' '}
                  {connection.usernameConnectedAccount || 'N/A'}
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  Days until refresh: {daysUntilRefresh}
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  Date Added:{' '}
                  {connection.dateAdded
                    ? new Date(connection.dateAdded).toLocaleDateString()
                    : 'Not available'}
                </p>

                <div className="flex justify-between items-center space-x-2 mt-4">
                  <div className="relative group">
                    <button
                      className={`${
                        isLocked ? 'bg-gray-500' : isConnected ? 'bg-green-500' : 'bg-red-500'
                      } text-white font-semibold py-2 px-3 rounded-full`}
                      onClick={() => handleRefreshToken(connection)}
                      disabled={isLoading || isLocked}
                    >
                      {isLocked ? 'Locked' : isConnected ? 'Connected' : 'Reconnect'}
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      {isLocked ? 'Locked' : isConnected ? 'Connected' : 'Reconnect'}
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>

                  <div className="relative group">
                    <button
                      className="bg-indigo-500 text-white font-semibold py-2 px-3 rounded-full"
                      onClick={() => !isLocked && onEdit(connection)}
                      disabled={isLocked}
                    >
                      <PencilIcon className="w-5 h-5" />
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      Edit Connection
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>

                  <div className="relative group">
                    <button
                      className="bg-red-500 text-white font-semibold py-2 px-3 rounded-full"
                      onClick={() => handleDelete(connection)}
                      disabled={isLocked}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      Delete Connection
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="flex items-center">
            <button
              className={`${
                canAddAccount ? 'bg-indigo-500' : 'bg-gray-500'
              } text-white font-semibold py-2 px-3 rounded-full flex items-center space-x-2 text-sm`}
              onClick={() => onConnect('selling')}
              disabled={!canAddAccount}
            >
              <PlusIcon className="w-6 h-6" />
              <span>
                {canAddAccount ? 'Add Account' : 'Upgrade to Add Accounts'}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionCard;
