import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationSettings, updateNotificationSettings } from '../../features/userSettings/settingsSlice';

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const { notificationSettings, isLoading } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth); // Get user information

  const [settings, setSettings] = useState(notificationSettings);

  useEffect(() => {
    dispatch(fetchNotificationSettings());
  }, [dispatch]);

  useEffect(() => {
    setSettings(notificationSettings);
  }, [notificationSettings]);

  const handleToggle = (setting) => {
    const updatedSettings = {
      ...settings,
      [setting]: !settings[setting],
    };
    setSettings(updatedSettings);
    dispatch(updateNotificationSettings(updatedSettings));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-8'>
        Notification Settings
      </h1>
      <div className='bg-gray-50 dark:bg-gray-700 p-6 mb-8 rounded-lg shadow-md'>
        <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4'>
          Manage your notifications
        </h2>
        <p className='text-gray-700 dark:text-gray-300 mb-6'>
          Customize your notification preferences for arbitrage identification alerts.
        </p>
        <div className='space-y-4'>
          <div className='flex items-center justify-between'>
            <div className='text-gray-800 dark:text-gray-200'>
              Email Notifications
            </div>
            <button
              className={`${
                settings.emailNotifications ? 'bg-indigo-500' : 'bg-gray-300 dark:bg-gray-600'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
              onClick={() => handleToggle('emailNotifications')}
            >
              <span className='sr-only'>Toggle email notifications</span>
              <span
                className={`${
                  settings.emailNotifications ? 'translate-x-6' : 'translate-x-1'
                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
              />
            </button>
          </div>
          {/* Render Push Notifications only if user is an admin */}
          {user?.admin && (
            <div className='flex items-center justify-between'>
              <div className='text-gray-800 dark:text-gray-200'>
                Push Notifications
              </div>
              <button
                className={`${
                  settings.pushNotifications ? 'bg-indigo-500' : 'bg-gray-300 dark:bg-gray-600'
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
                onClick={() => handleToggle('pushNotifications')}
              >
                <span className='sr-only'>Toggle push notifications</span>
                <span
                  className={`${
                    settings.pushNotifications ? 'translate-x-6' : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
