// src/components/Dashboard/Ecommerce/ProductDetailsPage/UnsavedChanges/UnsavedChangesModal.jsx

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

const UnsavedChangesModal = ({ isOpen, onSave, onDiscard, onCancel }) => {
  const modalRef = useRef(null);

  // Focus management: Shift focus to the modal when it opens
  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  // Handle Escape key to cancel navigation
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        onKeyDown={handleKeyDown}
        aria-labelledby="unsaved-changes-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 max-w-md p-6 focus:outline-none"
          tabIndex="-1"
          ref={modalRef}
        >
          <div className="flex items-center mb-4">
            {/* Alert Icon */}
            <svg
              className="w-6 h-6 text-red-500 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M12 20h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h2 id="unsaved-changes-title" className="text-lg font-semibold text-gray-800 dark:text-gray-100">
              Unsaved Changes
            </h2>
          </div>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            You have unsaved changes. What would you like to do?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onCancel}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={onDiscard}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
            >
              Discard Changes
            </button>
            <button
              onClick={onSave}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

UnsavedChangesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UnsavedChangesModal;
