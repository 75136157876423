import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

const SalesMetricsChart = () => {
  const [timeframe, setTimeframe] = useState('week'); // Default to week
  const salesMetrics = useSelector((state) => state.metrics.salesMetrics || {});

  const chartData = salesMetrics[timeframe] || []; // Select data based on timeframe

  // Process chart data
  const categories = chartData.map((data) => data._id); // Dates
  const series = [
    {
      name: 'Revenue',
      data: chartData.map((data) => data.revenue), // Revenues
    },
  ];

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Revenue',
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`, // Format as currency
      },
    },
    colors: ['#1E90FF'], // Bar color
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6 xl:p-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-900 dark:text-white">Sales Metrics</h2>
        <div className="space-x-2">
          <button
            onClick={() => setTimeframe('week')}
            className={`px-4 py-2 text-sm font-medium rounded ${
              timeframe === 'week'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white'
            }`}
          >
            Week
          </button>
          <button
            onClick={() => setTimeframe('month')}
            className={`px-4 py-2 text-sm font-medium rounded ${
              timeframe === 'month'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white'
            }`}
          >
            Month
          </button>
          <button
            onClick={() => setTimeframe('year')}
            className={`px-4 py-2 text-sm font-medium rounded ${
              timeframe === 'year'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white'
            }`}
          >
            Year
          </button>
        </div>
      </div>

      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default SalesMetricsChart;
