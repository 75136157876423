import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import {
  getInventoryItem,
  updateItem,
  addInventoryItem,
  clearItemDetails,
} from '../../features/inventory/inventorySlice';
import { fetchConnections } from '../../features/connections/connectionSlice';
import Spinner from '../../components/Spinner';
import ProductBasicDetails from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductBasicDetails';
import ProductOrganization from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductOrganization';
import ProductConnections from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductConnections';
import ProductMediaSection from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductMediaSection';
import ManageConnectionsModal from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ManageConnectionsModal';
import EditPhotographyModal from '../../components/Dashboard/Photography/EditPhotographyModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  selectMergedCustomFields,
  selectMissingCustomFields,
} from '../../features/inventory/inventorySelectors';

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { setDirty, resetDirty } from '../../features/unsavedChanges/unsavedChangesSlice';
import PromptLink from '../../components/Dashboard/Ecommerce/ProductDetailsPage/UnsavedChanges/PromptLink';

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((value, key) => (value ? value[key] : undefined), obj);
};

const determineRecordCompletionStatus = (values) => {
  const completedRequiredFields = [
    'basicDetails.brand',
    'basicDetails.model',
    'basicDetails.item_description',
    'basicDetails.price',
    'basicDetails.quantity',
    'basicDetails.condition',
    'basicDetails.dimensions.length',
    'basicDetails.dimensions.width',
    'basicDetails.dimensions.height',
    'basicDetails.weight.value',
    'basicDetails.shippingMethods',
    'category',
  ];

  const allFieldsFilled = completedRequiredFields.every((field) => {
    const fieldValue = getNestedValue(values, field);
    if (Array.isArray(fieldValue)) {
      return fieldValue.length > 0;
    }
    return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
  });

  return allFieldsFilled ? 'completed' : 'draft';
};

const calculateAutoAcceptPrice = (price) => Number((price * 0.95).toFixed(2));
const calculateAutoDeclinePrice = (price) => Number((price * 0.9).toFixed(2));

const validationSchema = Yup.object().shape({
  basicDetails: Yup.object().shape({
    brand: Yup.string().required('Brand is required'),
    model: Yup.string().required('Model is required'),
    item_description: Yup.string().required('Item description is required'),
    price: Yup.number()
      .transform((value, originalValue) =>
        String(originalValue).trim() === '' ? undefined : value
      )
      .typeError('Price must be a number')
      .required('Price is required')
      .test(
        'price-vs-auto-prices',
        'Price must be greater than or equal to Auto Accept and Auto Decline Prices',
        function (value) {
          const { autoAcceptPrice, autoDeclinePrice, overrideBestOffer, bestOfferEnabled } = this.parent;
          if (bestOfferEnabled && overrideBestOffer) {
            if ((autoAcceptPrice != null && value < autoAcceptPrice) ||
              (autoDeclinePrice != null && value < autoDeclinePrice)) {
              return false;
            }
          }
          return true;
        }
      ),
    quantity: Yup.number()
      .transform((value, originalValue) => String(originalValue).trim() === '' ? undefined : value)
      .typeError('Quantity must be a number')
      .required('Quantity is required'),
    condition: Yup.string().when('$recordCompletionStatus', {
      is: 'completed',
      then: () => Yup.string().required('Condition is required'),
    }),
    dimensions: Yup.object().shape({
      length: Yup.number()
        .nullable() // Allow null in draft mode
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
        .when('$recordCompletionStatus', {
          is: 'completed',
          then: (schema) => schema.typeError('Length must be a number').required('Length is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      width: Yup.number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
        .when('$recordCompletionStatus', {
          is: 'completed',
          then: (schema) => schema.typeError('Width must be a number').required('Width is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      height: Yup.number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
        .when('$recordCompletionStatus', {
          is: 'completed',
          then: (schema) => schema.typeError('Height must be a number').required('Height is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      unit: Yup.string().default('INCH'),
    }),
    weight: Yup.object().shape({
      value: Yup.number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
        .when('$recordCompletionStatus', {
          is: 'completed',
          then: (schema) => schema.typeError('Weight must be a number').required('Weight is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      unit: Yup.string().default('POUND'),
    }),
    shippingMethods: Yup.array()
      .of(Yup.string())
      .when('$recordCompletionStatus', {
        is: 'completed',
        then: () => Yup.array().min(1, 'At least one shipping method is required').required('At least one shipping method is required'),
      }),

    bestOfferEnabled: Yup.boolean(),
    overrideBestOffer: Yup.boolean(),
    autoAcceptPrice: Yup.number()
      .nullable()
      .when(['bestOfferEnabled', 'overrideBestOffer'], {
        is: (bestOfferEnabled, overrideBestOffer) => bestOfferEnabled && overrideBestOffer,
        then: () =>
          Yup.number()
            .typeError('Auto Accept Price must be a number')
            .required('Auto Accept Price is required')
            .test('auto-accept>auto-decline', 'Auto Accept Price must be greater than Auto Decline Price', function (value) {
              const { autoDeclinePrice } = this.parent;
              if (autoDeclinePrice != null && value != null && value <= autoDeclinePrice) return false;
              return true;
            }),
      }),
    autoDeclinePrice: Yup.number()
      .nullable()
      .when(['bestOfferEnabled', 'overrideBestOffer'], {
        is: (bestOfferEnabled, overrideBestOffer) => bestOfferEnabled && overrideBestOffer,
        then: () =>
          Yup.number()
            .typeError('Auto Decline Price must be a number')
            .required('Auto Decline Price is required')
            .test('auto-decline<auto-accept', 'Auto Decline Price must be less than Auto Accept Price', function (value) {
              const { autoAcceptPrice } = this.parent;
              if (autoAcceptPrice != null && value != null && value >= autoAcceptPrice) return false;
              return true;
            }),
      }),
  }),
  category: Yup.string().when('$recordCompletionStatus', {
    is: 'completed',
    then: () => Yup.string().required('Category is required'),
  }),
  customFields: Yup.array().of(
    Yup.object().shape({
      fieldName: Yup.string().required('Field name is required'),
      value: Yup.string().when('isRequired', {
        is: true,
        then: () => Yup.string().required('This field is required'),
      }),
      isRequired: Yup.boolean(),
    })
  ),
});

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onNavigateAttempt, onShowUnsavedChangesModal, setSubmitFormCallback } = useOutletContext();

  const { itemDetails } = useSelector((state) => state.inventory);
  const { connections } = useSelector((state) => state.connection);
  const isDirty = useSelector((state) => state.unsavedChanges.isDirty);

  const mergedCustomFields = useSelector(selectMergedCustomFields);
  const missingCustomFields = useSelector(selectMissingCustomFields);

  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');

  const isEditMode = Boolean(productId);
  const pageTitle = isEditMode ? 'Edit Product' : 'Create New Product';

  const previouslyCompleted = isEditMode && itemDetails && itemDetails.recordCompletionStatus === 'completed';

  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'dark');
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  useEffect(() => {
    if (!isEditMode) {
      dispatch(clearItemDetails());
    }
  }, [dispatch, isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      dispatch(getInventoryItem(productId));
    }
    dispatch(fetchConnections());
  }, [dispatch, productId, isEditMode]);

  const safeMergedCustomFields = useMemo(() => {
    return (mergedCustomFields || []).map(cf => ({
      ...cf,
      platforms: Array.isArray(cf.platforms) ? cf.platforms : [],
      sources: Array.isArray(cf.sources) ? cf.sources : []
    }));
  }, [mergedCustomFields]);

  const [isManageConnectionsModalOpen, setIsManageConnectionsModalOpen] = useState(false);
  const [isEditPhotographyModalOpen, setIsEditPhotographyModalOpen] = useState(false);
  const photographyPendingAction = useRef(false);

  const openEditPhotographyModal = () => setIsEditPhotographyModalOpen(true);
  const closeEditPhotographyModal = () => setIsEditPhotographyModalOpen(false);

  useEffect(() => {
    if (missingCustomFields.length > 0) {
      toast.error('Please fill in the missing required custom fields.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  }, [missingCustomFields]);

  const initialValues = useMemo(() => {
    let initialValues = {
      basicDetails: {
        brand: '',
        model: '',
        condition: '',
        quantity: undefined,
        item_description: '',
        dimensions: { length: undefined, width: undefined, height: undefined, unit: 'INCH' },
        weight: { value: undefined, unit: 'POUND' },
        shippingMethods: [],
        price: undefined,
        additional_info: '',
        bestOfferEnabled: false,
        acceptsReturns: true,
        autoAcceptPrice: undefined,
        autoDeclinePrice: undefined,
        overrideBestOffer: false,
      },
      category: '',
      listToChannels: [],
      photographyStatus: 'pending',
      recordCompletionStatus: 'draft',
      customFields: [],
    };

    if (itemDetails && isEditMode) {
      const price = itemDetails.price ?? undefined;
      const storedBestOfferEnabled = itemDetails?.bestOfferTerms?.bestOfferEnabled ?? false;
      const storedOverrideBestOffer = itemDetails?.bestOfferTerms?.overrideBestOffer ?? false;
      const storedAutoAcceptPrice = itemDetails?.bestOfferTerms?.autoAcceptPrice?.value;
      const storedAutoDeclinePrice = itemDetails?.bestOfferTerms?.autoDeclinePrice?.value;

      let autoAcceptPrice = storedAutoAcceptPrice !== undefined
        ? Number(storedAutoAcceptPrice)
        : (storedBestOfferEnabled && price !== undefined ? calculateAutoAcceptPrice(price) : undefined);

      let autoDeclinePrice = storedAutoDeclinePrice !== undefined
        ? Number(storedAutoDeclinePrice)
        : (storedBestOfferEnabled && price !== undefined ? calculateAutoDeclinePrice(price) : undefined);

      initialValues = {
        basicDetails: {
          brand: itemDetails.brand || '',
          model: itemDetails.model || '',
          item_description: itemDetails.item_description || '',
          condition: itemDetails.condition || '',
          quantity: itemDetails.quantity ?? undefined,
          dimensions: itemDetails.dimensions || { length: undefined, width: undefined, height: undefined, unit: 'INCH' },
          weight: itemDetails.weight || { value: undefined, unit: 'POUND' },
          shippingMethods: itemDetails.shippingMethods || [],
          additional_info: itemDetails.additional_info || '',
          price: price,
          bestOfferEnabled: storedBestOfferEnabled,
          autoAcceptPrice: autoAcceptPrice,
          autoDeclinePrice: autoDeclinePrice,
          overrideBestOffer: storedOverrideBestOffer,
          acceptsReturns: itemDetails?.acceptsReturns || true,
        },
        category: itemDetails.category || '',
        listToChannels: itemDetails.listToChannels || [],
        photographyStatus: itemDetails.photographyStatus || 'pending',
        recordCompletionStatus: itemDetails.recordCompletionStatus || 'draft',
        customFields: safeMergedCustomFields,
      };
    } else if (!isEditMode && connections.length > 0) {
      const defaultConnections = connections
        .filter((conn) => conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'draft',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      const nonDefaultConnections = connections
        .filter((conn) => !conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'disabled',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      initialValues.listToChannels = [...defaultConnections, ...nonDefaultConnections];
    }

    return initialValues;
  }, [itemDetails, isEditMode, safeMergedCustomFields, connections]);

  const FormikObserver = () => {
    const { dirty, submitForm } = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
      if (dirty) {
        dispatch(setDirty());
      } else {
        dispatch(resetDirty());
      }
    }, [dirty, dispatch]);

    useEffect(() => {
      if (setSubmitFormCallback) {
        setSubmitFormCallback(() => submitForm);
      }
    }, [setSubmitFormCallback, submitForm]);

    return null;
  };

  const setAllTouched = (valuesObj, setTouchedFunc) => {
    const markAllTouched = (obj) => {
      if (obj !== null && typeof obj === 'object') {
        if (Array.isArray(obj)) {
          return obj.map((item) => markAllTouched(item));
        } else {
          const res = {};
          for (const key in obj) {
            res[key] = markAllTouched(obj[key]);
          }
          return res;
        }
      }
      return true;
    };
    const touchedStructure = markAllTouched(valuesObj);
    setTouchedFunc(touchedStructure, false);
  };

  const buildErrorsObject = (err, values) => {
    const errors = {};
    err.inner.forEach((e) => {
      if (!e.path) return;
      const pathParts = e.path
        .replace(/\]/g, '')
        .split(/\.|\[/)
        .filter(Boolean);

      let customFieldName = null;
      if (pathParts[0] === 'customFields' && pathParts.length > 2) {
        const index = Number(pathParts[1]);
        if (!isNaN(index) && values.customFields && values.customFields[index]) {
          customFieldName = values.customFields[index].fieldName;
        }
      }

      let message = e.message;

      if (
        customFieldName &&
        (message === 'This field is required' ||
          message === 'Field name is required' ||
          message === 'Auto Accept Price is required' ||
          message === 'Auto Decline Price is required')
      ) {
        message = `Additional Field: (${customFieldName}) is required.`;
      } else if (
        customFieldName &&
        message === 'This field is required'
      ) {
        message = `Additional Field: (${customFieldName}) is required.`;
      }

      let current = errors;
      for (let i = 0; i < pathParts.length - 1; i++) {
        const part = pathParts[i];
        if (!current[part]) current[part] = {};
        current = current[part];
      }
      current[pathParts[pathParts.length - 1]] = message;
    });
    return errors;
  };

  const handlePhotographyClick = () => {
    if (isDirty) {
      photographyPendingAction.current = true;
      onShowUnsavedChangesModal({
        onSaved: () => {
          photographyPendingAction.current = false;
          openEditPhotographyModal();
        },
        onDiscarded: () => {
          photographyPendingAction.current = false;
          if (itemDetails && itemDetails._id) {
            openEditPhotographyModal();
          }
        },
        onCanceled: () => {
          photographyPendingAction.current = false;
        }
      });
    } else {
      openEditPhotographyModal();
    }
  };

  const showChannelResults = (status, payload) => {
    const { results = [], errors = [] } = payload;

    const successChannels = results.filter(r => r.status === 'success');
    const skippedChannels = results.filter(r => r.status === 'skipped');
    const errorChannels = errors;

    if (status === 'success' && successChannels.length > 0) {
      const successNames = successChannels.map(ch => `${ch.platform}: ${ch.message}`).join(' | ');
      toast.info(`Success channels: ${successNames}`, { position: 'top-right' });
    }

    if (status === 'partial_success') {
      if (successChannels.length > 0) {
        const successNames = successChannels.map(ch => `${ch.platform}: ${ch.message}`).join(' | ');
        toast.info(`These channels succeeded: ${successNames}`, { position: 'top-right' });
      }
      if (skippedChannels.length > 0) {
        skippedChannels.forEach(ch => {
          toast.info(`Skipped ${ch.platform}: ${ch.message}`, { position: 'top-right' });
        });
      }
      if (errorChannels.length > 0) {
        errorChannels.forEach(errItem => {
          toast.error(`Error on ${errItem.platform}: ${errItem.message}`, { position: 'top-right' });
        });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, setTouched, setErrors }) => {
        try {
          // Determine status for validation:
          const recordStatusForValidation = previouslyCompleted ? 'completed' : values.recordCompletionStatus;

          await validationSchema.validate(values, {
            abortEarly: false,
            context: { recordCompletionStatus: recordStatusForValidation },
          });

          // Validation passed. Determine final status after validation:
          let finalStatus;
          if (previouslyCompleted) {
            finalStatus = 'completed';
          } else {
            // Only become completed if all fields are now filled
            finalStatus = determineRecordCompletionStatus(values);
          }

          const bestOfferEnabled = values.basicDetails.bestOfferEnabled;
          const safeCustomFields = values.customFields.map(cf => ({
            ...cf,
            platforms: Array.isArray(cf.platforms) ? cf.platforms : []
          }));

          const submissionData = {
            ...values.basicDetails,
            category: values.category,
            listToChannels: values.listToChannels,
            recordCompletionStatus: finalStatus,
            photographyStatus: values.photographyStatus,
            customFields: safeCustomFields.map((field) => ({
              fieldName: field.fieldName,
              value: field.value,
              isRequired: field.isRequired,
              platforms: field.platforms,
              useInTemplates: field.useInTemplates,
            })),
            bestOfferTerms: {
              bestOfferEnabled,
              overrideBestOffer: values.basicDetails.overrideBestOffer,
              autoAcceptPrice: {
                currency: 'USD',
                value: bestOfferEnabled ? values.basicDetails.autoAcceptPrice : null,
              },
              autoDeclinePrice: {
                currency: 'USD',
                value: bestOfferEnabled ? values.basicDetails.autoDeclinePrice : null,
              },
            },
          };

          const action = isEditMode
            ? dispatch(updateItem({ id: productId, inventoryData: submissionData }))
            : dispatch(addInventoryItem(submissionData));

          const res = await action;
          const payload = res.payload || {};

          if (!res.error) {
            const status = payload.status || 'success';
            const mainMessage = payload.message || (isEditMode ? 'Product updated successfully!' : 'Product created successfully!');

            if (status === 'partial_success') {
              toast.warn(mainMessage, { position: 'top-right' });
              showChannelResults('partial_success', payload);
            } else if (status === 'success') {
              toast.success(mainMessage, { position: 'top-right' });
              showChannelResults('success', payload);

              if (!isEditMode && payload.data && payload.data._id) {
                navigate(`/arbie/products/${payload.data._id}`);
              }
            }

            dispatch(resetDirty());
          } else {
            const errorMessage = payload.message || 'Failed to save product.';
            toast.error(errorMessage, { position: 'top-right' });
            if (payload.errors && Array.isArray(payload.errors)) {
              payload.errors.forEach((errItem) => {
                toast.error(errItem.message || 'An error occurred', {
                  position: 'top-right',
                });
              });
            }
          }
        } catch (err) {
          if (err.name === 'ValidationError') {
            const errorsObject = buildErrorsObject(err, values);
            setErrors(errorsObject);
            setAllTouched(values, setTouched);

            const allErrorMessages = [];
            const collectErrors = (obj) => {
              for (let key in obj) {
                if (typeof obj[key] === 'string') {
                  allErrorMessages.push(obj[key]);
                } else if (typeof obj[key] === 'object') {
                  collectErrors(obj[key]);
                }
              }
            };
            collectErrors(errorsObject);

            allErrorMessages.forEach((msg) => {
              toast.error(msg, {
                position: 'top-right',
                autoClose: 5000,
              });
            });
          } else {
            toast.error('An unexpected error occurred.', {
              position: 'top-right',
            });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const recordCompletionStatus = formikProps.values.recordCompletionStatus;
        const saveButtonLabel = recordCompletionStatus === 'draft' ? 'Save as Draft' : 'Save';
        const { setFieldValue, values, isSubmitting } = formikProps;

        return (
          <>
            {isSubmitting && <Spinner />}
            <Form>
              <div className={`mx-auto p-6 lg:pt-4 lg:px-4 ${darkMode ? 'dark' : ''}`}>
                <div className="flex justify-between mb-4 items-center">
                  <div className="flex items-center">
                    <PromptLink
                      to="/arbie/ecom-products"
                      className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
                      onClick={(path) => onNavigateAttempt(path)}
                    >
                      <ArrowLeftIcon className="h-6 w-6 mr-2" />
                    </PromptLink>
                    <h1 className="text-2xl font-bold dark:text-gray-100">{pageTitle}</h1>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      type="submit"
                      className={`bg-green-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-green-600 text-sm ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={isSubmitting}
                    >
                      {saveButtonLabel}
                    </button>
                    {isEditMode && (
                      <>
                        <button
                          type="button"
                          className="bg-red-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-red-600 text-sm"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="bg-indigo-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-indigo-600 text-sm"
                        >
                          Archive
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                  <div className="lg:col-span-3">
                    <ProductBasicDetails darkMode={darkMode} />
                  </div>
                  <div className="lg:col-span-1">
                    <ProductConnections
                      inventoryRecord={itemDetails}
                      darkMode={darkMode}
                      setFieldValue={setFieldValue}
                      formData={values}
                      isEditMode={isEditMode}
                      initialListToChannels={values.listToChannels}
                      openModal={() => setIsManageConnectionsModalOpen(true)}
                    />
                    <div className="mt-8">
                      <ProductOrganization
                        category={values.category}
                        setFieldValue={setFieldValue}
                        darkMode={darkMode}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-0">
                  <ProductMediaSection
                    media={itemDetails?.images || []}
                    darkMode={darkMode}
                    onUploadImages={handlePhotographyClick}
                  />
                </div>

                {isManageConnectionsModalOpen && (
                  <ManageConnectionsModal
                    isOpen={isManageConnectionsModalOpen}
                    closeModal={() => setIsManageConnectionsModalOpen(false)}
                    connections={connections}
                    darkMode={darkMode}
                    setFieldValue={setFieldValue}
                    isEditMode={isEditMode}
                    initialListToChannels={values.listToChannels}
                  />
                )}

                {isEditPhotographyModalOpen && (
                  <EditPhotographyModal
                    isOpen={isEditPhotographyModalOpen}
                    onClose={closeEditPhotographyModal}
                    item={itemDetails}
                  />
                )}
              </div>
            </Form>
            <FormikObserver />
          </>
        );
      }}
    </Formik>
  );
};

export default ProductDetailsPage;
