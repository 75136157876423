import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CryptoJS from 'crypto-js'
import { getPlans } from '../../features/subscriptions/subscriptionSlice'
import { CheckCircleIcon } from '@heroicons/react/outline'

/**
 * Custom hook to detect if screen width ≥ 1024px ("desktop").
 */
function useDesktopMediaQuery() {
  const [isDesktop, setIsDesktop] = useState(() => window.innerWidth >= 1024)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isDesktop
}

const PricingSection = () => {
  const dispatch = useDispatch()
  const { plans } = useSelector((state) => state.subscription)

  // Determine if screen is "desktop" sized
  const isDesktop = useDesktopMediaQuery()

  // Fetch plan data on mount
  useEffect(() => {
    const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET
    const encryptedPassword = CryptoJS.AES.encrypt(
      preSharedPassword,
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString()
    dispatch(getPlans(encryptedPassword))
  }, [dispatch])

  // Sort plans by ascending hierarchy. If 'hierarchy' is missing, place last.
  const sortedPlans = [...plans].sort((a, b) => {
    const aHasHierarchy = typeof a.hierarchy === 'number'
    const bHasHierarchy = typeof b.hierarchy === 'number'
    if (aHasHierarchy && bHasHierarchy) {
      return a.hierarchy - b.hierarchy
    } else if (aHasHierarchy && !bHasHierarchy) {
      return -1
    } else if (!aHasHierarchy && bHasHierarchy) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <section
      id="pricing"
      aria-label="Final Beta Invitation"
      className="relative w-full py-16 sm:py-24 lg:py-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Two-column layout */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">

          {/* LEFT COLUMN: CTA / Intro */}
          <div className="space-y-6 lg:pr-4">
            <h2 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-900 dark:text-white leading-tight">
              Ready to Experience ArbiEngine?
            </h2>
            <p className="text-lg sm:text-xl text-gray-700 dark:text-gray-300 leading-relaxed">
              We’re currently in <strong>Beta</strong> for eBay Power Sellers,
              but if you’re excited about ArbiEngine’s potential for
              <strong> entrepreneurs</strong> and
              <strong> enterprises</strong> alike, come join our
              <strong> Early Adopter Initiative</strong>. Explore advanced
              listing flows, AI-powered insights, and a risk-free onboarding
              experience—all while helping us shape the future of ecommerce.
            </p>

            {/* Two Buttons: Join the Beta + Schedule a Demo */}
            <div className="flex flex-col sm:flex-row gap-4 pt-2">
              {/* Button 1: Join the Beta -> /beta */}
              <a
                href="/beta"
                className="inline-block rounded-full bg-indigo-600 px-6 py-3 text-center font-semibold text-white shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition-transform transform hover:scale-105"
              >
                Join the Beta
              </a>

              {/* Button 2: Schedule a Demo -> calendly.com in a new tab */}
              <a
                href="https://calendly.com/arbiengine/30min"
                target="_blank"
                rel="noreferrer noopener"
                className="inline-block rounded-full border-2 border-indigo-600 dark:border-indigo-300 px-6 py-3 text-center font-semibold text-indigo-600 dark:text-indigo-300 shadow-md hover:bg-indigo-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition-transform transform hover:scale-105"
              >
                Schedule a Demo
              </a>
            </div>
          </div>

          {/* RIGHT COLUMN: Plan Cards */}
          <div>
            {!sortedPlans.length ? (
              // Fallback if no plans
              <p className="text-gray-500 dark:text-gray-400">
                No plans available at this time.
              </p>
            ) : isDesktop ? (
              // DESKTOP: horizontal scroll container with partial peek of next card
              <div className="mt-8 custom-scrollbar overflow-x-auto flex space-x-8 p-2">
                {sortedPlans.map((plan) => (
                  <div
                    key={plan._id}
                    className="min-w-[28rem] max-w-[30rem] h-[36rem] flex-shrink-0 
                               bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 
                               relative"
                  >
                    <DynamicPlanCard plan={plan} />
                  </div>
                ))}
              </div>
            ) : (
              // MOBILE: stacked layout
              <div className="mt-8 grid grid-cols-1 gap-6">
                {sortedPlans.map((plan) => (
                  <div
                    key={plan._id}
                    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 relative"
                  >
                    <DynamicPlanCard plan={plan} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Minimal horizontal scrollbar styling (for desktop container) */}
      <style>{`
        .custom-scrollbar::-webkit-scrollbar {
          height: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: rgba(107,114,128,0.4); /* approx. gray-500 */
          border-radius: 9999px;
        }
        .custom-scrollbar:hover::-webkit-scrollbar-thumb {
          background-color: rgba(107,114,128,0.6);
        }
      `}</style>
    </section>
  )
}

/** Single plan card component */
const DynamicPlanCard = ({ plan }) => {
  const { name, price, description, features, hasFreeTrial } = plan

  return (
    <div className="flex flex-col w-full h-full">
      {/* Free Trial badge if applicable */}
      {hasFreeTrial && (
        <span className="absolute top-4 right-4 bg-green-500 text-white text-sm font-extrabold px-4 py-2 rounded-full shadow-lg">
          FREE TRIAL
        </span>
      )}

      {/* Title & Price */}
      <div className="mb-6">
        <h3 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-2">
          {name}
        </h3>
        <div className="flex items-baseline space-x-2">
          <span className="text-5xl font-extrabold text-indigo-600 dark:text-indigo-400">
            ${price}
          </span>
          <span className="text-2xl text-gray-600 dark:text-gray-300">
            /month
          </span>
        </div>
      </div>

      {/* Description */}
      <p className="mb-4 text-lg text-gray-600 dark:text-gray-300 leading-relaxed flex-shrink-0">
        {description}
      </p>

      {/* Features (scrollable) */}
      <div className="flex-1 overflow-y-auto custom-vertical-scroll pr-2">
        <ul className="space-y-3">
          {features?.map((feat, i) => (
            <li
              key={i}
              className="flex items-center text-base text-gray-700 dark:text-gray-300"
            >
              <CheckCircleIcon className="h-5 w-5 text-indigo-600 dark:text-indigo-400 mr-3 flex-shrink-0" />
              <span>{feat}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* CTA */}
      <a
        href="/beta"
        className="mt-6 rounded-full bg-indigo-600 py-3 text-center font-semibold text-white shadow-md 
                  hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 
                  transition-transform transform hover:scale-105"
      >
        Lock in My Rate
      </a>

      {/* Vertical scrollbar styling */}
      <style>{`
        .custom-vertical-scroll::-webkit-scrollbar {
          width: 8px;
        }
        .custom-vertical-scroll::-webkit-scrollbar-thumb {
          background-color: rgba(107,114,128,0.4);
          border-radius: 9999px;
        }
        .custom-vertical-scroll:hover::-webkit-scrollbar-thumb {
          background-color: rgba(107,114,128,0.6);
        }
      `}</style>
    </div>
  )
}

export default PricingSection
