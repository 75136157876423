import { createSelector } from '@reduxjs/toolkit';

// Basic Selectors
export const selectDefaultCustomFields = (state) => state.inventory.defaultCustomFields || [];
export const selectInventoryCustomFields = (state) => state.inventory.itemDetails.customFields || [];
export const selectMissingCustomFields = (state) => state.inventory.missingCustomFields || [];
export const selectAutoFillPreferences = (state) => ({
  autoFillMissingFields: state.inventory.autoFillMissingFields,
  autoFillMissingFieldsValue: state.inventory.autoFillMissingFieldsValue,
});
export const selectError = (state) => state.inventory.error;
export const selectCustomFieldsError = (state) => state.inventory.customFieldsError;

/**
 * Selector to merge defaultCustomFields, inventoryCustomFields, and missingCustomFields,
 * then group them by fieldName and platform while aggregating sources.
 */
export const selectMergedCustomFields = createSelector(
  [selectDefaultCustomFields, selectInventoryCustomFields, selectMissingCustomFields],
  (defaultFields, inventoryFields, missingFields) => {
    const fieldMap = {};

    const processFields = (fields, source) => {
      fields.forEach((field) => {
        const platform = field.platform || 'General';
        const key = `${field.fieldName}_${platform}`;

        if (!fieldMap[key]) {
          fieldMap[key] = {
            fieldName: field.fieldName,
            isRequired: !!field.isRequired,
            value: '',
            useInTemplates: !!field.useInTemplates,
            platforms: new Set(),
            sources: new Set(),
          };
        }

        // Add this platform to the set
        fieldMap[key].platforms.add(platform);
        // Add this source to the set
        fieldMap[key].sources.add(source);

        // Determine value precedence: inventory > missing > default
        if (source === 'inventory' && field.value !== undefined && field.value !== null) {
          fieldMap[key].value = field.value;
        } else if (
          source === 'missing' &&
          (fieldMap[key].value === '' || fieldMap[key].value === undefined)
        ) {
          fieldMap[key].value = field.value || '';
        } else if (
          source === 'default' &&
          (fieldMap[key].value === '' || fieldMap[key].value === undefined)
        ) {
          fieldMap[key].value = field.value || '';
        }

        // If any version of the field is required, set isRequired to true
        if (field.isRequired) {
          fieldMap[key].isRequired = true;
        }

        // If any version of the field usesInTemplates is true, set it true
        if (field.useInTemplates) {
          fieldMap[key].useInTemplates = true;
        }
      });
    };

    processFields(defaultFields, 'default');
    processFields(inventoryFields, 'inventory');
    processFields(missingFields, 'missing');

    return Object.values(fieldMap).map((field) => ({
      ...field,
      // Convert sets to arrays
      platforms: Array.from(field.platforms),
      sources: Array.from(field.sources),
    }));
  }
);

/**
 * Selector to prepare the custom fields payload for backend submission.
 * It maps each merged custom field to individual platform-specific entries.
 */
export const selectCustomFieldsPayload = createSelector(
  [selectMergedCustomFields, selectAutoFillPreferences],
  (mergedFields, autoFillPrefs) => {
    const { autoFillMissingFields, autoFillMissingFieldsValue } = autoFillPrefs;
    const payload = [];

    mergedFields.forEach((field) => {
      const { fieldName, isRequired, platforms, value, useInTemplates } = field;

      platforms.forEach((platform) => {
        let fieldValue = value;

        // Auto-fill if needed and allowed
        if (
          (!fieldValue || fieldValue.trim() === '') &&
          autoFillMissingFields &&
          autoFillMissingFieldsValue
        ) {
          fieldValue = autoFillMissingFieldsValue;
        }

        payload.push({
          fieldName,
          value: fieldValue,
          isRequired,
          platform,
          useInTemplates,
        });
      });
    });

    return payload;
  }
);
