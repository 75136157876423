import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendReferAFriendEmail, createLead } from './marketingService';

// Initial state for the marketing slice
const initialState = {
  // Refer-a-friend states
  referralEmail: '',
  loading: false,
  success: false,
  error: null,

  // Beta lead states
  betaLeadLoading: false,
  betaLeadSuccess: false,
  betaLeadError: null,
  betaLeadResponse: null, // where we can store response from createLead
};

/**
 * Async thunk for sending the refer-a-friend email
 */
export const referAFriend = createAsyncThunk(
  'marketing/referAFriend',
  async ({ email }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token, user },
      } = getState();
      const response = await sendReferAFriendEmail(email, token, user.name);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Could not send refer-a-friend email'
      );
    }
  }
);

/**
 * Async thunk for creating a new Beta Lead
 * No token needed, only reCAPTCHA on the backend.
 */
export const submitBetaLead = createAsyncThunk(
  'marketing/submitBetaLead',
  async (leadData, { rejectWithValue }) => {
    try {
      const response = await createLead(leadData);
      return response; // e.g. { message: 'Lead created successfully!', lead: {...} }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Could not create beta lead'
      );
    }
  }
);

const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    resetReferralStatus: (state) => {
      state.success = false;
      state.error = null;
      state.referralEmail = '';
    },
    setReferralEmail: (state, action) => {
      state.referralEmail = action.payload;
    },
    // For resetting the beta lead submission
    resetBetaLeadStatus: (state) => {
      state.betaLeadLoading = false;
      state.betaLeadSuccess = false;
      state.betaLeadError = null;
      state.betaLeadResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ==========================
      // Refer-a-Friend Thunk
      // ==========================
      .addCase(referAFriend.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(referAFriend.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(referAFriend.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })

      // ==========================
      // Submit Beta Lead Thunk
      // ==========================
      .addCase(submitBetaLead.pending, (state) => {
        state.betaLeadLoading = true;
        state.betaLeadSuccess = false;
        state.betaLeadError = null;
        state.betaLeadResponse = null;
      })
      .addCase(submitBetaLead.fulfilled, (state, action) => {
        state.betaLeadLoading = false;
        state.betaLeadSuccess = true;
        state.betaLeadResponse = action.payload; // { message, lead } from server
      })
      .addCase(submitBetaLead.rejected, (state, action) => {
        state.betaLeadLoading = false;
        state.betaLeadSuccess = false;
        state.betaLeadError = action.payload;
      });
  },
});

export const {
  resetReferralStatus,
  setReferralEmail,
  resetBetaLeadStatus,
} = marketingSlice.actions;

export default marketingSlice.reducer;
