import React, { useState } from 'react';
import { 
  CheckCircleIcon, 
  XCircleIcon, 
  RefreshIcon,
  ClockIcon 
} from '@heroicons/react/solid';
import HelperModal from '../../components/HelperModal';

const CurrentSubscriptionCard = ({ subscription, onCancel, onToggleAutoRenew, tiers }) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isAutoRenewModalOpen, setIsAutoRenewModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTogglingAutoRenew, setIsTogglingAutoRenew] = useState(false);

  // Create a mapping from plan names to their prices for easy access
  const planPrices = tiers.reduce((acc, tier) => {
    acc[tier.name] = tier.price ? `$${tier.price}/month` : 'Custom pricing';
    return acc;
  }, {});

  const getPlanPrice = (planName) => planPrices[planName] || 'N/A';

  // Determine the current tier based on the subscription's plan
  const currentTier = subscription && subscription.plan
    ? tiers.find((planItem) => planItem.name === subscription.plan)
    : null;

  /**
   * Handle the cancellation of the subscription
   */
  const handleCancel = async () => {
    setIsProcessing(true);
    await onCancel(subscription.stripeSubscriptionId);
    setIsProcessing(false);
    setIsCancelModalOpen(false);
  };

  /**
   * Handle toggling of auto-renew
   */
  const handleToggleAutoRenew = async () => {
    setIsTogglingAutoRenew(true);
    await onToggleAutoRenew(subscription.stripeSubscriptionId, !subscription.autoRenew);
    setIsTogglingAutoRenew(false);
    setIsAutoRenewModalOpen(false);
  };

  // Handle cases where there is no active subscription
  if (!subscription || !subscription.plan) {
    return (
      <div className='bg-white dark:bg-gray-800 p-6 border rounded-lg shadow-lg text-center'>
        <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-4'>
          No Current Subscription
        </h2>
        <p className='text-base text-gray-700 dark:text-gray-300'>
          You currently do not have an active subscription. Choose a plan below to get started.
        </p>
      </div>
    );
  }

  /**
   * Get the appropriate icon and text based on subscription status
   */
  const getStatusIconAndText = (status) => {
    switch (status) {
      case 'active':
        return {
          icon: <CheckCircleIcon className='h-6 w-6 text-green-500 ml-2' />,
          text: 'Active',
        };
      case 'trialing':
        return {
          icon: <ClockIcon className='h-6 w-6 text-blue-500 ml-2' />,
          text: 'On Free Trial',
        };
      case 'inactive':
        return {
          icon: <XCircleIcon className='h-6 w-6 text-yellow-500 ml-2' />,
          text: 'Inactive',
        };
      case 'cancelled':
        return {
          icon: <XCircleIcon className='h-6 w-6 text-red-500 ml-2' />,
          text: 'Cancelled',
        };
      default:
        return { icon: null, text: 'Unknown' };
    }
  };

  const { icon, text } = getStatusIconAndText(subscription.status);

  // Format the renewal or end date
  const renewalOrEndDate = subscription.endDate
    ? new Date(subscription.endDate).toLocaleDateString()
    : 'N/A';

  return (
    <>
      <div className='bg-white dark:bg-gray-800 p-6 border rounded-lg shadow-lg bg-gradient-to-r dark:from-gray-800 dark:to-gray-900'>
        <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-4'>
          Current Subscription
        </h2>
        <div className='text-base space-y-2'>
          <p className='text-gray-700 dark:text-gray-300'>
            <span className='font-semibold'>Plan:</span> {subscription.plan}
          </p>
          <p className='text-gray-700 dark:text-gray-300'>
            <span className='font-semibold'>Price:</span> {getPlanPrice(subscription.plan)}
          </p>
          <p className='flex items-center text-gray-700 dark:text-gray-300'>
            <span className='font-semibold'>Status:</span>
            {icon} <span className='ml-2'>{text}</span>
          </p>
          {subscription.status === 'trialing' && (
            <p className='text-blue-600 text-sm mt-2'>
              Enjoy your free trial! It ends on <strong>{renewalOrEndDate}</strong>.
            </p>
          )}

          <p className='text-gray-700 dark:text-gray-300'>
            <span className='font-semibold'>
              {subscription.autoRenew ? 'Renewal Date:' : 'End Date:'}
            </span>{' '}
            {renewalOrEndDate}
          </p>
          <div className='flex items-center mt-2'>
            <span className='font-semibold text-gray-700 dark:text-gray-300'>
              Auto Renew:
            </span>
            <button
              onClick={() => setIsAutoRenewModalOpen(true)}
              disabled={isTogglingAutoRenew}
              className={`ml-2 flex items-center space-x-1 ${
                isTogglingAutoRenew 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'text-blue-500 hover:text-blue-600'
              }`}
            >
              {subscription.autoRenew ? (
                <RefreshIcon className='h-6 w-6' />
              ) : (
                <XCircleIcon className='h-6 w-6 text-red-500' />
              )}
              <span>{subscription.autoRenew ? 'On' : 'Off'}</span>
            </button>
          </div>
        </div>
        <div className='mt-4 flex'>
          <button
            onClick={() => subscription.status === 'active' && setIsCancelModalOpen(true)}
            disabled={subscription.status !== 'active'}
            className={`font-bold py-2 px-4 rounded ${
              subscription.status === 'active'
                ? 'bg-red-500 hover:bg-red-600 text-white'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Cancel Subscription
          </button>
        </div>
      </div>

      {/* Cancel Subscription Modal */}
      <HelperModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        title="Cancel Subscription"
        confirmText="Yes, Cancel"
        cancelText="No, Go Back"
        onConfirm={handleCancel}
        isLoading={isProcessing}
      >
        <p className='text-gray-700 dark:text-gray-300'>
          Are you sure you want to cancel your subscription? This action cannot be undone.
        </p>
      </HelperModal>

      {/* Auto-Renew Toggle Modal */}
      <HelperModal
        isOpen={isAutoRenewModalOpen}
        onClose={() => setIsAutoRenewModalOpen(false)}
        title="Toggle Auto Renew"
        confirmText={`Yes, Turn ${subscription.autoRenew ? 'Off' : 'On'}`}
        cancelText="No, Go Back"
        onConfirm={handleToggleAutoRenew}
        isLoading={isTogglingAutoRenew}
      >
        <p className='text-gray-700 dark:text-gray-300'>
          Are you sure you want to turn {subscription.autoRenew ? 'off' : 'on'} 
          auto-renew for this subscription?
        </p>
      </HelperModal>
    </>
  );
};

export default CurrentSubscriptionCard;
