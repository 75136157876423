import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleIcon,
  HandIcon,
  CurrencyDollarIcon,
  ShieldCheckIcon,
  BriefcaseIcon,
  TagIcon,
  SparklesIcon,
  GiftIcon,
} from "@heroicons/react/outline";

const EarlyAdopterInitiative = () => {
  const navigate = useNavigate();

  // Navigate to /beta, then scroll to top
  const handleJoinBetaClick = () => {
    navigate("/beta");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  // Open Calendly in a new tab for scheduling a demo
  const handleScheduleDemoClick = () => {
    window.open("https://calendly.com/arbiengine/30min", "_blank", "noopener,noreferrer");
  };

  return (
    <section
      id="early-adopter-initiative"
      className="relative w-full py-16 bg-gradient-to-b from-gray-100 via-white to-indigo-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

        {/* Section Intro / Heading */}
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 dark:text-white leading-tight">
            ArbiEngine Beta: Early Adopter
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-700 dark:text-gray-300 leading-relaxed">
            We’re building tomorrow’s all-in-one ecommerce platform—
            starting with <strong>eBay Power Sellers</strong>. Our Beta Release
            offers a <strong>risk-free</strong> onboarding and <strong>locked-in</strong> pricing
            while you help shape our next generation of features.
          </p>
          <p className="mt-4 text-base sm:text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
            Our vision is to replace outdated ERPs for <strong>entrepreneurs</strong> and <strong>enterprises</strong> alike.&nbsp;
            <a
              href="/about-us"
              className="text-indigo-600 dark:text-indigo-400 font-semibold hover:underline"
            >
              Learn more about our team
            </a>
          </p>
        </div>

        {/* Why eBay Power Sellers */}
        <div className="mt-12 max-w-5xl mx-auto grid gap-10 lg:grid-cols-2 items-center">
          <div className="space-y-6">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white">
              Why Start with eBay?
            </h2>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
              eBay is high-volume and fast-paced—perfect for stress-testing advanced
              listing flows, analytics, and order management. By tackling the toughest
              environment first, ArbiEngine ensures you focus on <strong>profit</strong>,
              not clunky processes.
            </p>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
              As a Beta Partner, you’ll have <strong>direct input</strong> on new features
              before we expand to more marketplaces. Your feedback drives our
              evolution across all ecommerce channels.
            </p>
          </div>

          {/* Beta Benefits Card */}
          <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 sm:p-8 space-y-6">
            <div className="flex items-start">
              <CheckCircleIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400 mr-3" />
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                <strong>Real-Time Collaboration</strong> — Influence development
                with direct product team communication.
              </p>
            </div>
            <div className="flex items-start">
              <HandIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400 mr-3" />
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                <strong>Risk-Free Trial</strong> — Try us free for a month; cancel
                anytime if we’re not the right fit.
              </p>
            </div>
            <div className="flex items-start">
              <CurrencyDollarIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400 mr-3" />
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                <strong>Locked-In Pricing</strong> — <strong>$100/month</strong>
                {" "}for up to 5,000 items, guaranteed your first year.
              </p>
            </div>
            <div className="flex items-start">
              <ShieldCheckIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400 mr-3" />
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                <strong>Beta-Exclusive Access</strong> — Preview ArbiEngine’s newest
                capabilities before anyone else.
              </p>
            </div>
          </div>
        </div>

        {/* Ideal eBay Categories */}
        <div className="mt-16">
          <h3 className="text-2xl sm:text-3xl font-bold text-center text-gray-900 dark:text-white">
            Ideal for These eBay Categories
          </h3>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <BriefcaseIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400" />
              <h4 className="mt-3 text-lg font-semibold text-gray-900 dark:text-white">
                Industrial &amp; Automotive
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center leading-relaxed">
                Manage large or complex parts effortlessly.
              </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <TagIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400" />
              <h4 className="mt-3 text-lg font-semibold text-gray-900 dark:text-white">
                Apparel &amp; Clothing
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center leading-relaxed">
                Batch-upload fast-moving fashion catalogs.
              </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <SparklesIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400" />
              <h4 className="mt-3 text-lg font-semibold text-gray-900 dark:text-white">
                Antiques &amp; Collectibles
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center leading-relaxed">
                Protect the value of unique, vintage items.
              </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
              <GiftIcon className="h-10 w-10 text-indigo-500 dark:text-indigo-400" />
              <h4 className="mt-3 text-lg font-semibold text-gray-900 dark:text-white">
                Trading Cards &amp; More
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-center leading-relaxed">
                Bulk-list specialty collectibles hassle-free.
              </p>
            </div>
          </div>
        </div>

        {/* Final CTA Card */}
        <div className="mt-16 max-w-4xl mx-auto bg-white dark:bg-gray-800 shadow-lg rounded-lg px-6 py-8 sm:p-10">
          <div className="text-center">
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white">
              Join Our 100 Beta Partners
            </h3>
            <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
              We’re accepting only <strong>100 eBay Power Sellers</strong> for
              this Beta. Lock in your rate, shape our roadmap, and help build the
              <strong> ultimate ecommerce command center</strong>.
            </p>
          </div>
          <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={handleJoinBetaClick}
              className="w-full sm:w-auto rounded-full bg-indigo-600 dark:bg-indigo-700 px-6 py-3 text-lg font-semibold text-white 
                         shadow-md hover:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 
                         dark:focus:ring-indigo-500 transition-transform transform hover:scale-105"
            >
              Join the Beta
            </button>
            <button
              onClick={handleScheduleDemoClick}
              className="w-full sm:w-auto rounded-full border-2 border-indigo-600 dark:border-indigo-700 px-6 py-3 text-lg 
                         font-semibold text-indigo-600 dark:text-indigo-300 shadow-md hover:bg-indigo-100 dark:hover:bg-gray-800 
                         focus:outline-none focus:ring-2 focus:ring-indigo-300 dark:focus:ring-indigo-500 transition-transform 
                         transform hover:scale-105"
            >
              Schedule a Demo
            </button>
          </div>
        </div>

        {/* Closing Note */}
        <div className="mt-8 text-center max-w-3xl mx-auto">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Note: This Beta Release showcases only a fraction of ArbiEngine’s vision.
            As we near <strong>Q2 2025</strong>, we’ll expand beyond eBay to
            empower sellers on <strong>every major platform</strong>.
          </p>
          <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
            Join now to secure exclusive benefits and help shape the future of ecommerce.
          </p>
        </div>
      </div>
    </section>
  );
};

export default EarlyAdopterInitiative;
