import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DashHeader from '../../components/Dashboard/Header/DashHeader';
import DashSidebar from '../../components/Dashboard/DashSidebar';
import UnsavedChangesModal from '../../components/Dashboard/Ecommerce/ProductDetailsPage/UnsavedChanges/UnsavedChangesModal';

import { resetDirty } from '../../features/unsavedChanges/unsavedChangesSlice';

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Sidebar state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Unsaved changes state from Redux
  const isDirty = useSelector((state) => state.unsavedChanges.isDirty);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  // For non-navigation triggers (like photography modal open)
  const pendingActionCallbacks = useRef({
    onSaved: null,
    onDiscarded: null,
    onCanceled: null,
  });

  // Callback to submit form from ProductDetailsPage
  const [submitFormCallback, setSubmitFormCallback] = useState(null);

  const handleNavigationAttempt = (path) => {
    if (isDirty) {
      setNextLocation(path);
      setIsModalOpen(true);
      // Clear any pending callbacks since this is a navigation attempt
      pendingActionCallbacks.current = { onSaved: null, onDiscarded: null, onCanceled: null };
    } else {
      navigate(path);
    }
  };

  /**
   * Show unsaved changes modal for non-navigation triggers (e.g. photography)
   * Call this with callbacks that define what happens on save/discard/cancel.
   */
  const onShowUnsavedChangesModal = ({ onSaved, onDiscarded, onCanceled }) => {
    if (isDirty) {
      setIsModalOpen(true);
      setNextLocation(null); // Not a navigation attempt
      pendingActionCallbacks.current = { onSaved, onDiscarded, onCanceled };
    } else {
      // If not dirty, just call onSaved directly if needed
      if (onSaved) onSaved();
    }
  };

  const handleSave = async () => {
    if (submitFormCallback) {
      // submitFormCallback returns a promise from Formik submission
      await submitFormCallback();
      // After successful save:
      dispatch(resetDirty());
      setIsModalOpen(false);
      // If it was a navigation attempt:
      if (nextLocation) {
        navigate(nextLocation);
      }
      // If it was a pending action (like photography):
      if (pendingActionCallbacks.current.onSaved) {
        pendingActionCallbacks.current.onSaved();
      }

      // Reset callbacks
      pendingActionCallbacks.current = { onSaved: null, onDiscarded: null, onCanceled: null };
      setNextLocation(null);
    } else {
      // No submitForm available (unexpected)
      dispatch(resetDirty());
      setIsModalOpen(false);
      if (nextLocation) {
        navigate(nextLocation);
      }
      // Call onSaved if exists
      if (pendingActionCallbacks.current.onSaved) {
        pendingActionCallbacks.current.onSaved();
      }
      pendingActionCallbacks.current = { onSaved: null, onDiscarded: null, onCanceled: null };
      setNextLocation(null);
    }
  };

  const handleDiscard = () => {
    dispatch(resetDirty());
    setIsModalOpen(false);
    if (nextLocation) {
      navigate(nextLocation);
    }

    // If it was a pending action (like photography)
    if (pendingActionCallbacks.current.onDiscarded) {
      pendingActionCallbacks.current.onDiscarded();
    }

    pendingActionCallbacks.current = { onSaved: null, onDiscarded: null, onCanceled: null };
    setNextLocation(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // If pending action for photography:
    if (pendingActionCallbacks.current.onCanceled) {
      pendingActionCallbacks.current.onCanceled();
    }

    pendingActionCallbacks.current = { onSaved: null, onDiscarded: null, onCanceled: null };
    setNextLocation(null);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <div className='flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900'>
      <DashHeader
        setIsSidebarOpen={setIsSidebarOpen}
        onNavigateAttempt={handleNavigationAttempt}
      />
      <div className='flex flex-1 pt-16 overflow-hidden'>
        <DashSidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          onNavigateAttempt={handleNavigationAttempt}
        />

        <main className='flex-1 overflow-y-auto'>
          <Outlet context={{
            onNavigateAttempt: handleNavigationAttempt,
            onShowUnsavedChangesModal,
            setSubmitFormCallback
          }} />
        </main>
      </div>

      {isModalOpen && (
        <UnsavedChangesModal
          isOpen={isModalOpen}
          onSave={handleSave}
          onDiscard={handleDiscard}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default DefaultLayout;
