import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom"; // Import the hook for navigation

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Redux
import { submitBetaLead } from "../features/marketing/marketingSlice";

// React Hero Icons
import {
  CheckCircleIcon,
  ClipboardListIcon,
  CashIcon,
  UserIcon,
  MailIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  GlobeAltIcon,
} from "@heroicons/react/outline";

const ExpressInterestRedesigned = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { betaLeadLoading, betaLeadError } = useSelector(
    (state) => state.marketing
  );

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    storeName: "",
    websiteURL: "",
    industry: "",
    inventorySize: "",
    approximateRevenue: "",
    notes: "",
  });

  // reCAPTCHA value
  const [captcha, setCaptcha] = useState("");

  // Handle reCAPTCHA change
  const handleCaptchaChange = (value) => {
    setCaptcha(value);
  };

  // Validate required fields
  const validateForm = () => {
    if (!formData.fullName.trim()) {
      toast.error("Full name is required");
      return false;
    }
    if (!formData.email.trim()) {
      toast.error("Email is required");
      return false;
    }
    if (!formData.companyName.trim()) {
      toast.error("Company name is required");
      return false;
    }

    // Enforce reCAPTCHA if not in dev
    if (process.env.REACT_APP_NODE_ENV !== "development" && !captcha) {
      toast.error("Please complete the reCAPTCHA");
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const leadData = { ...formData, captcha };

    try {
      await dispatch(submitBetaLead(leadData)).unwrap();
      toast.success("Thank you for expressing interest! We will be in touch soon.");

      // Clear form data
      setFormData({
        fullName: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        storeName: "",
        websiteURL: "",
        industry: "",
        inventorySize: "",
        approximateRevenue: "",
        notes: "",
      });

      // Redirect to /powersellers
      navigate("/powersellers");
    } catch (err) {
      toast.error(betaLeadError || 'Something went wrong. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Helmet>
        {/* Basic HTML Metadata */}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Title */}
        <title>ArbiEngine Beta – Secure Your Spot</title>

        {/* Description */}
        <meta
          name="description"
          content="Join ArbiEngine's exclusive Beta to shape the future of e-commerce. Secure your risk-free trial and help finalize our Q2 2025 multi-vertical launch."
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="ArbiEngine, Beta, eBay Power Sellers, multi-vertical ecommerce, ERP alternative, listing optimization"
        />
        <meta name="robots" content="index, follow" />

        {/* Canonical */}
        <link rel="canonical" href="https://arbiengine.com/beta" />
        <meta name="author" content="ArbiEngine" />

        {/* JSON-LD for structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "ArbiEngine Beta – Secure Your Spot",
            description:
              "Join ArbiEngine's exclusive Beta to shape the future of e-commerce. Secure your risk-free trial and help finalize our Q2 2025 multi-vertical launch.",
            url: "https://arbiengine.com/beta",
            mainEntity: {
              "@type": "Organization",
              name: "ArbiEngine",
              logo: "https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png",
              sameAs: [
                "https://x.com/ArbiEngine",
                "https://www.instagram.com/arbiengine",
                "https://www.linkedin.com/company/arbiengine",
              ],
            },
          })}
        </script>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ArbiEngine Beta – Secure Your Spot" />
        <meta
          property="og:description"
          content="Be among the first to experience ArbiEngine’s streamlined listings, real-time collaboration, and next-gen ecommerce analytics."
        />
        <meta
          property="og:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
        <meta property="og:url" content="https://arbiengine.com/beta" />
        <meta property="og:site_name" content="ArbiEngine" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ArbiEngine Beta – Secure Your Spot"
        />
        <meta
          name="twitter:description"
          content="Help us shape the future of ecommerce. Join ArbiEngine’s Beta for advanced listing flows, locked-in pricing, and risk-free onboarding."
        />
        <meta
          name="twitter:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
      </Helmet>

      {/* Header */}
      <Header isSticky={true} />

      <main className="flex-grow py-12 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* LEFT COLUMN: Overview / Benefits */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 sm:p-8 flex flex-col h-[700px]">
              {/* Title & Intro */}
              <div className="mb-6">
                <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white mb-2">
                  Secure Your Spot in Our Beta
                </h2>
                <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300 mb-4 leading-relaxed">
                  We’re launching our <strong>Beta Release</strong> to power sellers on eBay
                  seeking a modern, streamlined workflow. By joining now, you’ll help
                  shape ArbiEngine’s evolution and <strong>lock in exclusive pricing</strong> as
                  we prepare for a <strong>Q2 2025</strong> full launch.
                </p>
                <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300 mb-4 leading-relaxed">
                  Take advantage of <strong>1 month free</strong> and then only{" "}
                  <strong>$100/month</strong> for your first year—no hidden fees or contracts.
                  Cancel anytime before the trial ends if we’re not a fit.
                </p>

                <ul className="space-y-2 text-sm sm:text-base text-gray-700 dark:text-gray-300">
                  <li className="flex items-start gap-2">
                    <CheckCircleIcon className="h-5 w-5 text-indigo-500 dark:text-indigo-400 mt-[2px]" />
                    <span>Hands-On Guidance & Fast Support</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircleIcon className="h-5 w-5 text-indigo-500 dark:text-indigo-400 mt-[2px]" />
                    <span>Exclusive Early Features</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircleIcon className="h-5 w-5 text-indigo-500 dark:text-indigo-400 mt-[2px]" />
                    <span>Risk-Free Month Trial</span>
                  </li>
                </ul>
              </div>

              {/* Why Join Now */}
              <div className="mt-auto">
                <hr className="mb-3 border-gray-300 dark:border-gray-700" />
                <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                  Looking Ahead: Q2 2025
                </h3>
                <p className="text-sm sm:text-base leading-relaxed text-gray-700 dark:text-gray-300">
                  This Beta is just the start. By Q2 2025, ArbiEngine will expand into a
                  multi-channel ecosystem—offering advanced analytics and integrations that
                  revolutionize how you sell. Lock in your spot now and help define the
                  future of ecommerce.
                </p>
              </div>
            </div>

            {/* RIGHT COLUMN: Form */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 sm:p-8 h-[700px] flex flex-col">
              <h2 className="text-xl sm:text-2xl font-extrabold text-gray-900 dark:text-white mb-3">
                Share Your Business Details
              </h2>
              <div className="flex-grow overflow-y-auto pr-2">
                <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-5">
                  {/* Full Name (required) */}
                  <div>
                    <label
                      htmlFor="fullName"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <UserIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="e.g. Jane Doe"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Email (required) */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <MailIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="you@example.com"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Company Name (required) */}
                  <div>
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <OfficeBuildingIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Company Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder="Your Company LLC"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Phone Number (optional) */}
                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <PhoneIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="(555) 123-4567"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Store Name (optional) */}
                  <div>
                    <label
                      htmlFor="storeName"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      Store Name
                    </label>
                    <input
                      type="text"
                      id="storeName"
                      name="storeName"
                      value={formData.storeName}
                      onChange={handleChange}
                      placeholder="E.g. VintageTreasures"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Website URL (optional) */}
                  <div>
                    <label
                      htmlFor="websiteURL"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <GlobeAltIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Website URL
                    </label>
                    <input
                      type="text"
                      id="websiteURL"
                      name="websiteURL"
                      value={formData.websiteURL}
                      onChange={handleChange}
                      placeholder="https://www.example.com"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Industry (optional) */}
                  <div>
                    <label
                      htmlFor="industry"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <ClipboardListIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Industry
                    </label>
                    <select
                      id="industry"
                      name="industry"
                      value={formData.industry}
                      onChange={handleChange}
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    >
                      <option value="">-- Select your industry --</option>
                      <option value="Industrial & Automotive">Industrial & Automotive</option>
                      <option value="Apparel & Clothing">Apparel & Clothing</option>
                      <option value="Antiques & Collectibles">Antiques & Collectibles</option>
                      <option value="Trading Cards & Memorabilia">Trading Cards & Memorabilia</option>
                      <option value="Electronics">Electronics</option>
                      <option value="General Ecommerce">General ecommerce</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {/* Inventory Size (optional) */}
                  <div>
                    <label
                      htmlFor="inventorySize"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <ClipboardListIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Approx. Inventory Items
                    </label>
                    <input
                      type="number"
                      min="0"
                      id="inventorySize"
                      name="inventorySize"
                      value={formData.inventorySize}
                      onChange={handleChange}
                      placeholder="e.g. 5000"
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Approx. Revenue (optional) */}
                  <div>
                    <label
                      htmlFor="approximateRevenue"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      <CashIcon className="inline-block h-4 w-4 mr-1 text-indigo-500 dark:text-indigo-400" />
                      Annual Revenue
                    </label>
                    <select
                      id="approximateRevenue"
                      name="approximateRevenue"
                      value={formData.approximateRevenue}
                      onChange={handleChange}
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    >
                      <option value="">-- Select range --</option>
                      <option value="Under $50k">Under $50k</option>
                      <option value="$50k - $250k">$50k - $250k</option>
                      <option value="$250k - $1M">$250k - $1M</option>
                      <option value="$1M - $5M">$1M - $5M</option>
                      <option value="$5M+">$5M+</option>
                    </select>
                  </div>

                  {/* Additional Notes (optional) */}
                  <div>
                    <label
                      htmlFor="notes"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
                    >
                      Anything else we should know?
                    </label>
                    <textarea
                      id="notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      placeholder="Tell us about your store or specific needs..."
                      rows={3}
                      className="w-full py-2 px-3 rounded-md border border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="pt-1">
                    <button
                      type="submit"
                      disabled={betaLeadLoading}
                      className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-sm sm:text-base font-semibold rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md w-full disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {betaLeadLoading ? "Submitting..." : "Request Beta Access"}
                    </button>
                  </div>

                  {/* reCAPTCHA */}
                  {process.env.REACT_APP_NODE_ENV !== "development" && (
                    <div className="mb-4 flex justify-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ExpressInterestRedesigned;
