import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import {
  cancelSubscription,
  clearError,
  getSubscription,
  getPlans,
  getPaymentMethod,
  toggleAutoRenew,
  fetchBillingDetails,
  updateSubscription,
} from '../../features/subscriptions/subscriptionSlice';

import CurrentSubscriptionCard from '../../components/subscriptions/CurrentSubscriptionCard';
import SubscriptionTierCard from '../../components/subscriptions/SubscriptionTierCard';
import PaymentMethodCard from '../../components/subscriptions/PaymentMethodCard';
import BillingDetails from '../../components/subscriptions/BillingDetails';
import Spinner from '../../components/Spinner'; // Import Spinner component
import HelperModal from '../../components/HelperModal';
import { getInventoryItems } from '../../features/inventory/inventorySlice';

const SubscriptionManagementPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    subscription,
    plans,
    isLoading,
    paymentMethod
  } = useSelector((state) => state.subscription);

  const {
    connections,
  } = useSelector((state) => state.connection);

  const {
    items,
  } = useSelector((state) => state.inventory);

  const [selectedTier, setSelectedTier] = useState(null);
  
  // Removed `currentItem` as it's no longer needed
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [downgradeWarning, setDowngradeWarning] = useState(null);

  // Update `currentTier` to use `subscription.plan` instead of `plan`
  const currentTier = subscription && plans
    ? plans.find((planItem) => planItem.name === subscription.plan)
    : null;

  useEffect(() => {
    if (user) {
      dispatch(getSubscription(user._id)); // Fetch user subscription
      const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
      const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();
      dispatch(getPlans(encryptedPassword))
      dispatch(getPaymentMethod());
      dispatch(fetchBillingDetails());
      dispatch(getInventoryItems())
    }
  }, [user, dispatch]);

  // Removed the useEffect that sets `currentItem`

  /**
   * Handle Subscribe button click
   */
  const handleSubscribe = (tier) => {
    if (tier.name === 'Enterprise') {
      navigate('/settings/help', {
        state: {
          name: user.name,
          email: user.email,
          message: 'I am interested in upgrading to the enterprise plan.',
          issue: 'Enterprise Plan Upgrade',
          priority: 'N/A',
          severity: 'N/A',
        },
      });
    } else {
      setSelectedTier(tier);
      dispatch({ type: 'subscription/setSelectedTier', payload: tier });
      navigate('/payment');
    }
  };

  /**
   * Handle Cancel Subscription
   */
  const handleCancelSubscription = () => {
    if (subscription) {
      dispatch(
        cancelSubscription({
          subscriptionId: subscription.stripeSubscriptionId,
        })
      );
      dispatch(clearError());
    }
  };

  /**
   * Handle Upgrade/Downgrade Plan
   */
  const handleUpgradeDowngrade = (tier) => {
    // Check if we have a current tier
    if (currentTier && tier.hierarchy < currentTier.hierarchy) {
      // This is a downgrade scenario
      const unlockedInventoryCount = items.filter((item) => !item.locked).length;
      const unlockedConnectionsCount = connections.filter((conn) => !conn.locked).length;
  
      // Compare against the new tier limits
      const overInventory = Math.max(0, unlockedInventoryCount - tier.inventoryLimit);
      const overConnections = Math.max(0, unlockedConnectionsCount - tier.accountLimit);
  
      if (overInventory > 0 || overConnections > 0) {
        // Set state to show a warning modal
        setDowngradeWarning({
          tier,
          overInventory,
          overConnections
        });
        setIsWarningModalOpen(true);
        return; // Stop here and wait for user confirmation in the modal
      }
    }
  
    // If not downgrading or no over-limit issues, proceed as before
    if (paymentMethod) {
      setSelectedTier(tier);
      setIsHelperModalOpen(true);
    } else {
      // Navigate directly to payment page if no payment method
      setSelectedTier(tier);
      dispatch({ type: 'subscription/setSelectedTier', payload: tier });
      localStorage.setItem('selectedPlan', JSON.stringify(tier));
      navigate('/payment');
    }
  };

  /**
   * Function to toggle auto-renew
   */
  const handleToggleAutoRenew = async (subscriptionId, autoRenew) => {
    try {
      await dispatch(toggleAutoRenew({ subscriptionId, autoRenew })).unwrap();
    } catch (error) {
      console.error('Error toggling auto-renew:', error);
    }
  };

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : (
        <>
          <CurrentSubscriptionCard
            subscription={subscription}
            onCancel={handleCancelSubscription}
            onToggleAutoRenew={handleToggleAutoRenew} // Pass the toggle function
            tiers={plans} // Use fetched plans instead of hardcoded tiers
          />
          <div
            id='subscription-tiers'
            className='grid gap-6 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mt-4'
          >
            {plans.map((plan) => (
              <SubscriptionTierCard
                key={plan.name}
                tier={plan}
                subscription={subscription}
                onSubscribe={() => handleSubscribe(plan)}
                onUpgradeDowngrade={() => handleUpgradeDowngrade(plan)}
                tiers={plans}
                className='hover:shadow-lg hover:scale-105 transition-transform duration-300'
              />
            ))}
          </div>

          <PaymentMethodCard />
          <BillingDetails />
        </>
      )}

      {isWarningModalOpen && (
        <HelperModal
          isOpen={isWarningModalOpen}
          onClose={() => setIsWarningModalOpen(false)}
          title="Warning: Downgrade Limits"
          confirmText="Proceed with Downgrade"
          cancelText="Cancel"
          onConfirm={() => {
            // User confirms the warning, now proceed to payment method check
            setIsWarningModalOpen(false);
            if (paymentMethod) {
              setSelectedTier(downgradeWarning.tier);
              setIsHelperModalOpen(true);
            } else {
              setSelectedTier(downgradeWarning.tier);
              dispatch({ type: 'subscription/setSelectedTier', payload: downgradeWarning.tier });
              localStorage.setItem('selectedPlan', JSON.stringify(downgradeWarning.tier));
              navigate('/payment');
            }
          }}
          onCancel={() => {
            setIsWarningModalOpen(false);
          }}
        >
          <div className="flex flex-col">
            <div className="flex items-center text-red-600 mb-4">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.29 3.86L4.12 9.14C2.4 10.78 2.4 13.53 4.12 15.17l6.17 5.28c1.71 1.64 4.54 1.64 6.25 0l6.17-5.28c1.72-1.64 1.72-4.39 0-6.03L16.54 3.86c-1.74-1.49-4.61-1.49-6.25 0z" />
              </svg>
              <span className="font-bold">Potential Loss of Access</span>
            </div>
            <p>
              By downgrading to the <strong>{downgradeWarning?.tier.name}</strong> tier, you will exceed the allowed limits.
            </p>
            {downgradeWarning?.overInventory > 0 && (
              <p className="mt-2">
                <strong>{downgradeWarning.overInventory}</strong> item(s) from your inventory will be locked.
              </p>
            )}
            {downgradeWarning?.overConnections > 0 && (
              <p className="mt-2">
                <strong>{downgradeWarning.overConnections}</strong> connection(s) from your accounts will be locked.
              </p>
            )}
            <p className="mt-2">Are you sure you want to proceed?</p>
          </div>
        </HelperModal>
      )}

      {isHelperModalOpen && (
        <HelperModal
          isOpen={isHelperModalOpen}
          onClose={() => setIsHelperModalOpen(false)}
          title="Confirm Payment Method"
          confirmText="Use Default Payment"
          cancelText="Use New Payment Method"
          onConfirm={() => {
            dispatch(updateSubscription({
              subscriptionId: subscription.stripeSubscriptionId,
              newPlanId: selectedTier.name,
            }));
            setIsHelperModalOpen(false);
          }}
          onCancel={() => {
            localStorage.setItem('selectedPlan', JSON.stringify(selectedTier));
            navigate('/payment');
          }}
        >
          <p>
            You have a default payment method <strong>{paymentMethod.card.brand.toUpperCase()}</strong> ending in <strong>{paymentMethod.card.last4}</strong>.
            Would you like to use it to upgrade/downgrade to <strong>{selectedTier.name}</strong>, or add a new method? 
            The difference in the subscription cost will be <strong>prorated</strong> and <strong>adjusted at the end of the billing cycle</strong>.
          </p>
        </HelperModal>
      )}
    </div>
  );
};

export default SubscriptionManagementPage;
