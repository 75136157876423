// src/pages/Dashboard/Photography/PhotographyQueue.jsx

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPendingPhotographyItems } from '../../features/inventory/inventorySlice';
import EditPhotographyModal from '../../components/Dashboard/Photography/EditPhotographyModal';
import ItemsTable from '../../components/Dashboard/Photography/ItemsTable';
import PhotographyActions from '../../components/Dashboard/Photography/PhotographyActions';
import MainPagination from '../../components/Dashboard/MainPagination';

const PhotographyQueue = () => {
  const dispatch = useDispatch();
  const { pendingPhotographyItems, loading, error } = useSelector(
    (state) => state.inventory
  );

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Fetch all pending photography items once on mount
  useEffect(() => {
    dispatch(getPendingPhotographyItems());
  }, [dispatch]);

  // Open modal with selected item
  const handlePhotographItem = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  // Close modal
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  // Handle search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Pagination logic
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const filteredItems = searchTerm
    ? pendingPhotographyItems.filter(
      (item) =>
        item.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.item_description.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : pendingPhotographyItems;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="min-h-screen flex flex-col p-4 pt-0">
      <div className="container mx-auto">
        {/* PhotographyActions (includes search) */}
        <div className="top-0 z-20 bg-gray-50 dark:bg-gray-900">
          <PhotographyActions
            value={searchTerm}
            onChange={handleSearchChange}
            pendingRecordsCount={filteredItems.length}
          />
        </div>

        {/* Loading / Error States */}
        {loading && (
          <div className="text-center text-gray-700 dark:text-gray-300">
            Loading items...
          </div>
        )}
        {error && <div className="text-center text-red-500">{error}</div>}

        {/* Items Table */}
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
          <ItemsTable items={currentItems} onPhotographItem={handlePhotographItem} />
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center my-4">
          <div className="text-gray-700 dark:text-gray-300">
            Show
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="mx-2 p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md"
            >
              {[10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
              <option value={filteredItems.length}>All</option>
            </select>
            items per page
          </div>
          <MainPagination
            totalItems={filteredItems.length}
            currentPage={currentPage}
            paginate={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
            itemsPerPage={itemsPerPage}
          />
        </div>

        {/* Edit Photography Modal */}
        {modalOpen && selectedItem && (
          <EditPhotographyModal
            isOpen={modalOpen}
            onClose={handleModalClose}
            item={selectedItem}
          />
        )}
      </div>
    </div>
  );
};

export default PhotographyQueue;
