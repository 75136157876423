import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function generateWords() {
  // Pools of random terms for the heading effect
  const dataWords = [
    'market insights',
    'customer data',
    'inventory signals',
    'sales analytics',
    'revenue metrics',
    'performance stats',
  ];

  const decisionsWords = [
    'strategic moves',
    'profitable expansions',
    'actionable steps',
    'data-driven workflows',
    'smart automations',
    'optimized outcomes',
  ];

  // Random pick from each array
  const randomDataWord = dataWords[Math.floor(Math.random() * dataWords.length)];
  const randomDecisionsWord =
    decisionsWords[Math.floor(Math.random() * decisionsWords.length)];

  return { data: randomDataWord, decisions: randomDecisionsWord };
}

function HeroSection() {
  const [words, setWords] = useState(generateWords());
  const [bouncy, setBouncy] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Rotate words every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setBouncy(true);
      setTimeout(() => {
        setWords(generateWords());
        setTimeout(() => {
          setBouncy(false);
        }, 500);
      }, 2000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Primary CTA
  const handlePrimaryClick = () => {
    // If user is logged in => navigate to a dashboard
    if (user) {
      navigate('/arbie');
    } else {
      // Otherwise => lead to your Beta sign-up or Powersellers page
      navigate('/beta');
    }
  };

  // Secondary CTA (Schedule a Demo)
  // Opens Calendly link in a new tab
  const handleSecondaryClick = () => {
    window.open('https://calendly.com/arbiengine/30min', '_blank', 'noopener,noreferrer');
  };

  return (
    <section
      className="relative w-full mx-auto flex justify-center items-center h-auto overflow-hidden pt-10 sm:pt-12 lg:mb-10"
      aria-label="Hero Section"
    >
      <div className="container mx-auto px-4 max-w-4xl py-10 sm:py-24 lg:py-28 relative z-10">

        {/* Desktop Notice */}
        <div className="hidden md:mb-8 md:flex md:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 dark:text-gray-300 ring-1 ring-gray-900/10 dark:ring-gray-300/10 hover:ring-gray-900/20 dark:hover:ring-gray-300/20">
            Join us on our journey to revolutionize ecommerce.{' '}
            <a
              href="/about-us"
              className="font-semibold text-indigo-600 dark:text-indigo-400 ml-1"
            >
              Learn more about our team <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>

        {/* Heading + Rotating Words */}
        <div className="text-center">
          <h1
            className={`${bouncy ? 'animate-pulse' : ''}
            text-4xl font-bold leading-tight text-gray-900 dark:text-white sm:text-6xl`}
          >
            Where{' '}
            <span className="text-indigo-600 dark:text-indigo-400">
              {words.data}
            </span>{' '}
            drive{' '}
            <span className="text-indigo-600 dark:text-indigo-400">
              {words.decisions}
            </span>
            .
          </h1>

          {/* Blurb */}
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            Reimagine ecommerce from eBay to every major marketplace. Start with us if you’re a Power Seller—grow with us wherever you sell.
          </p>

          {/* CTA Buttons */}
          <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
            {/* Primary Button */}
            <button
              onClick={handlePrimaryClick}
              className="w-full sm:w-auto rounded-full bg-indigo-600 px-8 py-3 text-sm sm:text-base font-semibold text-white shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition-transform transform hover:scale-105"
            >
              {user ? 'Go to Dashboard' : 'Join the Beta'}
            </button>

            {/* Secondary Button: Schedule a Demo */}
            <button
              onClick={handleSecondaryClick}
              className="w-full sm:w-auto rounded-full border-2 border-indigo-600 dark:border-indigo-300 px-8 py-3 text-sm sm:text-base font-semibold text-indigo-600 dark:text-indigo-300 shadow-md hover:bg-indigo-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition-transform transform hover:scale-105"
            >
              Schedule a Demo
            </button>
          </div>
        </div>

        {/* Partnerships */}
        <div className="mt-16">
          <p className="font-display text-center text-gray-900 dark:text-white">
            Pending partnerships with:
          </p>
          <ul
            role="list"
            className="mt-6 flex flex-wrap items-center justify-center gap-8"
          >
            <li>
              <img
                alt="eBay logo"
                loading="lazy"
                width="158"
                height="48"
                className="h-12 w-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/4/48/EBay_logo.png"
              />
            </li>
            <li>
              <img
                alt="Shopify logo"
                loading="lazy"
                width="105"
                height="48"
                className="h-12 w-auto"
                src="https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
