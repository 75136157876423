// inventorySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchInventoryItems,
  fetchInventoryItemById,
  createInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  fetchPendingPhotographyItems,
  uploadPhotographyImages,
  getDefaultCustomFields,
  updateDefaultCustomFields,
  syncInventoryItems,
  getSkuPrefix,
  updateSkuPrefix,
  deleteCustomField,
  fetchInventoryItemCount,
  fetchPendingPhotographyCount,
  unlockInventoryService,
} from './inventoryService';

const defaultItemDetails = {
  _id: null,
  brand: '',
  model: '',
  condition: '',
  quantity: 1,
  item_description: '',
  dimensions: { length: '', width: '', height: '', unit: 'INCH' },
  weight: { value: '', unit: 'POUND' },
  shippingMethods: ['UPSGround'],
  price: 0,
  additional_info: '',
  bestOffer: false,
  acceptsReturns: true,
  autoAcceptPrice: 0,
  autoDeclinePrice: 0,
  category: '',
  listToChannels: [],
  photographyStatus: 'pending',
  recordCompletionStatus: 'draft',
  customFields: [],
  images: [],
  itemCount: 0,
  countLoading: false,
  countError: null,
};

const initialState = {
  items: [],
  displayedItems: [],
  pendingPhotographyItems: [],
  pendingPhotographyCount: 0,
  itemDetails: { ...defaultItemDetails },
  loading: true,
  loadingMore: false,
  error: null,
  page: 1,
  itemsPerPage: 10,
  hasMoreItems: true,
  skuPrefix: 'ARB_',
  updatingSkuPrefix: false,
  skuPrefixError: null,
  defaultCustomFields: [],
  autoFillMissingFields: false,
  autoFillMissingFieldsValue: '',
  updatingCustomFields: false,
  customFieldsError: null,
  missingCustomFields: [],
};

// Fetch initial inventory items
export const getInventoryItems = createAsyncThunk(
  'inventory/getInventoryItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      return await fetchInventoryItems(token);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch inventory items';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInventoryItemCount = createAsyncThunk(
  'inventory/getInventoryItemCount',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const count = await fetchInventoryItemCount(token);
      return count;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || 'Failed to fetch inventory item count';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch more inventory items from local state
export const getMoreInventoryItems = createAsyncThunk(
  'inventory/getMoreInventoryItems',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().inventory;
      const startIndex = state.page * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      const moreItems = state.items.slice(startIndex, endIndex);

      return moreItems;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch individual inventory item by ID
export const getInventoryItem = createAsyncThunk(
  'inventory/getInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const data = await fetchInventoryItemById(token, id);

      // Extract default custom fields and preferences
      const {
        defaultCustomFields = [],
        autoFillMissingFields = false,
        autoFillMissingFieldsValue = 'N/A',
        ...inventoryData
      } = data;

      return {
        inventoryData, // Contains the actual inventory item details
        defaultCustomFields,
        autoFillMissingFields,
        autoFillMissingFieldsValue,
      };
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addInventoryItem = createAsyncThunk(
  'inventory/addInventoryItem',
  async (inventoryData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const response = await createInventoryItem(token, inventoryData);
      return response; // Backend returns {status, message, data: {...}}
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to add inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update an existing inventory item
export const updateItem = createAsyncThunk(
  'inventory/updateItem',
  async ({ id, inventoryData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const response = await updateInventoryItem(token, id, inventoryData);
      return response; // Backend returns {status, message, data: {...}} on success
    } catch (error) {
      const { response: res } = error;
      let message = 'Failed to update inventory item';
      let errorCode = null;
      let missingFields = [];
      let context = null;

      if (res && res.data) {
        const data = res.data;
        message = data.message || message;

        if (data.error) {
          const errorData = data.error;
          message = errorData.message || message;
          errorCode = errorData.errorCode || null;
          missingFields = errorData.missingFields || [];
          context = errorData.context || null;
        }
      } else if (error.message) {
        message = error.message;
      }

      return thunkAPI.rejectWithValue({ message, errorCode, missingFields, context });
    }
  }
);

// Delete an inventory item
export const removeInventoryItem = createAsyncThunk(
  'inventory/removeInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      await deleteInventoryItem(token, id);
      return id;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to delete inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch pending photography items
export const getPendingPhotographyItems = createAsyncThunk(
  'inventory/getPendingPhotographyItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      return await fetchPendingPhotographyItems(token);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch pending photography items';
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// Upload edited images and refresh both inventory and pending photography lists
export const uploadEditedImagesForItem = createAsyncThunk(
  'inventory/uploadEditedImagesForItem',
  async ({ inventoryItemId, formData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }

      const response = await uploadPhotographyImages(token, inventoryItemId, formData);

      // Also fetch updated inventory & pending photography items
      const [refreshedItems, pendingPhotographyItems] = await Promise.all([
        fetchInventoryItems(token),
        fetchPendingPhotographyItems(token),
      ]);

      // Return everything in a single object
      return {
        inventoryItemId,
        refreshedItems,
        pendingPhotographyItems,
        ...response, // Spread out status, message, results, etc.
      };
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to upload edited images';
      return thunkAPI.rejectWithValue({ inventoryItemId, message });
    }
  }
);


// Sync inventory items
export const syncInventory = createAsyncThunk(
  'inventory/syncInventory',
  async ({ id, platform, countOnly = false }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const user = thunkAPI.getState().auth.user;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      return await syncInventoryItems(token, id, platform, user, countOnly);
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || 'Failed to sync inventory items';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch default custom fields
export const fetchDefaultCustomFields = createAsyncThunk(
  'inventory/fetchDefaultCustomFields',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const data = await getDefaultCustomFields(token);
      return data;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch default custom fields';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update default custom fields
export const updateUserDefaultCustomFields = createAsyncThunk(
  'inventory/updateUserDefaultCustomFields',
  async (customFieldSettings, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const data = await updateDefaultCustomFields(token, customFieldSettings);
      return data;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to update default custom fields';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch SKU prefix
export const fetchSkuPrefix = createAsyncThunk(
  'inventory/fetchSkuPrefix',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const data = await getSkuPrefix(token);
      return data.skuPrefix;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch SKU prefix';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update SKU prefix
export const updateUserSkuPrefix = createAsyncThunk(
  'inventory/updateUserSkuPrefix',
  async (newPrefix, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const data = await updateSkuPrefix(token, newPrefix);
      return data.skuPrefix;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to update SKU prefix';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete custom field
export const removeCustomField = createAsyncThunk(
  'inventory/removeCustomField',
  async (fieldId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      await deleteCustomField(token, fieldId);
      return fieldId; // Return the ID to remove it from the Redux state
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to delete custom field';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPendingPhotographyCount = createAsyncThunk(
  'inventory/getPendingPhotographyCount',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) {
        throw new Error('Authentication token is missing');
      }
      const count = await fetchPendingPhotographyCount(token);
      return count;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        'Failed to fetch pending photography count';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const unlockInventoryItem = createAsyncThunk(
  'inventory/unlockInventoryItem',
  async (itemId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      if (!token) throw new Error('Authentication token is missing');
      
      const response = await unlockInventoryService(token, itemId);
      return response; // Expect the backend to return the updated item
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || 'Failed to unlock item.';
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null; // Clear the error
    },
    clearItemDetails: (state) => {
      state.itemDetails = { ...defaultItemDetails };
      state.missingCustomFields = []; // Reset missing custom fields
    },
    updateItemCustomFields: (state, action) => {
      state.itemDetails.customFields = action.payload;
    },
    updateItemCustomFieldValue: (state, action) => {
      const { fieldName, platform, value } = action.payload;
      const existingFieldIndex = state.itemDetails.customFields.findIndex(
        (field) => field.fieldName === fieldName && field.platform === platform
      );

      if (existingFieldIndex !== -1) {
        // Update existing field value
        state.itemDetails.customFields[existingFieldIndex].value = value;
      } else {
        // Add new field
        state.itemDetails.customFields.push({
          fieldName,
          value,
          isRequired: false, // Adjust as necessary
          platform: platform || 'General', // Adjust as necessary
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Get inventory items and populate displayed items for the first page
      .addCase(getInventoryItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.page = 1;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.items = [];
      })
      // Fetch more items from the local state
      .addCase(getMoreInventoryItems.pending, (state) => {
        state.loadingMore = true;
        state.error = null;
      })
      .addCase(getMoreInventoryItems.fulfilled, (state, action) => {
        state.displayedItems = [...state.displayedItems, ...action.payload];
        state.page += 1;
        state.loadingMore = false;
        state.hasMoreItems = action.payload.length === state.itemsPerPage;
      })
      .addCase(getMoreInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingMore = false;
      })
      // Fetch individual inventory item by ID
      .addCase(getInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryItem.fulfilled, (state, action) => {
        const inventoryData = action.payload.inventoryData;
        const mergedData = {
          ...defaultItemDetails,
          ...inventoryData,
          dimensions: { ...defaultItemDetails.dimensions, ...inventoryData.dimensions },
          weight: { ...defaultItemDetails.weight, ...inventoryData.weight },
          customFields: inventoryData.customFields || [],
        };
        state.itemDetails = mergedData;
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.missingCustomFields = [];
        state.loading = false;
        state.error = null;
      })
      .addCase(getInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Add new inventory item and refresh list
      .addCase(addInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addInventoryItem.fulfilled, (state, action) => {
        const newItem = action.payload.data; // only use data
        const mergedData = {
          ...defaultItemDetails,
          ...newItem,
          dimensions: { ...defaultItemDetails.dimensions, ...newItem.dimensions },
          weight: { ...defaultItemDetails.weight, ...newItem.weight },
          customFields: newItem.customFields || [],
        };
        state.itemDetails = mergedData;
        state.loading = false;
      })
      .addCase(addInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateItem.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.customFieldsError = null;
        state.missingCustomFields = [];
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        const updatedItem = action.payload.data; // only use data
        const mergedData = {
          ...defaultItemDetails,
          ...updatedItem,
          dimensions: { ...defaultItemDetails.dimensions, ...updatedItem.dimensions },
          weight: { ...defaultItemDetails.weight, ...updatedItem.weight },
          customFields: updatedItem.customFields || [],
        };

        // Only mergedData is assigned to itemDetails, not the entire payload
        state.itemDetails = mergedData;
        state.missingCustomFields = [];
        state.loading = false;
        state.error = null;
      })
      .addCase(updateItem.rejected, (state, action) => {
        const { message, missingFields } = action.payload || {};

        state.error = message || 'Failed to update inventory item.';
        state.loading = false;

        if (missingFields && missingFields.length > 0) {
          const existingFieldIdentifiers = new Set([
            ...state.defaultCustomFields.map(
              (field) => `${field.fieldName}_${field.platform || 'General'}`
            ),
            ...state.itemDetails.customFields.map(
              (field) => `${field.fieldName}_${field.platform || 'General'}`
            ),
          ]);

          const uniqueMissingFields = missingFields.filter((field) => {
            const identifier = `${field.fieldName}_${field.platform || 'General'}`;
            return !existingFieldIdentifiers.has(identifier);
          });

          state.missingCustomFields = uniqueMissingFields.map((field) => ({
            fieldName: field.fieldName,
            platform: field.platform || 'General',
            isRequired: field.isRequired !== undefined ? field.isRequired : true,
            value: '',
            source: 'missing',
          }));
        }
      })
      // Delete inventory item and refresh displayed items
      .addCase(removeInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeInventoryItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item._id !== action.payload);
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(removeInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch pending photography items
      .addCase(getPendingPhotographyItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPendingPhotographyItems.fulfilled, (state, action) => {
        state.pendingPhotographyItems = action.payload;
        state.loading = false;
      })
      .addCase(getPendingPhotographyItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Upload edited images, refresh both inventory and pending photography lists
      .addCase(uploadEditedImagesForItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadEditedImagesForItem.fulfilled, (state, action) => {
        const { inventoryItemId, refreshedItems, pendingPhotographyItems } = action.payload;

        // Update the Redux store with refreshed items
        state.items = refreshedItems;
        state.pendingPhotographyItems = pendingPhotographyItems;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.hasMoreItems = state.items.length > state.itemsPerPage;

        // If the updated item is in refreshedItems, set it as the current itemDetails
        const updatedItem = refreshedItems.find((item) => item._id === inventoryItemId);
        if (updatedItem) {
          const mergedData = {
            ...defaultItemDetails,
            ...updatedItem,
            dimensions: { ...defaultItemDetails.dimensions, ...updatedItem.dimensions },
            weight: { ...defaultItemDetails.weight, ...updatedItem.weight },
            customFields: updatedItem.customFields || [],
          };
          state.itemDetails = mergedData;
        }

        // No storing of success messages in Redux—handled in the modal
        state.loading = false;
      })
      .addCase(uploadEditedImagesForItem.rejected, (state, action) => {
        // On failure, store an error to Redux if you wish
        const { message } = action.payload;
        state.error = message || 'Failed to upload edited images';
        state.loading = false;
      })
      // Sync inventory items
      .addCase(syncInventory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(syncInventory.fulfilled, (state, action) => {
        state.loading = false;
        state.migratedListings = action.payload.migrationResults;
      })
      .addCase(syncInventory.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handle SKU Prefix Fetching
      .addCase(fetchSkuPrefix.pending, (state) => {
        state.loading = true;
        state.skuPrefixError = null;
      })
      .addCase(fetchSkuPrefix.fulfilled, (state, action) => {
        state.skuPrefix = action.payload;
        state.loading = false;
      })
      .addCase(fetchSkuPrefix.rejected, (state, action) => {
        state.skuPrefixError = action.payload;
        state.loading = false;
      })
      // Handle SKU Prefix Updating
      .addCase(updateUserSkuPrefix.pending, (state) => {
        state.updatingSkuPrefix = true;
        state.skuPrefixError = null;
      })
      .addCase(updateUserSkuPrefix.fulfilled, (state, action) => {
        state.skuPrefix = action.payload;
        state.updatingSkuPrefix = false;
      })
      .addCase(updateUserSkuPrefix.rejected, (state, action) => {
        state.skuPrefixError = action.payload;
        state.updatingSkuPrefix = false;
      })
      // Custom Fields Handlers
      .addCase(fetchDefaultCustomFields.pending, (state) => {
        state.loading = true;
        state.customFieldsError = null;
      })
      .addCase(fetchDefaultCustomFields.fulfilled, (state, action) => {
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.loading = false;
      })
      .addCase(fetchDefaultCustomFields.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.loading = false;
      })
      .addCase(updateUserDefaultCustomFields.pending, (state) => {
        state.updatingCustomFields = true;
        state.customFieldsError = null;
      })
      .addCase(updateUserDefaultCustomFields.fulfilled, (state, action) => {
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.updatingCustomFields = false;
      })
      .addCase(updateUserDefaultCustomFields.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.updatingCustomFields = false;
      })
      // Handle removing a custom field by ID
      .addCase(removeCustomField.pending, (state) => {
        state.loading = true;
        state.customFieldsError = null;
      })
      .addCase(removeCustomField.fulfilled, (state, action) => {
        state.defaultCustomFields = state.defaultCustomFields.filter(
          (field) => field._id !== action.payload
        );
        state.loading = false;
      })
      .addCase(removeCustomField.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.loading = false;
      })
      .addCase(getInventoryItemCount.pending, (state) => {
        state.countLoading = true;
        state.countError = null;
      })
      .addCase(getInventoryItemCount.fulfilled, (state, action) => {
        state.itemCount = action.payload;
        state.countLoading = false;
      })
      .addCase(getInventoryItemCount.rejected, (state, action) => {
        state.countError = action.payload;
        state.countLoading = false;
      })
      .addCase(getPendingPhotographyCount.pending, (state) => {
        state.countLoading = true;
        state.countError = null;
      })
      .addCase(getPendingPhotographyCount.fulfilled, (state, action) => {
        state.pendingPhotographyCount = action.payload;
        state.countLoading = false;
      })
      .addCase(getPendingPhotographyCount.rejected, (state, action) => {
        state.countError = action.payload;
        state.countLoading = false;
      })
      .addCase(unlockInventoryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(unlockInventoryItem.fulfilled, (state, action) => {
        const unlockedItem = action.payload.item;
        const index = state.items.findIndex((item) => item._id === unlockedItem._id);
        if (index !== -1) {
          state.items[index] = unlockedItem; // Update the unlocked item
        }
        state.loading = false;
      })
      .addCase(unlockInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// Export actions
export const {
  clearItemDetails,
  updateItemCustomFields,
  updateItemCustomFieldValue,
  clearError,
} = inventorySlice.actions;

// Export the reducer
export default inventorySlice.reducer;
