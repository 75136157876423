import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  signInToPlatform,
  checkPlatformToken,
  updateUserConnectionDocument,
  deleteConnection,
  getConnectionStatus,
} from './connectionService'

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  connections: JSON.parse(localStorage.getItem('connections')) || [],
}

export const fetchConnections = createAsyncThunk(
  'connection/fetchConnections',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token
      const connections = await getConnectionStatus(token)
      return connections.connectionStatuses
    } catch (error) {
      const message = error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const signIn = createAsyncThunk(
  'connection/signIn',
  async (
    { platform, accountType, accountName, connectionId = null },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.token
      await signInToPlatform(
        token,
        platform,
        accountType,
        accountName,
        connectionId
      )
    } catch (error) {
      const message =
        error.response?.data?.message || // Backend-provided message
        error.response?.data?.error ||  // Alternative key for error
        error.message ||                // Default error message
        'Something went wrong';         // Fallback message
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const checkToken = createAsyncThunk(
  'connection/checkToken',
  async ({ platform, accountType, accountName }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token
      const userData = await checkPlatformToken(
        token,
        platform,
        accountType,
        accountName
      )

      thunkAPI.dispatch(
        connectionSlice.actions.updateConnectionStatus(userData)
      )
      return userData
    } catch (error) {
      const message = error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Thunk for updating connection document directly
export const updateUserConnection = createAsyncThunk(
  'connection/updateUserConnection',
  async ({ connectionId, newAccountName, listToByDefault, unlock = false }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await updateUserConnectionDocument(
        token,
        connectionId,
        newAccountName,
        listToByDefault,
        unlock // Pass the unlock flag to the service
      );
      return { connectionId, newAccountName, listToByDefault, unlock };
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteConnectionThunk = createAsyncThunk(
  'connection/deleteConnection',
  async ({ connectionId, platform }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token
      await deleteConnection(token, connectionId, platform)
      return { connectionId, platform }
    } catch (error) {
      const message = error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const updateLocalStorageConnections = (connections) => {
  localStorage.setItem('connections', JSON.stringify(connections))
}

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateConnectionStatus: (state, action) => {
      const {
        id,
        platform,
        refreshTokenPresent,
        requestCount,
        accessTokenExpiresAt,
        refreshTokenExpiresAt,
      } = action.payload
      const connection = state.connections.find(
        (conn) => conn.id === id && conn.platform === platform
      )
      if (connection) {
        connection.refreshToken = refreshTokenPresent
        connection.requestCount = requestCount
        connection.accessTokenExpiresAt = accessTokenExpiresAt
        connection.refreshTokenExpiresAt = refreshTokenExpiresAt
      }
    },
    removeConnection: (state, action) => {
      const { connectionId, platform } = action.payload
      state.connections = state.connections.filter(
        (conn) => !(conn.id === connectionId && conn.platform === platform)
      )
      updateLocalStorageConnections(state.connections)
    },
    updateConnectionNameAction: (state, action) => {
      const { connectionId, newAccountName } = action.payload
      const connection = state.connections.find(
        (conn) => conn.id === connectionId
      )
      if (connection) {
        connection.accountName = newAccountName
      }
      updateLocalStorageConnections(state.connections)
    },
    setConnections: (state, action) => {
      state.connections = action.payload
      updateLocalStorageConnections(state.connections)
    },
    clearConnections: (state) => {
      state.connections = []
      localStorage.removeItem('connections')
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnections.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.connections = action.payload
        updateLocalStorageConnections(state.connections)
      })
      .addCase(fetchConnections.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(signIn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signIn.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        console.log(action.payload)
        state.message = action.payload
      })
      .addCase(checkToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(checkToken.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateUserConnection.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserConnection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.connections = state.connections.map((conn) =>
          conn._id === action.payload.connectionId
            ? {
                ...conn,
                accountName: action.payload.newAccountName || conn.accountName,
                listToByDefault:
                  typeof action.payload.listToByDefault === 'boolean'
                    ? action.payload.listToByDefault
                    : conn.listToByDefault,
                locked: action.payload.unlock ? false : conn.locked, // Handle unlock
              }
            : conn
        );
        updateLocalStorageConnections(state.connections);
      })
      .addCase(updateUserConnection.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteConnectionThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteConnectionThunk.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.connections = state.connections.filter(
          (conn) =>
            !(
              conn._id === action.payload.connectionId &&
              conn.platform === action.payload.platform
            )
        )
        updateLocalStorageConnections(state.connections)
      })
      .addCase(deleteConnectionThunk.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const {
  reset,
  updateConnectionStatus,
  removeConnection,
  setConnections,
  clearConnections,
} = connectionSlice.actions

export default connectionSlice.reducer
