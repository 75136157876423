import React, { useState, useEffect } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import InventoryLight from "../../images/Inventory_Management_Light.png";
import InventoryDark from "../../images/Inventory_Management_Dark.png";

const ValueAddTabs = () => {
  const [selectedTab, setSelectedTab] = useState("Inventory Management");
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  );

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme);
    };

    window.addEventListener("themeChange", handleThemeChange);

    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  const tabs = [
    {
      name: "Inventory Management",
      description:
        "Manage your inventory effortlessly with dynamic templates and real-time updates.",
      image: theme === "dark" ? InventoryDark : InventoryLight,
    },
    {
      name: "Listing Workflows",
      description: "Simplify your listings with dynamic templates and bulk upload tools.",
      locked: true,
    },
    {
      name: "Order Fulfillment",
      description: "Pick, pack, and ship orders from a centralized dashboard.",
      locked: true,
    },
    {
      name: "Multi-Account Management",
      description: "Manage multiple eBay stores seamlessly in one place.",
      locked: true,
    },
    {
      name: "Dynamic Descriptions",
      description: "Generate professional descriptions and apply them en masse.",
      locked: true,
    },
  ];

  const handleTabClick = (tab) => {
    if (!tab.locked) {
      setSelectedTab(tab.name);
    }
  };

  return (
    <section
      id="value-add-tabs"
      aria-label="Value Adds for Power Sellers"
      className="relative overflow-hidden bg-gradient-to-b from-indigo-100 via-white to-indigo-50 dark:from-indigo-800 dark:via-gray-800 dark:to-indigo-900 pb-28 pt-20 sm:py-20"
    >
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-gray-900 dark:text-white sm:text-4xl md:text-5xl">
            Explore Our Value Add
          </h2>
          <p className="mt-6 text-lg tracking-tight text-gray-700 dark:text-gray-300">
            Unlock powerful tools designed to simplify and scale your eBay selling experience.
          </p>
        </div>
        <div className="mt-16 md:mt-20 lg:grid lg:grid-cols-12 items-center">
          {/* Tabs */}
          <div className="lg:col-span-5">
            <div
              className="relative z-10 flex lg:block lg:gap-y-2 whitespace-nowrap overflow-x-auto custom-scrollbar gap-4 px-4 sm:px-0 lg:whitespace-normal"
              role="tablist"
              aria-orientation="horizontal"
            >
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  className={`group relative flex-shrink-0 rounded-full px-4 py-2 lg:rounded-l-xl lg:rounded-r-none lg:p-6 ${selectedTab === tab.name
                      ? "border bg-white text-indigo-600 dark:bg-indigo-600 dark:text-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                      : "hover:bg-white/10 lg:hover:bg-white/5"
                    }`}
                  onClick={() => handleTabClick(tab)}
                >
                  <h3>
                    <button
                      className={`flex items-center text-lg font-semibold ui-not-focus-visible:outline-none ${selectedTab === tab.name
                          ? "text-indigo-600 dark:text-white"
                          : "text-gray-700 dark:text-gray-300 hover:text-indigo-600"
                        }`}
                      aria-selected={selectedTab === tab.name}
                      role="tab"
                      type="button"
                      disabled={tab.locked}
                    >
                      {tab.name}
                      {tab.locked && (
                        <LockClosedIcon className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-300" />
                      )}
                    </button>
                  </h3>
                  <p
                    className={`mt-2 hidden text-sm lg:block ${selectedTab === tab.name
                        ? "text-gray-900 dark:text-white"
                        : "text-gray-700 dark:text-gray-400 group-hover:text-gray-600"
                      }`}
                  >
                    {tab.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Tab Content */}
          <div className="lg:col-span-7 lg:col-start-6">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                id={`tab-content-${tab.name}`}
                role="tabpanel"
                aria-labelledby={`tab-${tab.name}`}
                hidden={selectedTab !== tab.name}
              >
                {tab.image && (
                  <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-indigo-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem] dark:bg-gray-800">
                    <img
                      alt={tab.name}
                      className="w-full object-cover border rounded-xl"
                      src={tab.image}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Scrollbar Styling */}
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            height: 4px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: ${theme === "dark" ? "rgba(31, 41, 55, 0.7)" : "rgba(229, 231, 235, 0.7)"
          };
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background: ${theme === "dark" ? "rgba(75, 85, 99, 1)" : "rgba(107, 114, 128, 1)"
          };
            border-radius: 10px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: ${theme === "dark" ? "rgba(156, 163, 175, 1)" : "rgba(156, 163, 175, 1)"
          };
          }
        `}
      </style>
    </section>
  );
};

export default ValueAddTabs;
