import React from 'react'
import { useSelector } from 'react-redux'
import { TrendingUpIcon, TrendingDownIcon } from '@heroicons/react/solid'
import { MinusIcon } from '@heroicons/react/outline';
import {
  ArchiveIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  RefreshIcon,
} from '@heroicons/react/outline'

const formatNumber = (value) => {
  if (!isNaN(value)) {
    return new Intl.NumberFormat('en-US').format(value)
  }
  return value
}

const MetricCard = ({ icon: Icon, title, value, trend, timeframe = '' }) => {
  let TrendIcon;
  let trendColor;

  // Determine the trend icon and color based on the trend value
  if (trend === '0.00%' || trend === '0%') {
    TrendIcon = MinusIcon; // Flat line icon
    trendColor = 'text-orange-500'; // Orange color for no change
  } else if (trend && trend.startsWith('-')) {
    TrendIcon = TrendingDownIcon;
    trendColor = 'text-red-600'; // Red for negative trend
  } else {
    TrendIcon = TrendingUpIcon;
    trendColor = 'text-green-600'; // Green for positive trend
  }

  const formattedValue = formatNumber(value);
  const trendText = trend ? `${trend} change in the last ${timeframe}` : 'N/A';

  return (
    <div className="flex flex-col justify-between bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
      <div className="flex items-center mb-4">
        <div className="flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 dark:bg-indigo-600 text-indigo-600 dark:text-indigo-100">
          <Icon className="h-6 w-6" />
        </div>
        <div className="ml-4 flex-1">
          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
            {title}
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">{trendText}</p>
        </div>
      </div>
      <div className="flex items-baseline">
        <span className="text-3xl font-bold text-gray-900 dark:text-white">
          {formattedValue}
        </span>
        <span className={`ml-2 inline-flex items-center ${trendColor}`}>
          <TrendIcon className="ml-1 w-5 h-5" />
          <span className="ml-1 text-sm">{trend}</span>
        </span>
      </div>
    </div>
  );
};

const SummaryMetrics = () => {
  const {
    comprehensiveInventoryMetrics,
  } = useSelector((state) => state.metrics)

  const {
    inventory,
    revenue,
    purchases,
    returns,
  } = comprehensiveInventoryMetrics || {};

  return (
    <div className=' grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4'>
      <MetricCard
        icon={ArchiveIcon}
        title="Total Inventory Value"
        value={inventory?.totalValue || 0}
        trend={inventory?.growthPercent || '0%'}
        timeframe="30 days"
      />
      <MetricCard
        icon={CurrencyDollarIcon}
        title="Total Revenue"
        value={revenue?.totalRevenue || 0}
        trend={revenue?.growthPercent || '0%'}
        timeframe="30 days"
      />
      <MetricCard
        icon={ShoppingCartIcon}
        title="Total Purchase"
        value={purchases?.totalPurchases || 0}
        trend={purchases?.growthPercent || '0%'}
        timeframe="30 days"
      />
      <MetricCard
        icon={RefreshIcon}
        title="Total Returns"
        value={returns?.totalReturns || 0}
        trend={returns?.growthPercent || '0%'}
        timeframe="30 days"
      />
    </div>
  )
}

export default SummaryMetrics
