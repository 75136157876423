// src/components/OrderFulfilment/FilterBar.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilter, fetchOrders } from '../../features/orders/ordersSlice';

const FilterBar = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(setFilter({ searchQuery }));
    dispatch(fetchOrders());
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const applyDateFilter = () => {
    dispatch(setFilter({ dateRange }));
    dispatch(fetchOrders());
  };

  const clearFilters = () => {
    setSearchQuery('');
    setDateRange({ start: '', end: '' });
    dispatch(setFilter({ searchQuery: '', dateRange: { start: null, end: null } }));
    dispatch(fetchOrders());
  };

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
      {/* Search Bar */}
      <form onSubmit={handleSearch} className="flex flex-wrap items-center gap-2">
        <input
          type="text"
          placeholder="Search by Order ID, Buyer, SKU..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-gray-100 transition"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-500 dark:bg-indigo-600 text-white rounded-md hover:bg-indigo-600 dark:hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition"
        >
          Search
        </button>
      </form>

      {/* Date Range Filters */}
      <div className="flex items-center gap-2">
        <input
          type="date"
          name="start"
          value={dateRange.start}
          onChange={handleDateChange}
          className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-gray-100 transition"
        />
        <span className="text-gray-700 dark:text-gray-300">to</span>
        <input
          type="date"
          name="end"
          value={dateRange.end}
          onChange={handleDateChange}
          className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-gray-100 transition"
        />
        <button
          onClick={applyDateFilter}
          className="px-4 py-2 bg-green-500 dark:bg-green-600 text-white rounded-md hover:bg-green-600 dark:hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 transition"
        >
          Apply
        </button>
      </div>

      {/* Clear Filters Button */}
      <button
        onClick={clearFilters}
        className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-400 transition"
      >
        Clear Filters
      </button>
    </div>
  );
};

export default FilterBar;
