import React from "react";
import PowerSellersTeaserDesktop from "./PowerSellersTeaserDesktop";
import PowerSellersTeaserMobile from "./PowerSellersTeaserMobile";

const PowerSellersTeaser = () => {
  return (
    <>
      {/* Desktop Layout */}
      <div className="hidden md:block">
        <PowerSellersTeaserDesktop />
      </div>

      {/* Mobile Layout */}
      <div className="block md:hidden">
        <PowerSellersTeaserMobile />
      </div>
    </>
  );
};

export default PowerSellersTeaser;
