import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';

import HeroSection from '../components/HomePage/HeroSection';
import SecondaryFeatures from '../components/HomePage/SecondaryFeatures';
import PricingSection from '../components/HomePage/PricingSection';
import PowerSellersTeaser from '../components/PowerSellers/PowerSellersTeaser';

function Home() {
  return (
    <>
      <Helmet>

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>ArbiEngine – Reimagining ecommerce for Power Sellers & Beyond</title>

        <meta
          name="description"
          content="ArbiEngine is transforming ecommerce from eBay Power Sellers to entrepreneurs everywhere. Join our Beta to streamline listings, optimize workflows, and prepare for our Q2 2025 multi-vertical launch."
        />
        <meta
          name="keywords"
          content="ecommerce, arbitrage, power sellers, listing optimization, multi-marketplace, eBay, fulfillment, ERP alternative, ERP, PowerApps"
        />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://arbiengine.com" />
        <meta name="author" content="ArbiEngine" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "ArbiEngine – Reimagining ecommerce for Power Sellers & Beyond",
            description:
              "ArbiEngine is transforming ecommerce from eBay Power Sellers to entrepreneurs everywhere. Join our Beta to streamline listings, optimize workflows, and prepare for our Q2 2025 multi-vertical launch.",
            url: "https://arbiengine.com",
            mainEntity: {
              "@type": "Organization",
              name: "ArbiEngine",
              logo: "https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png",
              sameAs: [
                "https://x.com/ArbiEngine",
                "https://www.instagram.com/arbiengine",
                "https://www.linkedin.com/company/arbiengine",
              ],
            },
          })}
        </script>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="ArbiEngine – Reimagining ecommerce for Power Sellers & Beyond"
        />
        <meta
          property="og:description"
          content="ArbiEngine is transforming ecommerce from eBay Power Sellers to entrepreneurs everywhere. Unlock next-gen listing flows and AI-powered insights."
        />
        <meta
          property="og:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
        <meta property="og:url" content="https://arbiengine.com" />
        <meta property="og:site_name" content="ArbiEngine" />
        

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ArbiEngine – Reimagining ecommerce for Power Sellers & Beyond"
        />
        <meta
          name="twitter:description"
          content="Join ArbiEngine's Beta to streamline eBay listings, gain AI-powered insights, and prepare for our Q2 2025 full ecommerce suite launch."
        />
        <meta
          name="twitter:image"
          content="https://arbiengine.com/static/media/arbieBlackNew.ce6d96f84ac572df9af1.png"
        />
      </Helmet>


      {/* Page Layout */}
      <div className="relative min-h-screen overflow-hidden bg-gray-100 dark:bg-gray-900">
        {/* Gradients */}
        <div
          className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500 to-pink-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] z-0"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] z-0"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/4 bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 sm:left-[calc(50%-66rem)] sm:w-[62.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-0 transform-gpu overflow-hidden blur-3xl sm:top-60 z-0"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(25%-6rem)] aspect-[4200/1000] w-[76.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 md:opacity-80 sm:left-[calc(25%-20rem)] sm:w-[76rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-52 transform-gpu overflow-hidden blur-3xl  z-0"
          aria-hidden="true"
        >
          <div
            className="relative aspect-[4200/1000] w-[36.125rem] translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-80 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-1/3 transform-gpu overflow-hidden blur-3xl  z-0"
          aria-hidden="true"
        >
          <div
            className="relative aspect-[3200/1000] w-[70.125rem] translate-x-1/2 rotate-[120deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-80 sm:left-[calc(60%-30rem)] sm:w-[62.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div
          className="absolute inset-x-0 top-1/3 transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500 to-pink-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <Header hasBorder={false} />

        {/* Main Hero Section */}
        <HeroSection />

        {/* 
          NEW CTA that calls to PowerSellers Landing Page
          Import from ../components/PowerSellers/PowerSellersTeaser.jsx
        */}
        <PowerSellersTeaser />

        {/* Additional Sections */}
        <SecondaryFeatures />
        <PricingSection />
    

        <Footer />
      </div>
    </>
  );
}

export default Home;
