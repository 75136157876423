import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { TrashIcon } from '@heroicons/react/solid';
import HelperModal from '../HelperModal';
import visaLogo from '../../images/cards/visa.png';
import mastercardLogo from '../../images/cards/mastercard.png';
import americanExpressLogo from '../../images/cards/americanexpress.png';
import paypalLogo from '../../images/cards/paypal.png';
import { deletePaymentMethod, addPaymentMethod } from '../../features/subscriptions/subscriptionSlice';
import { PlusIcon } from '@heroicons/react/outline';
import { countryOptions, stateOptionsUS, stateOptionsCA } from '../../pages/SubscriptionManagement/optionsHelper';


const PaymentMethodCard = () => {
  const dispatch = useDispatch();
  const { paymentMethod } = useSelector((state) => state.subscription);
  const stripe = useStripe();
  const elements = useElements();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    country: 'US', // Default to US
    postalCode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements) return;
  
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
  
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: billingDetails.name,
          address: {
            line1: billingDetails.address,
            city: billingDetails.city,
            state: billingDetails.state,
            country: billingDetails.country,
            postal_code: billingDetails.postalCode,
          },
        },
      });
  
      if (error) {
        console.error('Payment Method creation failed:', error);
      } else {
        await dispatch(
          addPaymentMethod({
            paymentMethodId: paymentMethod.id,
            billingDetails,
          })
        );
        setModalOpen(false);
      }
    } catch (error) {
      console.error('Error adding payment method:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Mapping card brands to their respective logos
  const cardBrandLogos = {
    visa: visaLogo,
    mastercard: mastercardLogo,
    americanexpress: americanExpressLogo,
    paypal: paypalLogo,
  };

  if (!paymentMethod) {
    return (
      <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-4">Payment Method</h2>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            className="flex items-center px-4 py-2 border-4 border-gray-300 text-gray-700 dark:text-white rounded-lg shadow-sm hover:border-gray-500 hover:text-gray-900 dark:hover:text-gray-100 focus:ring-2 focus:ring-blue-400 focus:outline-none"
            aria-label="Add Payment Method"
          >
            <PlusIcon className="h-5 w-5 mr-2 text-gray-700 dark:text-white" />
            <span className="font-semibold text-sm tracking-wide uppercase">Add New Card</span>
          </button>
        </div>
        <HelperModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          title="Add Payment Method"
          confirmText="Save Card"
          onConfirm={handleAddPaymentMethod}
          isLoading={isLoading}
        >
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                value={billingDetails.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                placeholder="Full Name"
              />
            </div>
            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Address
              </label>
              <input
                id="address"
                name="address"
                type="text"
                required
                value={billingDetails.address}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                placeholder="Street Address"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  required
                  value={billingDetails.city}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                  placeholder="City"
                />
              </div>
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  State
                </label>
                <select
                  id="state"
                  name="state"
                  required
                  value={billingDetails.state}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                >
                  <option value="">Select State</option>
                  {billingDetails.country === 'US' &&
                    stateOptionsUS.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  {billingDetails.country === 'CA' &&
                    stateOptionsCA.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  required
                  value={billingDetails.country}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                >
                  <option value="">Select Country</option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Postal Code
                </label>
                <input
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  required
                  value={billingDetails.postalCode}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:text-gray-100"
                  placeholder="Postal Code"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Card Details
              </label>
              <div className="p-3 border rounded bg-gray-50 dark:bg-gray-800">
                <CardElement className="p-3 dark:text-white" />
              </div>
            </div>
          </form>
        </HelperModal>
      </div>
    );
  }

  const { card } = paymentMethod;
  const cardLogo = cardBrandLogos[card.brand.toLowerCase()] || '';

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await dispatch(deletePaymentMethod())
      setModalOpen(false);
    } catch (error) {
      console.error('Failed to delete payment method:', error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-4">Payment Method</h2>
        <p className="text-gray-500 dark:text-gray-300 mb-4">
          Manage your preferred payment method securely and conveniently.
        </p>
        <div className="flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md max-w-sm">
          <div className="flex items-center">
            <img src={cardLogo} alt={card.brand} className="w-10 h-10 mr-4" />
            <div>
              <p className="font-semibold text-gray-700 dark:text-white">
                **** **** **** {card.last4}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-300">
                Expires {card.exp_month}/{card.exp_year}
              </p>
            </div>
          </div>
          <div className="flex space-x-3">
            <button
              onClick={() => setModalOpen(true)}
              className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-500"
              aria-label="Delete"
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      <HelperModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Delete Payment Method"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
        isLoading={isDeleting}
      >
        <p className="text-gray-700 dark:text-gray-300">
          Are you sure you want to delete this payment method? If you have an active subscription,
          it may result in service interruptions.
        </p>
      </HelperModal>
    </>
  );
};

export default PaymentMethodCard;
