// OnboardingPage.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlusCircleIcon,
  ArrowLeftIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import Spinner from '../../components/Spinner';
import LoginIllustration from '../../images/Login.svg';
import NoDataIllustration from '../../images/NoData.svg';
import SyncIllustration from '../../images/Sync.svg';
import CompleteIllustration from '../../images/Complete.svg';
import {
  syncInventory,
  clearError,
  getInventoryItems,
} from '../../features/inventory/inventorySlice';
import { fetchConnections } from '../../features/connections/connectionSlice';

const OnboardingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const connections = useSelector((state) => state.connection.connections) || [];
  const connectionsLoading = useSelector((state) => state.connection.loading);
  const items = useSelector((state) => state.inventory.items); // Get inventory items
  const error = useSelector((state) => state.inventory.error);

  // State Variables
  const [step, setStep] = useState(1);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [itemCount, setItemCount] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorShake, setErrorShake] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Handle Errors
  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setErrorShake(true);
      setTimeout(() => setErrorShake(false), 800);
    }
  }, [error, dispatch]);

  // Fetch connections if not already loaded
  useEffect(() => {
    if (!connections || connections.length === 0) {
      dispatch(fetchConnections());
    }
  }, [dispatch]);

  // Adjust steps based on connections
  useEffect(() => {
    if (connections && connections.length === 0) {
      setStep(0);
    } else if (connections && step === 0) {
      setStep(1);
    }
  }, [connections, step]);

  // Handle connection selection
  const handleConnectionSelect = async (connection) => {
    setSelectedConnection(connection);
    setIsProcessing(true);
    setStep(2);
    setItemCount(null);
    setErrorMessage(null);
    dispatch(clearError());

    const resultAction = await dispatch(
      syncInventory({
        id: connection.id,
        platform: connection.platform,
        countOnly: true,
      })
    );

    if (
      syncInventory.fulfilled.match(resultAction) &&
      resultAction.payload?.itemCount !== undefined
    ) {
      setItemCount(resultAction.payload.itemCount);
    } else {
      console.error(
        'Failed to fetch item count:',
        resultAction.payload || 'Unknown error'
      );
      setErrorMessage('Failed to fetch item count.');
    }

    setIsProcessing(false);
  };

  // Handle synchronization confirmation
  const handleConfirmSync = async () => {
    setIsSyncing(true);

    const resultAction = await dispatch(
      syncInventory({
        id: selectedConnection.id,
        platform: selectedConnection.platform,
      })
    );

    if (syncInventory.fulfilled.match(resultAction)) {
      setStep(3);
    } else {
      setErrorMessage(resultAction.payload?.message || 'Sync failed.');
    }

    await dispatch(getInventoryItems());
    setIsSyncing(false);
  };

  // Handle back navigation
  const handleBack = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleFinish = () => {
    if (items && items.length > 0) {
      navigate('/arbie/ecom-products');
    }
  };

  // Get appropriate illustration based on step
  const getIllustration = () => {
    switch (step) {
      case 0:
        return LoginIllustration;
      case 1:
        return NoDataIllustration;
      case 2:
        return SyncIllustration;
      case 3:
        return CompleteIllustration;
      default:
        return NoDataIllustration;
    }
  };

  // Render loading state if connections are being fetched
  if (connectionsLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex w-full h-screen text-slate-800 dark:text-slate-200">
      {/* Left Section: User Interaction */}
      <div className="flex w-full flex-col md:w-2/3 lg:w-3/5">
        <div className="my-8 mx-auto flex flex-col justify-start pt-4 lg:w-[40rem]">
          <div
            className={`flex w-full flex-col rounded-2xl bg-white dark:bg-gray-800 p-6 sm:p-10 shadow-lg relative ${errorShake ? 'shake' : ''
              }`}
          >
            {/* Back Button */}
            {step > 1 && (
              <button
                onClick={handleBack}
                className="absolute top-4 left-4 flex items-center text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-200 transition duration-300"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" />
                Back
              </button>
            )}

            <div className="mx-auto w-full max-w-lg pb-20 px-8 sm:px-0">
              {/* Step Indicator */}
              <div className="relative mt-8">
                <div className="absolute left-0 top-2 h-1 w-full bg-gray-200 dark:bg-gray-700">
                  <div
                    className={`absolute h-full ${step >= 1 ? 'w-1/3 bg-indigo-600' : 'w-0'
                      }`}
                  />
                  <div
                    className={`absolute h-full left-1/3 ${step >= 2 ? 'w-1/3 bg-indigo-600' : 'w-0'
                      }`}
                  />
                  <div
                    className={`absolute h-full left-2/3 ${step >= 3 ? 'w-1/3 bg-indigo-600' : 'w-0'
                      }`}
                  />
                </div>
                <ul className="relative flex w-full justify-between mt-4">
                  <li>
                    <span
                      className={`flex h-6 w-6 items-center justify-center rounded-full ${step >= 1
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300'
                        }`}
                    >
                      1
                    </span>
                  </li>
                  <li>
                    <span
                      className={`flex h-6 w-6 items-center justify-center rounded-full ${step >= 2
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300'
                        }`}
                    >
                      2
                    </span>
                  </li>
                  <li>
                    <span
                      className={`flex h-6 w-6 items-center justify-center rounded-full ${step >= 3
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300'
                        }`}
                    >
                      3
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Content Section */}
            {step === 0 && (
              <div className="text-center">
                <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-200 mb-4">
                  No Connected Accounts
                </h2>
                <p className="mb-6 text-lg text-gray-700 dark:text-gray-300">
                  You need to connect an account to start syncing your inventory.
                </p>
                <button
                  onClick={() => navigate('/settings/connections')}
                  className="bg-indigo-600 text-white text-lg font-medium py-3 px-6 rounded-lg hover:bg-indigo-700 dark:hover:bg-indigo-500 transition duration-300"
                >
                  Connect Account
                </button>
              </div>
            )}

            {step === 1 && connections.length > 0 && (
              <>
                <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-200">
                  Get Started
                </h2>
                <p className="mt-8 text-lg text-gray-700 dark:text-gray-300">
                  You can sync inventory from connected accounts or add items
                  manually.
                </p>
                {/* Sync Inventory Options */}
                {connections
                  .filter((connection) => !connection.locked) // Exclude locked connections
                  .map((connection) => (
                    <button
                      key={connection.id}
                      onClick={() => handleConnectionSelect(connection)}
                      className="w-full bg-indigo-600 text-white text-lg font-medium py-3 px-4 rounded-lg hover:bg-indigo-700 dark:hover:bg-indigo-500 transition duration-300 my-2"
                    >
                      Sync from {connection.accountName}
                    </button>
                  ))}

                {/* Add New Item Manually */}
                <button
                  onClick={() => navigate('/arbie/products/')}
                  className="w-full flex items-center justify-center bg-green-600 text-white text-lg font-medium py-3 px-4 rounded-lg hover:bg-green-700 dark:hover:bg-green-500 transition duration-300 my-2"
                >
                  <PlusCircleIcon className="h-6 w-6 mr-2" />
                  Add Item Manually
                </button>

                {/* Add New Account Button with Icon */}
                <button
                  onClick={() => navigate('/settings/connections')}
                  className="w-full flex items-center justify-center bg-gray-200 dark:bg-gray-700 text-indigo-600 dark:text-indigo-400 text-lg font-medium py-3 px-4 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 mt-6"
                >
                  <PlusCircleIcon className="h-6 w-6 mr-2" />
                  Add New Account
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-200">
                  Sync Inventory
                </h2>
                {isProcessing ? (
                  <div className="flex justify-center items-center">
                    <Spinner />
                    <p className="ml-4 text-lg text-gray-700 dark:text-gray-300">
                      Loading item count...
                    </p>
                  </div>
                ) : errorMessage ? (
                  <div className="flex items-center text-red-600 mt-4">
                    <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    <p className="text-lg">{errorMessage}</p>
                  </div>
                ) : (
                  <p className="mb-8 text-lg text-gray-700 dark:text-gray-300">
                    {itemCount !== null
                      ? `You have ${itemCount} items available for syncing.`
                      : 'Loading item count...'}
                  </p>
                )}
                <button
                  onClick={handleConfirmSync}
                  className={`w-full text-lg font-medium py-3 px-4 rounded-lg transition duration-300 ${isSyncing
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-indigo-600 hover:bg-indigo-700 dark:hover:bg-indigo-500 text-white'
                    }`}
                  disabled={isSyncing}
                >
                  {isSyncing ? 'Syncing... Please Wait' : 'Confirm Sync'}
                </button>
              </>
            )}

            {step === 3 && (
              <div className="text-center">
                <div className="flex items-center justify-center mb-4">
                  <CheckCircleIcon className="h-10 w-10 text-green-500 mr-2" />
                  <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-200">
                    Sync Complete
                  </h2>
                  </div>
                <p className="mb-8 text-lg text-gray-700 dark:text-gray-300">
                  Your inventory has been successfully synced.
                </p>
                <button
                  onClick={handleFinish}
                  className="w-full bg-indigo-600 text-white text-lg font-medium py-3 px-4 rounded-lg hover:bg-indigo-700 dark:hover:bg-indigo-500 transition duration-300"
                >
                  Finish
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Right Section: Explanation and Visuals */}
      <div className="relative hidden h-screen select-none flex-col justify-start bg-indigo-600 bg-gradient-to-br from-indigo-600 to-blue-600 text-white md:flex md:w-1/3 lg:w-2/5">
        <div className="py-16 px-8 xl:w-[40rem]">
          <p className="my-8 text-4xl font-semibold leading-tight">
            {step === 0 && 'Get Started by Connecting an Account'}
            {step === 1 && 'Start Managing Your Inventory'}
            {step === 2 && 'Why Sync Inventory?'}
            {step === 3 && 'Sync Complete!'}
          </p>
          <p className="mb-8 text-lg">
            {step === 0 &&
              'You currently have no connected accounts. Connect an account to start managing and syncing your inventory.'}
            {step === 1 &&
              'You can either sync your existing inventory from connected accounts or add items manually to start managing your inventory.'}
            {step === 2 &&
              'Syncing ensures your inventory is consistent across platforms and managed in a single place.'}
            {step === 3 &&
              'Your inventory is now synced and ready to be managed through ArbiEngine.'}
          </p>
        </div>
        <img
          src={getIllustration()}
          alt="Illustration"
          className="ml-8 w-11/12 max-w-lg rounded-lg object-cover"
        />
      </div>
    </div>
  );
};

export default OnboardingPage;
