import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Logo
import logo from "../../images/arbiengine_data.png";
// Light/Dark images
import inventoryComputerLight from "../../images/inventory-computer-light.png";
import inventoryComputerDark from "../../images/inventory-computer-dark.png";

const PowerSellersTeaserDesktop = () => {
  const navigate = useNavigate();

  // Listen for theme changes, defaulting to localStorage's value or "light"
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme);
    };

    window.addEventListener("themeChange", handleThemeChange);
    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  // Determine which image to use
  const teaserImage = theme === "dark" ? inventoryComputerDark : inventoryComputerLight;

  // Single button: "Learn About Our Beta"
  const handleNavigateToPowerSellers = () => {
    navigate("/powersellers");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section
      className="relative w-full min-h-[85vh] flex items-center overflow-hidden"
      aria-label="Power Sellers Teaser Section"
    >
      {/* Background / Monitor Image */}
      <div className="absolute inset-0">
        <img
          src={teaserImage}
          alt="Teaser for ArbiEngine Power Sellers"
          className="absolute inset-0 object-cover w-full h-full"
        />
      </div>

      {/* Content */}
      <div className="relative z-10 container mx-auto flex justify-end px-6 lg:px-16">
        {/* Teaser Text Block */}
        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 w-full flex flex-col items-start text-left">
          {/* Small Title with Logo */}
          <div className="flex items-center gap-3 mb-4">
            <img src={logo} alt="ArbiEngine Logo" className="h-8 w-8" />
            <div className="flex items-center gap-2">
              <span className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900 dark:text-white">
                ArbiEngine
              </span>
              <span className="text-sm sm:text-base font-semibold text-indigo-700 dark:text-indigo-500 whitespace-nowrap">
                for Power Sellers
              </span>
            </div>
          </div>

          {/* Headline / Slogan */}
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 dark:text-white leading-tight drop-shadow-sm">
            Reimagine Ecommerce, <br />
            Starting with eBay.
          </h1>

          {/* Supporting Text */}
          <p className="mt-4 text-base sm:text-lg lg:text-xl text-gray-700 dark:text-gray-300 max-w-md drop-shadow-sm">
            We’re partnering with <strong>100 eBay Power Sellers</strong> to refine
            listing flows, fulfillment processes, and the foundations of a
            next-generation ecommerce platform. Become an Early Adopter and help
            shape what’s coming next!
          </p>

          {/* Single Button Row */}
          <div className="mt-6">
            <button
              onClick={handleNavigateToPowerSellers}
              className="inline-flex items-center justify-center rounded-full bg-indigo-600 px-8 py-4 text-lg font-semibold text-white shadow-lg hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition-transform transform hover:scale-105"
            >
              Learn About Our Beta
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PowerSellersTeaserDesktop;
