import React from "react";
import PowerSellersHeroDesktop from "./PowerSellersHeroDesktop";
import PowerSellersHeroMobile from "./PowerSellersHeroMobile";

const PowerSellersHero = () => {
  return (
    <>
      {/* Desktop Layout */}
      <div className="hidden md:block">
        <PowerSellersHeroDesktop />
      </div>

      {/* Mobile/Medium Layout */}
      <div className="block md:hidden">
        <PowerSellersHeroMobile />
      </div>
    </>
  );
};

export default PowerSellersHero;
