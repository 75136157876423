// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';

import authReducer from '../features/auth/authSlice';
import connectionReducer from '../features/connections/connectionSlice';
import inventoryReducer from '../features/inventory/inventorySlice.js';
import autoFeedReducer from '../features/arbitrage/autoFeedSlice.js';
import metricsReducer from '../features/metrics/metricsSlice';
import productResearchReducer from '../features/productResearch/productResearchSlice';
import subscriptionReducer from '../features/subscriptions/subscriptionSlice';
import settingsReducer from '../features/userSettings/settingsSlice';
import purchasesReducer from '../features/purchases/purchasesSlice';
import ordersReducer from '../features/orders/ordersSlice';
import organizationReducer from '../features/organization/organizationSlice';
import marketingReducer from '../features/marketing/marketingSlice';
import socialReducer from '../features/socials/socialSlice';
import unsavedChangesReducer from '../features/unsavedChanges/unsavedChangesSlice';

// 1. Combine all slices
const appReducer = combineReducers({
  auth: authReducer,
  connection: connectionReducer,
  inventory: inventoryReducer,
  autoFeed: autoFeedReducer,
  metrics: metricsReducer,
  productResearch: productResearchReducer,
  subscription: subscriptionReducer,
  settings: settingsReducer,
  purchases: purchasesReducer,
  orders: ordersReducer,
  organization: organizationReducer,
  marketing: marketingReducer,
  social: socialReducer,
  unsavedChanges: unsavedChangesReducer,
});

// 2. Write a custom root reducer
const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    // Capture the old plans
    const oldPlans = state?.subscription?.plans ?? [];

    // Wipe entire Redux store
    state = undefined;

    // Re-run normal reducer initialization
    let newState = appReducer(state, action);

    // Restore the old plans
    newState.subscription.plans = oldPlans;

    return newState;
  }

  // Return the normal combined reducer
  return appReducer(state, action);
};

export default rootReducer;
