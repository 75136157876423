// src/pages/OrderFulfillmentDashboard.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, setFilter, fetchMoreOrdersFromState } from '../../features/orders/ordersSlice';
import OrderTable from '../../components/OrderFulfilment/OrderTable';
import FilterBar from '../../components/OrderFulfilment/FilterBar';
import { toast } from 'react-toastify';

const OrderFulfillmentDashboard = () => {
  const dispatch = useDispatch();
  const { orders, isLoading, isError, isSuccess, message, hasMoreItems } = useSelector((state) => state.orders);

  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess && message) {
      toast.success(message);
    }
  }, [isError, isSuccess, message]);

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId) ? prev.filter(id => id !== orderId) : [...prev, orderId]
    );
  };

  const handleBulkAction = (action) => {
    // Implement bulk actions like label generation, tracking updates, etc.
    if (action === 'generate_label') {
      // Example: Dispatch an action or call a service to generate labels
      toast.success('Labels generated successfully.');
    } else if (action === 'update_tracking') {
      toast.success('Tracking updated successfully.');
    } else if (action === 'process_returns') {
      toast.success('Returns processed successfully.');
    }

    // Reset selected orders after action
    setSelectedOrders([]);
  };

  const handleLoadMore = () => {
    if (hasMoreItems) {
      dispatch(fetchMoreOrdersFromState());
    }
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <FilterBar />

      <div className="mt-6">
        <OrderTable
          orders={orders}
          selectedOrders={selectedOrders}
          onSelectOrder={handleSelectOrder}
        />
      </div>

      {/* Bulk Action Buttons */}
      {selectedOrders.length > 0 && (
        <div className="mt-4 flex flex-wrap gap-2">
          <button
            onClick={() => handleBulkAction('generate_label')}
            className="px-4 py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-md hover:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
          >
            Generate Labels
          </button>
          <button
            onClick={() => handleBulkAction('update_tracking')}
            className="px-4 py-2 bg-green-500 dark:bg-green-600 text-white rounded-md hover:bg-green-600 dark:hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 transition"
          >
            Update Tracking
          </button>
          <button
            onClick={() => handleBulkAction('process_returns')}
            className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-400 transition"
          >
            Process Returns
          </button>
        </div>
      )}

      {/* Load More Button */}
      {hasMoreItems && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition"
          >
            Load More
          </button>
        </div>
      )}

      {/* Loading Indicator */}
      {isLoading && (
        <div className="mt-4 text-center text-gray-500 dark:text-gray-400">Loading...</div>
      )}
    </div>
  );
};

export default OrderFulfillmentDashboard;
