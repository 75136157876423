import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Import the same subscription action(s) you use in PaymentPage
import { createSubscription } from '../../features/subscriptions/subscriptionSlice';

const PaymentRightSection = () => {
  const { user } = useSelector((state) => state.auth);
  const { selectedPlan } = useSelector((state) => state.subscription);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local form state for billing details (similar to PaymentPage)
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'US',
    postalCode: '',
  });

  // Track whether the card is complete
  const [cardComplete, setCardComplete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleCheckboxChange = () => {
    setAgreedToTerms((prev) => !prev);
  };

  // Update local state whenever a user types into a field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe is not loaded.');
      return;
    }

    setIsLoading(true);

    try {
      // Grab the card info from the CardElement
      const cardElement = elements.getElement(CardElement);

      // Create a PaymentMethod using Stripe.js
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: billingDetails.name,
          address: {
            line1: billingDetails.address1,
            line2: billingDetails.address2,
            city: billingDetails.city,
            state: billingDetails.state,
            country: billingDetails.country,
            postal_code: billingDetails.postalCode,
          },
          email: user.email,
        },
      });

      if (error) {
        console.error('Error creating payment method:', error);
        setIsLoading(false);
        return;
      }

      // Dispatch the same createSubscription action used in PaymentPage
      await dispatch(
        createSubscription({
          userId: user.id,
          plan: selectedPlan?.name || 'Explorer', // fallback if no plan selected
          paymentMethodId: paymentMethod.id,
          billingDetails,
        })
      ).unwrap();

      // Success! You could navigate to a subscription page
      navigate('/settings/subscription');
    } catch (err) {
      console.error('Subscription error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Check if form is complete (all required fields + cardComplete + terms)
  const isFormComplete =
    billingDetails.name.trim() &&
    billingDetails.address1.trim() &&
    billingDetails.city.trim() &&
    billingDetails.state.trim() &&
    billingDetails.country.trim() &&
    billingDetails.postalCode.trim() &&
    cardComplete &&
    agreedToTerms &&
    !isLoading;

  return (
    <div className="max-w-lg mr-auto px-4 py-6 bg-white rounded-md">
      {/* Contact Information */}
      <h2 className="text-gray-600 text-lg font-medium mb-4">Contact information</h2>
      <div className="flex items-center bg-gray-100 border border-gray-300 rounded-lg p-4">
        <span className="text-gray-500 text-sm font-medium w-1/3">Email</span>
        <div className="flex-grow text-center text-gray-800 text-sm">{user.email}</div>
      </div>

      {/* Payment Method */}
      <h2 className="text-lg font-medium text-gray-800 mb-4 pt-4">Payment method</h2>
      <form
        onSubmit={handleSubmit}
        className="space-y-6 border border-gray-200 shadow-sm rounded-md p-6 bg-white"
      >
        {/* Card Element */}
        <div className="p-4 border border-gray-300 rounded-md">
          <CardElement
            className="w-full"
            onChange={(e) => {
              setCardComplete(e.complete);
            }}
          />
        </div>

        {/* Cardholder Name */}
        <input
          type="text"
          name="name"
          value={billingDetails.name}
          onChange={handleChange}
          placeholder="Cardholder name"
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          required
        />

        {/* Billing Address */}
        <div className="space-y-4">
          <select
            name="country"
            value={billingDetails.country}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          >
            <option value="US">United States</option>
            {/* Add more countries as needed */}
          </select>

          <input
            type="text"
            name="address1"
            value={billingDetails.address1}
            onChange={handleChange}
            placeholder="Address line 1"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />

          <input
            type="text"
            name="address2"
            value={billingDetails.address2}
            onChange={handleChange}
            placeholder="Address line 2"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />

          <div className="flex space-x-4">
            <input
              type="text"
              name="city"
              value={billingDetails.city}
              onChange={handleChange}
              placeholder="City"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
            <input
              type="text"
              name="postalCode"
              value={billingDetails.postalCode}
              onChange={handleChange}
              placeholder="ZIP"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <input
            type="text"
            name="state"
            value={billingDetails.state}
            onChange={handleChange}
            placeholder="State"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>

        {/* Terms Agreement */}
        <div className="mt-6">
          <label className="flex items-start space-x-3">
            <input
              type="checkbox"
              className="mt-1 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
              checked={agreedToTerms}
              onChange={handleCheckboxChange}
              required
            />
            <span className="text-sm text-gray-700">
              You'll be charged the amount and at the frequency listed above
              until you cancel. We may change our prices as described in our{' '}
              <a href="/terms-of-service" className="text-indigo-600 underline">
                Terms of Use
              </a>
              . You can{' '}
              <a href="/terms-of-service" className="text-indigo-600 underline">
                cancel any time
              </a>
              . By subscribing, you agree to ArbiEngine's{' '}
              <a href="/terms-of-service" className="text-indigo-600 underline">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" className="text-indigo-600 underline">
                Privacy Policy
              </a>
              .
            </span>
          </label>
        </div>

        {/* Subscribe Button */}
        <button
          type="submit"
          disabled={!isFormComplete}
          className={`w-full py-3 text-white font-bold rounded-md transition-colors duration-200 ${
            isFormComplete
              ? 'bg-indigo-600 hover:bg-indigo-700'
              : 'bg-indigo-300 cursor-not-allowed'
          }`}
        >
          {isLoading ? 'Processing...' : 'Subscribe'}
        </button>
      </form>

      {/* Footer */}
      <div className="mt-6 text-sm text-gray-500 text-center border-t pt-4">
        <p>
          Powered by{' '}
          <a
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold underline text-indigo-600 hover:text-indigo-800"
          >
            stripe
          </a>{' '}
          |{' '}
          <a href="/terms-of-service" className="underline">
            Terms
          </a>{' '}
          |{' '}
          <a href="privacy-policy" className="underline">
            Privacy
          </a>
        </p>
      </div>
    </div>
  );
};

export default PaymentRightSection;
