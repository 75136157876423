import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const API_URL = `${apiUrl}/api/subscriptions/`;

// Fetch user's subscription
const fetchSubscription = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/get-sub/${userId}`, config);
  return response.data;
};

// Create a new subscription
const createSubscription = async (subscriptionData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, subscriptionData, config);
  return response.data;
};

// Update an existing subscription
const updateSubscription = async (subscriptionData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL, subscriptionData, config);
  return response.data;
};

// Cancel a subscription item
const cancelSubscriptionItem = async (subscriptionId, subscriptionItemId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_URL, {
    data: { subscriptionId, subscriptionItemId }, // Send data in the body
    headers: config.headers,
  });
  return response.data;
};

const fetchPlans = async (encryptedPassword, config) => {
  const response = await axios.post(`${API_URL}plans`, { encryptedPassword }, config);
  return response.data;
};

// Fetch user's payment method
const fetchPaymentMethod = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}payment-method`, config);
  return response.data;
};

const deletePaymentMethod = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}payment-method`, config);
  return response.data;
};

const addPaymentMethod = async ({ paymentMethodId, billingDetails }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}payment-method`, { paymentMethodId, billingDetails }, config);
  return response.data;
};

const toggleAutoRenew = async (subscriptionId, autoRenew, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}${subscriptionId}/auto-renew`,
    { autoRenew },
    config
  );

  return response.data;
};

const fetchBillingDetails = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}billing-details`, config);
  return response.data.billingDetails; // Adjust based on API response structure
};

// Reactivate a subscription
const reactivateSubscription = async (subscriptionId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}reactivate`, { subscriptionId }, config);
  return response.data;
};


const subscriptionService = {
  fetchSubscription,
  createSubscription,
  updateSubscription,
  cancelSubscriptionItem,
  fetchPlans,
  fetchPaymentMethod,
  deletePaymentMethod,
  addPaymentMethod,
  toggleAutoRenew,
  fetchBillingDetails,
  reactivateSubscription,
};

export default subscriptionService;
